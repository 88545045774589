<script setup>
import BomboleCard from "../../components/GRP-CONTRATTI/BomboleCard.vue";
import { computed, onMounted, reactive, ref } from "vue";
import { parseError } from "../../composables/parseError";
import { useStore } from "vuex";
import { datesLimit } from "../../composables/datesLimit";
const store = useStore();
const {
  today,
  firstLimit,
  secondLimit,
  thirdLimit,
  fourthLimit,
  fifthLimit,
  sixthLimit,
  datesCalc,
  datesCalc2,
} = datesLimit();
const props = defineProps(["title"]);
const items = computed(() => store.getters["GRPCONTRATTI/getUtenzeBombole"]);
const user = computed(() => store.getters["user/getUser"]);
const loaded = ref(false);
const loaded2 = ref(false);
const { errorSys, calcError } = parseError();
const files = computed(() => store.getters["amm/getFiles"]);
const params = computed(() =>
  store.getters["menu/getMainMenu"]
    .filter((c) => c.code === "GRP-CONTRATTI")[0]
    .childs.filter((child) => child.code === "VIS-BOMBOLE_GPL")[0]
    .buttons.filter((b) => b.code === "STOR-BOMB")[0]
    .params.split(";"),
);

onMounted(() => {
  datesCalc(params.value);

  if (user.value.DatiUtenza[0].Cliente !== "") {
    store
      .dispatch("GRPCONTRATTI/fetchUtenzeBombole", {
        codiceCliente: user.value.DatiUtenza[0].Cliente,
      })
      .then(() => {
        if (!items.value.length) {
          errorSys.value = "Nessun elemento trovato";
        }
        loaded.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  }

  store
    .dispatch("amm/fetchFiles")
    .then(() => {
      loaded2.value = true;
    })
    .catch((e) => {
      calcError(e);
    });
});

const configVars = computed(() => store.getters["menu/getConfigVars"]);

const replaceField = (data) => {
  let myJSONString = JSON.stringify(data);
  return JSON.parse(myJSONString.replace(/\//g, ""));
};
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="(!loaded || !loaded2) && !errorSys">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div
      class="sm:flex sm:justify-between sm:items-center mb-8"
      v-if="loaded && loaded2"
    >
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ props.title }}
        </h1>
      </div>
    </div>
    <div v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Cards -->
    <div class="grid grid-cols-12 gap-6" v-if="loaded && loaded2 && !errorSys">
      <BomboleCard
        v-for="item in items"
        :key="item.__dbKey"
        :item="replaceField(item)"
        :firstLimit="firstLimit"
        :secondLimit="secondLimit"
        :thirdLimit="thirdLimit"
        :today="today"
        :doc="files.filter((file) => file.typeId == 3)"
      />
    </div>
  </div>
</template>
