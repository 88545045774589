<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const mounting = ref(true);
const props = defineProps(["code"]);
const currentRoute = ref();

const store = useStore();
const configVars = computed(() => store.getters["menu/getConfigVars"]);

onMounted(() => {
  currentRoute.value = useRouter().currentRoute.value;
  mounting.value = false;
});
</script>

<template>
  <div v-if="!mounting">
    <template v-if="props.code === 'GRP-CONTRATTI'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g fill="#94a3b8" class="nc-icon-wrapper">
          <path
            class="fill-current"
            d="M19.09 19.19l-1.57-1.56a3.61 3.61 0 1 0-1.7 1.7l1.57 1.56a1.2 1.2 0 0 0 1.7-1.7z m-6.8-1.13a2.81 2.81 0 1 1 1.99 0.81 2.8 2.8 0 0 1-1.98-0.82z m6.22 2.26a0.4 0.4 0 0 1-0.56 0l-1.43-1.43a3.3 3.3 0 0 0 0.56-0.57l1.43 1.44a0.4 0.4 0 0 1 0 0.56z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M11.87 19.68h-5.21a1.2 1.2 0 0 1-1.2-1.2v-13.62a1.2 1.2 0 0 1 1.2-1.2h6.96a1.22 1.22 0 0 1 0.25 0.02v2.38a1.2 1.2 0 0 0 1.2 1.2h2.38a1.14 1.14 0 0 1 0.03 0.27v5.34a0.4 0.4 0 0 0 0.81 0v-5.35a2 2 0 0 0-0.59-1.41l-2.66-2.67a1.99 1.99 0 0 0-1.42-0.59h-6.96a2.01 2.01 0 0 0-2 2.01v13.62a2.01 2.01 0 0 0 2 2h5.21a0.4 0.4 0 0 0 0-0.8z m5.04-13.22h-1.84a0.4 0.4 0 0 1-0.4-0.4v-1.85z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M7.46 7.26h4.41a0.4 0.4 0 1 0 0-0.8h-4.41a0.4 0.4 0 0 0 0 0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.47 8.86h-6.8a0.4 0.4 0 0 0 0 0.8h6.8a0.4 0.4 0 1 0 0-0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.87 11.27a0.4 0.4 0 0 0-0.4-0.4h-8.01a0.4 0.4 0 0 0 0 0.8h8.01a0.39 0.39 0 0 0 0.28-0.12 0.4 0.4 0 0 0 0.12-0.28z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M10.27 13.27a0.41 0.41 0 0 0-0.12-0.28 0.4 0.4 0 0 0-0.29-0.12h-2.4a0.4 0.4 0 0 0 0 0.8h2.4a0.4 0.4 0 0 0 0.4-0.4z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M7.46 15.67h1.6a0.4 0.4 0 0 0 0-0.8h-1.6a0.4 0.4 0 0 0 0 0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M7.46 17.68h2a0.4 0.4 0 1 0 0-0.8h-2a0.4 0.4 0 0 0 0 0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.28 17.28a1.2 1.2 0 0 1-1.2-1.2 0.4 0.4 0 1 0-0.8 0 2.01 2.01 0 0 0 2 2 0.4 0.4 0 0 0 0-0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'GEST-RICHIESTE'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g class="nc-icon-wrapper">
          <g>
            <path
              class="fill-current"
              d="M12.07 7.26a2.3 2.3 0 0 1-2.26-2.33 2.26 2.26 0 1 1 4.53 0 2.3 2.3 0 0 1-2.27 2.33z m0-4.15a1.82 1.82 0 1 0 1.77 1.82 1.8 1.8 0 0 0-1.77-1.82z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M17.81 6.8a2.02 2.02 0 0 1-1.98-2.04 1.99 1.99 0 1 1 3.97 0 2.02 2.02 0 0 1-1.99 2.04z m0-3.57a1.53 1.53 0 1 0 1.5 1.53 1.52 1.52 0 0 0-1.5-1.53z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M6.39 6.8a2.02 2.02 0 0 1-1.99-2.04 1.99 1.99 0 1 1 3.98 0 2.02 2.02 0 0 1-1.99 2.04z m0-3.57a1.53 1.53 0 1 0 1.5 1.53 1.52 1.52 0 0 0-1.5-1.53z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M21.51 10.63h-5.29a0.25 0.25 0 0 1-0.24-0.19 4.14 4.14 0 0 0-1.28-2.14 0.26 0.26 0 0 1-0.04-0.34 3.88 3.88 0 0 1 3.15-1.62c2.17 0 3.94 1.82 3.94 4.04a0.25 0.25 0 0 1-0.24 0.26z m-5.1-0.51h4.84a3.5 3.5 0 0 0-3.44-3.29 3.38 3.38 0 0 0-2.62 1.24 4.62 4.62 0 0 1 1.22 2.05z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M7.93 10.63h-5.23a0.25 0.25 0 0 1-0.25-0.25c0-2.23 1.77-4.05 3.94-4.05a3.88 3.88 0 0 1 3.13 1.59 0.26 0.26 0 0 1-0.04 0.34 4.14 4.14 0 0 0-1.32 2.18 0.25 0.25 0 0 1-0.24 0.19z m-4.98-0.51h4.79a4.62 4.62 0 0 1 1.25-2.08 3.38 3.38 0 0 0-2.6-1.21c-1.82 0-3.31 1.46-3.44 3.29z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M13.58 23.39h-3.01a0.25 0.25 0 0 1-0.25-0.23l-0.14-1.79a6.8 6.8 0 0 1-1.51-0.64l-1.33 1.16a0.24 0.24 0 0 1-0.34-0.01l-2.11-2.19a0.26 0.26 0 0 1-0.02-0.34l1.14-1.36a7.26 7.26 0 0 1-0.63-1.55l-1.74-0.14a0.25 0.25 0 0 1-0.23-0.25v-3.1a0.25 0.25 0 0 1 0.23-0.26l1.91-0.15h13.05l1.91 0.15a0.25 0.25 0 0 1 0.23 0.26v3.09a0.25 0.25 0 0 1-0.23 0.25l-1.74 0.14a7.11 7.11 0 0 1-0.62 1.55l1.13 1.36a0.26 0.26 0 0 1-0.01 0.34l-2.13 2.2a0.24 0.24 0 0 1-0.34 0.01l-1.33-1.16a6.73 6.73 0 0 1-1.51 0.64l-0.14 1.79a0.25 0.25 0 0 1-0.24 0.23z m-2.78-0.51h2.56l0.13-1.73a0.25 0.25 0 0 1 0.18-0.23 6.42 6.42 0 0 0 1.7-0.72 0.24 0.24 0 0 1 0.29 0.02l1.28 1.13 1.81-1.85-1.1-1.33a0.26 0.26 0 0 1-0.02-0.29 6.68 6.68 0 0 0 0.7-1.75 0.25 0.25 0 0 1 0.22-0.19l1.69-0.14v-2.62l-1.68-0.14h-12.98l-1.67 0.14v2.63l1.69 0.14a0.25 0.25 0 0 1 0.22 0.19 6.68 6.68 0 0 0 0.7 1.75 0.26 0.26 0 0 1-0.02 0.29l-1.1 1.32 1.81 1.86 1.29-1.13a0.24 0.24 0 0 1 0.28-0.02 6.37 6.37 0 0 0 1.7 0.72 0.25 0.25 0 0 1 0.19 0.22l0.13 1.74z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M12.07 19.25c-2.55 0-4.63-2.13-4.63-4.76a0.25 0.25 0 1 1 0.49 0c0 2.34 1.86 4.25 4.14 4.25s4.14-1.91 4.14-4.25a0.25 0.25 0 1 1 0.49 0c0 2.62-2.08 4.76-4.63 4.76z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M12.07 17.17a2.64 2.64 0 0 1-2.59-2.68 0.24 0.24 0 1 1 0.49 0 2.11 2.11 0 1 0 4.21 0 0.24 0.24 0 1 1 0.49 0 2.64 2.64 0 0 1-2.6 2.68z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M16.35 11.69h-8.55a0.25 0.25 0 0 1-0.25-0.25c0-2.56 2.03-4.64 4.52-4.64s4.51 2.07 4.52 4.62v0.02a0.25 0.25 0 0 1-0.24 0.25z m-8.3-0.51h8.05c-0.13-2.16-1.88-3.88-4.03-3.88s-3.89 1.72-4.02 3.89z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M2.48 21.38a1.51 1.51 0 0 1 0-3.03 1.51 1.51 0 0 1 0 3.03z m0-2.53a1.01 1.01 0 0 0 0 2.02 1.01 1.01 0 0 0 0-2.02z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M21.62 3.73a1.49 1.49 0 0 1-1.48-1.52 1.47 1.47 0 1 1 2.95 0 1.49 1.49 0 0 1-1.47 1.52z m0-2.52a1 1 0 0 0-0.99 1 0.98 0.98 0 1 0 1.97 0 1 1 0 0 0-0.98-1z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M21.62 20.37a1.26 1.26 0 1 1 1.22-1.26 1.25 1.25 0 0 1-1.22 1.26z m0-2.02a0.76 0.76 0 1 0 0.73 0.76 0.75 0.75 0 0 0-0.73-0.76z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'GRP-PROFILO'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g
          :class="currentRoute.path.includes(props.code) && configVars.fill1"
          class="nc-icon-wrapper"
        >
          <g>
            <path
              class="fill-current"
              d="M12.05 8.27a1.83 1.83 0 1 0 1.82 1.82 1.82 1.82 0 0 0-1.82-1.82z m0 2.98a1.19 1.19 0 1 1 1.18-1.18 1.19 1.19 0 0 1-1.18 1.18z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M12.05 6.61a5.44 5.44 0 0 0-2.07 10.47 5.28 5.28 0 0 0 2.07 0.4 5.62 5.62 0 0 0 2.12-0.43 5.44 5.44 0 0 0-2.12-10.44z m-1.66 9.93l-0.56-3.38 2.26-0.21 2.21 0.21-0.53 3.38a4.93 4.93 0 0 1-3.38 0z m4.06-0.34l0.54-3.27a0.3 0.3 0 0 0-0.06-0.25 0.34 0.34 0 0 0-0.22-0.13l-2.58-0.27h-0.06l-2.65 0.26a0.28 0.28 0 0 0-0.21 0.13 0.27 0.27 0 0 0-0.06 0.26l0.54 3.29a4.79 4.79 0 1 1 4.76-0.02z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
            <path
              class="fill-current"
              d="M21.72 9.88h-1.49a8.87 8.87 0 0 0-0.85-2.09l1.05-1.05a0.31 0.31 0 0 0 0-0.45l-2.62-2.6a0.31 0.31 0 0 0-0.45 0l-1.05 1.05a8.33 8.33 0 0 0-2.09-0.86v-1.5a0.33 0.33 0 0 0-0.32-0.32h-3.7a0.33 0.33 0 0 0-0.32 0.32v1.48a8.87 8.87 0 0 0-2.09 0.86l-1.05-1.05a0.31 0.31 0 0 0-0.45 0l-2.6 2.62a0.31 0.31 0 0 0 0 0.45l1.05 1.05a8.35 8.35 0 0 0-0.86 2.09h-1.5a0.33 0.33 0 0 0-0.33 0.32v3.7a0.33 0.33 0 0 0 0.33 0.32h1.48a8.91 8.91 0 0 0 0.86 2.08l-1.05 1.06a0.31 0.31 0 0 0 0 0.45l2.6 2.59a0.31 0.31 0 0 0 0.45 0l1.05-1.05a8.35 8.35 0 0 0 2.09 0.86v1.51a0.34 0.34 0 0 0 0.32 0.32h3.69a0.34 0.34 0 0 0 0.33-0.32v-1.49a8.9 8.9 0 0 0 2.08-0.85l1.05 1.05a0.31 0.31 0 0 0 0.46 0l2.6-2.6a0.31 0.31 0 0 0 0-0.45l-1.06-1.05a8.35 8.35 0 0 0 0.86-2.09h1.53a0.34 0.34 0 0 0 0.33-0.32v-3.72a0.33 0.33 0 0 0-0.33-0.32z m-0.32 3.69h-1.42a0.34 0.34 0 0 0-0.32 0.26 7.37 7.37 0 0 1-0.99 2.36 0.33 0.33 0 0 0 0.04 0.41l0.99 0.99-2.15 2.15-0.99-0.99a0.32 0.32 0 0 0-0.41-0.04 7.91 7.91 0 0 1-2.36 0.99 0.34 0.34 0 0 0-0.26 0.32v1.42h-3.01v-1.42a0.34 0.34 0 0 0-0.26-0.32 7.35 7.35 0 0 1-2.36-0.99 0.33 0.33 0 0 0-0.41 0.04l-0.99 0.99-2.15-2.15 0.99-0.99a0.33 0.33 0 0 0 0.05-0.41 7.86 7.86 0 0 1-0.99-2.36 0.34 0.34 0 0 0-0.32-0.26h-1.38v-3.06h1.42a0.34 0.34 0 0 0 0.32-0.26 7.38 7.38 0 0 1 0.99-2.36 0.33 0.33 0 0 0-0.04-0.41l-0.99-0.99 2.15-2.14 0.98 0.99a0.32 0.32 0 0 0 0.41 0.05 7.88 7.88 0 0 1 2.37-0.99 0.34 0.34 0 0 0 0.25-0.32v-1.38h3.06v1.42a0.34 0.34 0 0 0 0.26 0.32 7.39 7.39 0 0 1 2.37 0.99 0.32 0.32 0 0 0 0.4-0.04l0.99-0.99 2.14 2.14-0.98 0.99a0.32 0.32 0 0 0-0.05 0.41 7.92 7.92 0 0 1 0.99 2.37 0.34 0.34 0 0 0 0.32 0.25h1.42v3.01z"
              :class="
                currentRoute.path.includes(props.code) && configVars.fill1
              "
            ></path>
          </g>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'GRP-FATTURE'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g
          :class="currentRoute.path.includes(props.code) && configVars.fill1"
          class="nc-icon-wrapper"
        >
          <path
            class="fill-current"
            d="M18.62 15.51a3.61 3.61 0 1 0-1.64 4.95l0.7-0.43a3.37 3.37 0 0 0 0.57-0.57l0.43-0.7a3.61 3.61 0 0 0-0.06-3.25z m-1.19 3.69a2.81 2.81 0 0 1-3.97 0 2.81 2.81 0 1 1 3.97 0z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M12.27 20.06h-5.21a1.21 1.21 0 0 1-0.85-0.35 1.19 1.19 0 0 1-0.35-0.85v-13.62a1.2 1.2 0 0 1 1.2-1.2h6.96a1.22 1.22 0 0 1 0.25 0.02v2.38a1.21 1.21 0 0 0 1.2 1.21h2.38a1.11 1.11 0 0 1 0.03 0.26v5.34a0.4 0.4 0 0 0 0.8 0v-5.35a1.99 1.99 0 0 0-0.58-1.41l-2.66-2.67a2 2 0 0 0-1.42-0.59h-6.96a2.01 2.01 0 0 0-2 2.01v13.62a2.01 2.01 0 0 0 2 2h5.21a0.4 0.4 0 1 0 0-0.8z m5.04-13.22h-1.84a0.4 0.4 0 0 1-0.4-0.4v-1.85z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M7.86 7.65h4.41a0.4 0.4 0 0 0 0-0.8h-4.41a0.4 0.4 0 0 0 0 0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.87 9.24h-6.81a0.4 0.4 0 1 0 0 0.8h6.81a0.4 0.4 0 0 0 0-0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M16.27 11.65a0.4 0.4 0 0 0-0.4-0.4h-8.01a0.4 0.4 0 1 0 0 0.8h8.01a0.4 0.4 0 0 0 0.28-0.12 0.39 0.39 0 0 0 0.12-0.28z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M10.67 13.65a0.4 0.4 0 0 0-0.4-0.4h-2.41a0.4 0.4 0 1 0 0 0.8h2.41a0.4 0.4 0 0 0 0.28-0.11 0.39 0.39 0 0 0 0.12-0.29z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M7.86 16.05h1.61a0.4 0.4 0 0 0 0-0.8h-1.61a0.4 0.4 0 1 0 0 0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M7.86 18.06h2a0.4 0.4 0 1 0 0-0.8h-2a0.4 0.4 0 1 0 0 0.8z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.53 15.11a2.18 2.18 0 0 0-2.07 1.53h-0.38a0.26 0.26 0 1 0 0.02 0.51h0.26v0.12a2.12 2.12 0 0 0 0.02 0.26h-0.3a0.26 0.26 0 1 0 0.02 0.51h0.4a2.17 2.17 0 0 0 2.03 1.4 2.14 2.14 0 0 0 1.57-0.71 0.26 0.26 0 1 0-0.35-0.36c-0.36 0.36-0.66 0.56-1.22 0.56a1.65 1.65 0 0 1-1.48-0.89h1.6a0.26 0.26 0 1 0 0-0.51h-1.76a1.8 1.8 0 0 1-0.02-0.25v-0.13h1.78a0.26 0.26 0 1 0 0-0.51h-1.66a1.65 1.65 0 0 1 1.53-1.02c0.56 0 0.85 0.2 1.22 0.56a0.26 0.26 0 1 0 0.36-0.36 2.13 2.13 0 0 0-1.58-0.71z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'GRP-PRIMARI'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g
          :class="currentRoute.path.includes(props.code) && configVars.fill1"
          class="nc-icon-wrapper"
        >
          <path
            class="fill-current"
            d="M16.52 11v-7.14a0.2 0.2 0 0 0-0.2-0.19h-11.9a0.2 0.2 0 0 0-0.14 0.05 0.18 0.18 0 0 0-0.06 0.14v15.38a0.19 0.19 0 0 0 0.2 0.2h8.7a5.38 5.38 0 0 0 0.34 0.88h-9.04a1.08 1.08 0 0 1-1.08-1.08v-15.38a1.08 1.08 0 0 1 1.08-1.07h11.9a1.07 1.07 0 0 1 1.07 1.07v6.88a5.46 5.46 0 0 0-0.87 0.26z m-1.73-5.39h-6.33v0.79h6.33z m0 2.77h-6.33v0.8h6.33z m0 2.78h-6.33v0.79h6.33z m-0.21 2.77h-6.12v0.79h5.44a5.44 5.44 0 0 1 0.68-0.79z m-6.12 3.57h4.46a5.18 5.18 0 0 1 0.12-0.79h-4.58z m-1.49-12.57h-1.42v1.42h1.42z m0 2.77h-1.42v1.43h1.42z m0 2.77h-1.42v1.43h1.42z m0 2.78h-1.42v1.42h1.42z m-1.43 4.2h1.43v-1.43h-1.42z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M20.15 15.85l-2.52-3.78a0.47 0.47 0 0 0-0.39-0.21 0.47 0.47 0 0 0-0.4 0.21l-2.52 3.78a3.51 3.51 0 1 0 5.83 0z m-2.91 4.67a2.81 2.81 0 0 1-2.34-4.37l2.34-3.5 0.4 0.6 1.94 2.9a2.81 2.81 0 0 1-2.34 4.37z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'GRP-DISTR'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g
          :class="currentRoute.path.includes(props.code) && configVars.fill1"
          class="nc-icon-wrapper"
        >
          <path
            class="fill-current"
            d="M18 2.43a1.63 1.63 0 0 1 1.63 1.63v14.33l0.21 0.07a1.62 1.62 0 0 1 1.08 1.53v1.3a0.33 0.33 0 0 1-0.34 0.34h-14.21a0.33 0.33 0 0 1-0.34-0.34v-1.3a1.62 1.62 0 0 1 1.09-1.53l0.21-0.07v-4.96h-0.96a0.96 0.96 0 0 0-0.95 0.96v3.01a1.63 1.63 0 0 1-1.63 1.64 1.62 1.62 0 0 1-1.62-1.63v-8.67a1.61 1.61 0 0 1 0.89-1.47l1.87-0.93c-0.06 0.03-0.06 0.03 0.04 0l0.21-0.07v-0.92a0.33 0.33 0 0 1 0.34-0.34h1.81v-0.95a1.63 1.63 0 0 1 1.63-1.63z m0 0.68h-9.04a0.96 0.96 0 0 0-0.96 0.95v14.3h10.96v-14.3a0.96 0.96 0 0 0-0.96-0.96z m-1.29 1.9a0.33 0.33 0 0 1 0.34 0.34v2.59a0.33 0.33 0 0 1-0.34 0.34h-6.46a0.34 0.34 0 0 1-0.34-0.34v-2.59a0.34 0.34 0 0 1 0.34-0.34z m-0.34 0.67h-5.78v1.92h5.78z m-9.04 0h-1.48v4.5h1.48z m-2.15 1.29l-1.82 0.9a0.96 0.96 0 0 0-0.53 0.85v8.67a0.96 0.96 0 0 0 1.92 0v-3a1.63 1.63 0 0 1 1.62-1.63h0.96v-1.91h-1.82a0.33 0.33 0 0 1-0.34-0.34z m8.3 2.78a0.34 0.34 0 0 1 0.28 0.15l1.79 2.68a2.49 2.49 0 1 1-4.14 0l1.79-2.68a0.34 0.34 0 0 1 0.28-0.15z m0 0.94l-1.51 2.26a1.82 1.82 0 1 0 3.02 0l-1.25-1.87z m5.82 8.35h-11.63a0.96 0.96 0 0 0-0.96 0.96v0.95h13.54v-0.95a0.96 0.96 0 0 0-0.95-0.96z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'GRP-OPERS'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g
          :class="currentRoute.path.includes(props.code) && configVars.fill1"
          class="nc-icon-wrapper"
        >
          <path
            class="fill-current"
            d="M5.61 13.4q-0.14-0.29-0.01-0.49a0.45 0.45 0 0 1 0.4-0.19 0.7 0.7 0 0 1 0.38 0.13 2 2 0 0 0 0.5 0.47 0.81 0.81 0 0 0 0.62 0.13 0.67 0.67 0 0 0 0.26-0.11 0.56 0.56 0 0 0 0.17-0.2 0.54 0.54 0 0 0 0.06-0.24 0.44 0.44 0 0 0-0.07-0.24 0.86 0.86 0 0 0-0.2-0.22 0.79 0.79 0 0 0-0.22-0.12 1.53 1.53 0 0 0-0.22-0.06 1.47 1.47 0 0 1-0.2-0.06c-0.1-0.03-0.2-0.06-0.3-0.08a1.8 1.8 0 0 1-0.29-0.12 1.45 1.45 0 0 1-0.53-0.44 1.22 1.22 0 0 1-0.17-0.33 1.03 1.03 0 0 1-0.06-0.33 1.27 1.27 0 0 1 0.08-0.43 1.64 1.64 0 0 1 0.22-0.41 1.45 1.45 0 0 1 0.32-0.32 1.1 1.1 0 0 1 0.4-0.19 2.03 2.03 0 0 1 0.88-0.03 2.61 2.61 0 0 1 0.82 0.28 0.79 0.79 0 0 1 0.21 0.22 0.4 0.4 0 0 1 0.07 0.21 0.38 0.38 0 0 1-0.06 0.21 0.52 0.52 0 0 1-0.17 0.16 0.48 0.48 0 0 1-0.24 0.06 0.61 0.61 0 0 1-0.29-0.08 1.83 1.83 0 0 0-0.34-0.11 1.33 1.33 0 0 0-0.43-0.04 0.59 0.59 0 0 0-0.35 0.13q-0.14 0.12-0.11 0.41a0.67 0.67 0 0 0 0.14 0.18 0.81 0.81 0 0 0 0.19 0.13 1.49 1.49 0 0 0 0.23 0.08c0.08 0.02 0.16 0.05 0.24 0.07a4.63 4.63 0 0 1 0.57 0.2 1.69 1.69 0 0 1 0.46 0.29 1.2 1.2 0 0 1 0.29 0.41 1.46 1.46 0 0 1 0.11 0.59 1.23 1.23 0 0 1-0.12 0.53 1.67 1.67 0 0 1-0.32 0.46 1.57 1.57 0 0 1-0.45 0.32 1.16 1.16 0 0 1-0.5 0.12 2.56 2.56 0 0 1-1.1-0.25 2.19 2.19 0 0 1-0.86-0.7z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M9.81 8.4a0.57 0.57 0 0 1 0.56-0.57 0.55 0.55 0 0 1 0.41 0.17 0.54 0.54 0 0 1 0.17 0.4 0.53 0.53 0 0 1-0.17 0.39 0.56 0.56 0 0 1-0.41 0.16 0.55 0.55 0 0 1-0.4-0.16 0.53 0.53 0 0 1-0.16-0.39z m1.08 5.4a0.47 0.47 0 0 1-0.16 0.37 0.51 0.51 0 0 1-0.33 0.12 0.5 0.5 0 0 1-0.32-0.11 0.49 0.49 0 0 1-0.17-0.34l-0.03-3.84a0.56 0.56 0 0 1 0.18-0.32 0.48 0.48 0 0 1 0.31-0.11 0.52 0.52 0 0 1 0.3 0.1 0.49 0.49 0 0 1 0.19 0.3z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.65 9.93a0.5 0.5 0 0 1 0.22-0.21 0.62 0.62 0 0 1 0.27-0.06 0.48 0.48 0 0 1 0.33 0.13 0.46 0.46 0 0 1 0.13 0.4q-0.11 0.42-0.25 0.83t-0.29 0.8h0.02l-0.16 0.44-0.18 0.45q-0.13 0.37-0.26 0.74c-0.09 0.24-0.18 0.47-0.27 0.68a0.54 0.54 0 0 1-0.43 0.21 0.46 0.46 0 0 1-0.45-0.27q-0.35-0.95-0.74-1.89t-0.82-1.82a0.62 0.62 0 0 1-0.06-0.26 0.43 0.43 0 0 1 0.07-0.23 0.44 0.44 0 0 1 0.16-0.15 0.45 0.45 0 0 1 0.22-0.05q0.26 0 0.47 0.27 0.29 0.6 0.58 1.26t0.57 1.36c0.02-0.05 0.04-0.1 0.06-0.14s0.04-0.11 0.05-0.18 0.05-0.15 0.08-0.24 0.07-0.21 0.12-0.34q0.15-0.42 0.29-0.85t0.27-0.88z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M12.76 15.11l-0.18 0.69h-0.35l0.16-0.63c0.02-0.07 0-0.09-0.03-0.09s-0.09 0.03-0.14 0.09l-0.16 0.63h-0.35l0.25-0.94h0.3v0.1a0.48 0.48 0 0 1 0.32-0.13c0.16 0 0.23 0.1 0.18 0.28z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M13.69 15.42h-0.57c-0.02 0.14 0.04 0.16 0.15 0.16a0.48 0.48 0 0 0 0.23-0.08l0.09 0.19a0.77 0.77 0 0 1-0.42 0.14c-0.34 0-0.45-0.21-0.38-0.5 0.07-0.27 0.29-0.51 0.61-0.51 0.28 0 0.41 0.18 0.33 0.49-0.01 0.03-0.02 0.08-0.04 0.11z m-0.28-0.2c0.02-0.09 0.02-0.17-0.07-0.18-0.07 0-0.12 0.04-0.17 0.19h0.24z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.31 15.79l-0.05-0.42-0.27 0.42h-0.39l0.5-0.65-0.15-0.57h0.39l0.04 0.35 0.24-0.35h0.38l-0.46 0.56 0.16 0.66z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.13 15.82c-0.24 0-0.31-0.13-0.24-0.38l0.09-0.35h-0.11l0.06-0.24h0.11l0.05-0.2 0.36-0.04-0.06 0.24h0.21l-0.1 0.24h-0.17l-0.09 0.35c-0.02 0.09 0 0.11 0.04 0.11a0.22 0.22 0 0 0 0.1-0.02l0.05 0.21a0.66 0.66 0 0 1-0.3 0.08z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M1.01 5.18c-0.56 0.32-1.01 1.11-1.01 1.75l4.09 2.36c0-0.64 0.46-1.43 1.01-1.75l4.46-2.58v-4.72z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M0 16.8c0 0.64 0.46 1.43 1.01 1.75l4.09-2.36c-0.56-0.32-1.01-1.11-1.01-1.75v-5.15l-4.09-2.36z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M9.56 23.48c0.56 0.32 1.47 0.32 2.02 0v-4.72c-0.56 0.32-1.47 0.32-2.02 0l-4.46-2.57-4.09 2.36z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M20.13 18.55c0.56-0.32 1.01-1.11 1.01-1.75l-4.09-2.36c0 0.64-0.46 1.43-1.01 1.75l-4.46 2.57v4.72z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M21.14 6.93c0-0.64-0.45-1.43-1.01-1.75l-4.09 2.36c0.56 0.32 1.01 1.11 1.01 1.75v5.15l4.09 2.36z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M11.58 0.24c-0.56-0.32-1.47-0.32-2.02 0v4.72c0.56-0.32 1.47-0.32 2.02 0l4.46 2.58 4.09-2.36z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.89 19.62l-0.01 0.09a0.24 0.24 0 0 0-0.13 0.02 0.1 0.1 0 0 0-0.04 0.04 0.04 0.04 0 0 0 0 0.05 0.04 0.04 0 0 0 0.02 0.02 0.08 0.08 0 0 0 0.04 0 0.56 0.56 0 0 0 0.07-0.02 0.28 0.28 0 0 1 0.13-0.01 0.13 0.13 0 0 1 0.09 0.07 0.13 0.13 0 0 1 0 0.13 0.26 0.26 0 0 1-0.11 0.12 0.33 0.33 0 0 1-0.1 0.04 0.28 0.28 0 0 1-0.11 0l0.02-0.09a0.23 0.23 0 0 0 0.15-0.03 0.11 0.11 0 0 0 0.04-0.05 0.05 0.05 0 0 0 0-0.05 0.06 0.06 0 0 0-0.02-0.02 0.08 0.08 0 0 0-0.04-0.01 0.69 0.69 0 0 0-0.08 0.02 0.25 0.25 0 0 1-0.13 0.01 0.13 0.13 0 0 1-0.08-0.07 0.12 0.12 0 0 1-0.01-0.08 0.17 0.17 0 0 1 0.03-0.08 0.25 0.25 0 0 1 0.08-0.07 0.29 0.29 0 0 1 0.19-0.03z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.33 19.38a0.29 0.29 0 0 1 0.15 0.14 0.33 0.33 0 0 1 0.04 0.14 0.22 0.22 0 0 1-0.12 0.21 0.22 0.22 0 0 1-0.18 0.03 0.28 0.28 0 0 1-0.16-0.14 0.31 0.31 0 0 1-0.04-0.14 0.22 0.22 0 0 1 0.02-0.12 0.24 0.24 0 0 1 0.1-0.09 0.22 0.22 0 0 1 0.19-0.03z m-0.15 0.31a0.25 0.25 0 0 0 0.09 0.1 0.1 0.1 0 0 0 0.14-0.08 0.33 0.33 0 0 0-0.13-0.23 0.09 0.09 0 0 0-0.1 0.01c-0.06 0.04-0.06 0.1 0 0.2z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.53 19.07a0.08 0.08 0 0 0 0.01 0.06l0.03 0.04 0.11-0.06 0.03 0.08-0.1 0.06 0.21 0.36-0.1 0.06-0.21-0.36-0.07 0.04-0.05-0.08 0.07-0.04-0.02-0.05a0.15 0.15 0 0 1-0.02-0.12 0.19 0.19 0 0 1 0.09-0.1 0.32 0.32 0 0 1 0.13-0.04l0.01 0.08a0.2 0.2 0 0 0-0.08 0.03 0.07 0.07 0 0 0-0.04 0.04z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M16.23 19.35a0.19 0.19 0 0 1-0.03 0.05 0.23 0.23 0 0 1-0.06 0.05 0.15 0.15 0 0 1-0.12 0.02 0.18 0.18 0 0 1-0.09-0.09l-0.14-0.23-0.07 0.04-0.04-0.08 0.07-0.04-0.06-0.1 0.1-0.07 0.07 0.11 0.1-0.06 0.03 0.08-0.09 0.05 0.13 0.23a0.09 0.09 0 0 0 0.04 0.04 0.05 0.05 0 0 0 0.04-0.01 0.13 0.13 0 0 0 0.05-0.04z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M16.59 18.59l0.16 0.49-0.13 0.08-0.27-0.31 0.13 0.39-0.13 0.07-0.35-0.38 0.11-0.06 0.27 0.32-0.14-0.4 0.11-0.07 0.28 0.32-0.15-0.4z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M17.19 18.72a0.06 0.06 0 0 0 0.04 0l0.02 0.08a0.15 0.15 0 0 1-0.08 0.02 0.11 0.11 0 0 1-0.06-0.02 0.16 0.16 0 0 1-0.03 0.08 0.21 0.21 0 0 1-0.06 0.06 0.16 0.16 0 0 1-0.13 0.02 0.16 0.16 0 0 1-0.1-0.22 0.3 0.3 0 0 1 0.12-0.12l0.06-0.03-0.02-0.03a0.09 0.09 0 0 0-0.05-0.04 0.11 0.11 0 0 0-0.07 0.01 0.42 0.42 0 0 0-0.11 0.09l-0.06-0.05a0.52 0.52 0 0 1 0.14-0.13c0.11-0.06 0.2-0.05 0.26 0.06l0.1 0.18a0.08 0.08 0 0 0 0.03 0.04z m-0.13 0.02l-0.06-0.08-0.04 0.02c-0.07 0.04-0.09 0.08-0.06 0.14a0.08 0.08 0 0 0 0.04 0.04 0.07 0.07 0 0 0 0.06-0.01 0.11 0.11 0 0 0 0.06-0.1z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M17.36 18.13l0.04 0.12a0.22 0.22 0 0 0-0.04 0.01 0.08 0.08 0 0 0-0.05 0.07 0.24 0.24 0 0 0 0.02 0.1l0.13 0.24-0.1 0.06-0.26-0.44 0.1-0.05 0.06 0.08a0.18 0.18 0 0 1 0-0.1 0.12 0.12 0 0 1 0.06-0.06 0.17 0.17 0 0 1 0.04-0.03z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M17.91 18.16l-0.28 0.16a0.16 0.16 0 0 0 0.09 0.07 0.12 0.12 0 0 0 0.09-0.01 0.17 0.17 0 0 0 0.05-0.04 0.27 0.27 0 0 0 0.04-0.07l0.08 0.04a0.29 0.29 0 0 1-0.13 0.15 0.23 0.23 0 0 1-0.19 0.03 0.28 0.28 0 0 1-0.15-0.14 0.35 0.35 0 0 1-0.05-0.13 0.22 0.22 0 0 1 0.02-0.12 0.21 0.21 0 0 1 0.09-0.09 0.2 0.2 0 0 1 0.17-0.03 0.28 0.28 0 0 1 0.15 0.14z m-0.15-0.01c-0.04-0.08-0.09-0.1-0.15-0.07a0.08 0.08 0 0 0-0.04 0.06 0.19 0.19 0 0 0 0.02 0.11l0.18-0.1z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M13.04 19.68l0.19 0.33-0.17 0.09-0.17-0.3c-0.02-0.03-0.03-0.04-0.05-0.02s-0.03 0.04-0.03 0.08l0.17 0.29-0.16 0.09-0.26-0.44 0.14-0.08 0.04 0.04a0.2 0.2 0 0 1 0.1-0.14 0.13 0.13 0 0 1 0.2 0.06z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M13.61 19.56l-0.27 0.15a0.09 0.09 0 0 0 0.13 0.03 0.2 0.2 0 0 0 0.08-0.1l0.12 0.05a0.33 0.33 0 0 1-0.15 0.17 0.28 0.28 0 0 1-0.29-0.47c0.13-0.08 0.27-0.04 0.35 0.11a0.49 0.49 0 0 1 0.03 0.06z m-0.22-0.01c-0.03-0.04-0.06-0.08-0.1-0.05s-0.04 0.05-0.01 0.12l0.11-0.07z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.05 19.54l-0.2-0.16 0.04 0.24-0.18 0.11-0.02-0.4-0.3-0.19 0.18-0.11 0.16 0.13-0.02-0.2 0.18-0.11v0.35l0.34 0.22z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.45 19.33c-0.11 0.06-0.2 0.03-0.27-0.09l-0.1-0.17-0.05 0.03-0.06-0.11 0.05-0.03-0.05-0.09 0.15-0.12 0.07 0.12 0.09-0.06 0.05 0.12-0.08 0.05 0.1 0.16c0.02 0.04 0.04 0.04 0.06 0.04a0.1 0.1 0 0 0 0.04-0.04l0.11 0.07a0.29 0.29 0 0 1-0.11 0.12z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M14.95 18.46a0.49 0.49 0 0 1-0.12 0.09 0.16 0.16 0 0 1 0.09 0.01 0.13 0.13 0 0 1 0.05 0.06 0.16 0.16 0 0 1 0.03 0.09 0.17 0.17 0 0 1-0.03 0.09 0.25 0.25 0 0 1-0.08 0.07 0.2 0.2 0 0 1-0.06 0.02 0.04 0.04 0 0 0 0 0.05c0.01 0.02 0.04 0.02 0.07 0l0.07-0.04a0.24 0.24 0 0 1 0.09-0.04 0.15 0.15 0 0 1 0.08 0.02 0.12 0.12 0 0 1 0.06 0.05 0.14 0.14 0 0 1 0 0.14 0.34 0.34 0 0 1-0.14 0.13 0.44 0.44 0 0 1-0.13 0.05 0.13 0.13 0 0 1-0.09-0.01 0.16 0.16 0 0 1-0.06-0.07l0.09-0.05a0.08 0.08 0 0 0 0.03 0.03 0.06 0.06 0 0 0 0.05 0 0.28 0.28 0 0 0 0.07-0.03 0.21 0.21 0 0 0 0.08-0.06 0.05 0.05 0 0 0 0-0.06 0.05 0.05 0 0 0-0.05-0.03 0.11 0.11 0 0 0-0.06 0.02l-0.07 0.04a0.18 0.18 0 0 1-0.1 0.03 0.08 0.08 0 0 1-0.07-0.05 0.1 0.1 0 0 1-0.01-0.05 0.13 0.13 0 0 1 0.02-0.06 0.16 0.16 0 0 1-0.09-0.02 0.15 0.15 0 0 1-0.06-0.06 0.17 0.17 0 0 1-0.03-0.09 0.18 0.18 0 0 1 0.03-0.1 0.26 0.26 0 0 1 0.08-0.07 0.43 0.43 0 0 0 0.11-0.08 0.52 0.52 0 0 0 0.07-0.09z m-0.25 0.23a0.1 0.1 0 0 0 0.01 0.07 0.11 0.11 0 0 0 0.06 0.05 0.09 0.09 0 0 0 0.07-0.01 0.09 0.09 0 0 0 0.04-0.05 0.11 0.11 0 0 0-0.01-0.08 0.08 0.08 0 0 0-0.13-0.04 0.08 0.08 0 0 0-0.04 0.06z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.49 18.46l-0.28 0.16a0.16 0.16 0 0 0 0.09 0.07 0.12 0.12 0 0 0 0.09-0.01 0.2 0.2 0 0 0 0.05-0.04 0.31 0.31 0 0 0 0.03-0.06l0.08 0.03a0.29 0.29 0 0 1-0.12 0.15 0.22 0.22 0 0 1-0.19 0.03 0.28 0.28 0 0 1-0.16-0.14 0.35 0.35 0 0 1-0.04-0.13 0.23 0.23 0 0 1 0.02-0.12 0.23 0.23 0 0 1 0.26-0.12 0.28 0.28 0 0 1 0.15 0.14z m-0.15-0.01c-0.04-0.08-0.1-0.1-0.15-0.07a0.08 0.08 0 0 0-0.05 0.06 0.19 0.19 0 0 0 0.03 0.11l0.17-0.1z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M15.78 17.99a0.16 0.16 0 0 1 0.09 0.08l0.18 0.32-0.11 0.06-0.17-0.3a0.1 0.1 0 0 0-0.04-0.05 0.06 0.06 0 0 0-0.05 0.01 0.09 0.09 0 0 0-0.04 0.04 0.24 0.24 0 0 0-0.02 0.08l0.18 0.3-0.11 0.06-0.25-0.44 0.09-0.05 0.04 0.05a0.19 0.19 0 0 1 0.1-0.14 0.13 0.13 0 0 1 0.11-0.02z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M16.43 17.92l-0.28 0.16a0.16 0.16 0 0 0 0.09 0.07 0.12 0.12 0 0 0 0.09-0.01 0.19 0.19 0 0 0 0.05-0.04 0.31 0.31 0 0 0 0.03-0.06l0.08 0.03a0.29 0.29 0 0 1-0.13 0.15 0.23 0.23 0 0 1-0.19 0.03 0.28 0.28 0 0 1-0.15-0.14 0.33 0.33 0 0 1-0.04-0.13 0.21 0.21 0 0 1 0.02-0.12 0.21 0.21 0 0 1 0.08-0.09 0.21 0.21 0 0 1 0.18-0.03 0.29 0.29 0 0 1 0.14 0.14z m-0.15-0.01c-0.04-0.08-0.09-0.1-0.15-0.07a0.08 0.08 0 0 0-0.05 0.06 0.2 0.2 0 0 0 0.03 0.11l0.17-0.1z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M16.64 17.46l0.04 0.11a0.15 0.15 0 0 0-0.04 0.02 0.09 0.09 0 0 0-0.05 0.06 0.26 0.26 0 0 0 0.02 0.11l0.13 0.23-0.1 0.06-0.26-0.44 0.09-0.05 0.06 0.08a0.19 0.19 0 0 1 0.01-0.1 0.12 0.12 0 0 1 0.05-0.07 0.18 0.18 0 0 1 0.05-0.01z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M17.22 17.61a0.06 0.06 0 0 0 0.03 0l0.02 0.08a0.16 0.16 0 0 1-0.07 0.02 0.1 0.1 0 0 1-0.06-0.02 0.17 0.17 0 0 1-0.03 0.08 0.19 0.19 0 0 1-0.07 0.05 0.16 0.16 0 0 1-0.12 0.03 0.15 0.15 0 0 1-0.1-0.08 0.14 0.14 0 0 1-0.01-0.14 0.29 0.29 0 0 1 0.13-0.13l0.06-0.03-0.02-0.02a0.09 0.09 0 0 0-0.05-0.05 0.11 0.11 0 0 0-0.08 0.02 0.44 0.44 0 0 0-0.1 0.09l-0.07-0.06a0.53 0.53 0 0 1 0.15-0.12c0.11-0.06 0.2-0.05 0.25 0.05l0.11 0.19a0.08 0.08 0 0 0 0.03 0.04z m-0.14 0.02l-0.05-0.09-0.04 0.03c-0.07 0.04-0.09 0.08-0.06 0.14a0.08 0.08 0 0 0 0.04 0.04 0.07 0.07 0 0 0 0.06-0.01 0.11 0.11 0 0 0 0.05-0.11z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M17.62 17.45a0.18 0.18 0 0 1-0.03 0.06 0.25 0.25 0 0 1-0.06 0.04 0.15 0.15 0 0 1-0.12 0.02 0.18 0.18 0 0 1-0.09-0.09l-0.14-0.23-0.06 0.04-0.05-0.08 0.07-0.04-0.06-0.1 0.1-0.07 0.07 0.11 0.1-0.06 0.03 0.08-0.09 0.05 0.13 0.23a0.08 0.08 0 0 0 0.04 0.04 0.05 0.05 0 0 0 0.04 0 0.13 0.13 0 0 0 0.05-0.05z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M17.55 16.94l0.25 0.44-0.1 0.06-0.26-0.44z m-0.12-0.2a0.08 0.08 0 0 1 0.05 0.09 0.08 0.08 0 0 1-0.04 0.05 0.07 0.07 0 0 1-0.06 0 0.07 0.07 0 0 1-0.04-0.03 0.07 0.07 0 0 1-0.01-0.06 0.08 0.08 0 0 1 0.04-0.04 0.07 0.07 0 0 1 0.06-0.01z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M18.04 16.72a0.29 0.29 0 0 1 0.15 0.14 0.32 0.32 0 0 1 0.04 0.14 0.22 0.22 0 0 1-0.02 0.12 0.23 0.23 0 0 1-0.09 0.09 0.22 0.22 0 0 1-0.19 0.03 0.29 0.29 0 0 1-0.15-0.14 0.33 0.33 0 0 1-0.05-0.14 0.21 0.21 0 0 1 0.03-0.12 0.23 0.23 0 0 1 0.09-0.09 0.22 0.22 0 0 1 0.19-0.03z m-0.15 0.31a0.24 0.24 0 0 0 0.09 0.1 0.1 0.1 0 0 0 0.14-0.08 0.26 0.26 0 0 0-0.04-0.13 0.26 0.26 0 0 0-0.09-0.1 0.09 0.09 0 0 0-0.09 0.01c-0.06 0.04-0.07 0.11-0.01 0.21z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
          <path
            class="fill-current"
            d="M18.5 16.42a0.16 0.16 0 0 1 0.09 0.08l0.19 0.32-0.11 0.06-0.17-0.3a0.1 0.1 0 0 0-0.05-0.05 0.06 0.06 0 0 0-0.05 0.01 0.09 0.09 0 0 0-0.04 0.05 0.23 0.23 0 0 0-0.01 0.07l0.17 0.31-0.1 0.06-0.26-0.44 0.09-0.06 0.04 0.05a0.19 0.19 0 0 1 0.1-0.14 0.14 0.14 0 0 1 0.11-0.02z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'AMMINISTRAZIONE'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <g
          :class="currentRoute.path.includes(props.code) && configVars.fill1"
          class="nc-icon-wrapper"
        >
          <path
            class="fill-current"
            d="M10.74 2.45a1.32 1.32 0 0 0-1.31 1.31v0.91a7.82 7.82 0 0 0-2.55 1.49l-0.8-0.45a1.31 1.31 0 0 0-1.78 0.5l-1.27 2.29a1.32 1.32 0 0 0 0.51 1.79l0.79 0.45a7.83 7.83 0 0 0-0.13 1.31 7.93 7.93 0 0 0 0.13 1.31l-0.79 0.45a1.32 1.32 0 0 0-0.51 1.79l1.28 2.28a1.31 1.31 0 0 0 1.78 0.5l0.8-0.44a7.82 7.82 0 0 0 2.55 1.48v0.92a1.32 1.32 0 0 0 1.31 1.31h2.61a1.32 1.32 0 0 0 1.31-1.31v-0.92a7.85 7.85 0 0 0 2.54-1.48l0.8 0.44a1.32 1.32 0 0 0 1.78-0.5c0.43-0.8 0.89-1.61 1.29-2.28a1.32 1.32 0 0 0-0.51-1.79l-0.8-0.45a7.75 7.75 0 0 0 0.14-1.31 7.83 7.83 0 0 0-0.14-1.31l0.8-0.45a1.32 1.32 0 0 0 0.51-1.79c-0.46-0.79-0.91-1.61-1.29-2.28a1.32 1.32 0 0 0-1.78-0.5l-0.8 0.44a7.85 7.85 0 0 0-2.54-1.49v-0.91a1.32 1.32 0 0 0-1.31-1.31z m0 0.87h2.62a0.43 0.43 0 0 1 0.43 0.44v1.2a0.45 0.45 0 0 0 0.31 0.41 6.94 6.94 0 0 1 2.76 1.62 0.45 0.45 0 0 0 0.51 0.07l1.05-0.59a0.43 0.43 0 0 1 0.6 0.17l1.28 2.28a0.44 0.44 0 0 1-0.17 0.6l-1.04 0.59a0.45 0.45 0 0 0-0.22 0.47 6.85 6.85 0 0 1 0 2.93 0.45 0.45 0 0 0 0.21 0.47l1.04 0.58a0.44 0.44 0 0 1 0.17 0.6l-1.28 2.29a0.43 0.43 0 0 1-0.59 0.17l-1.05-0.59a0.45 0.45 0 0 0-0.51 0.06 6.94 6.94 0 0 1-2.76 1.62 0.45 0.45 0 0 0-0.31 0.43v1.2a0.43 0.43 0 0 1-0.44 0.43h-2.61a0.43 0.43 0 0 1-0.44-0.43v-1.2a0.45 0.45 0 0 0-0.31-0.43 6.94 6.94 0 0 1-2.76-1.62 0.45 0.45 0 0 0-0.51-0.06l-1.05 0.59a0.42 0.42 0 0 1-0.59-0.17c-0.46-0.79-0.91-1.61-1.28-2.29a0.44 0.44 0 0 1 0.17-0.6l1.04-0.58a0.45 0.45 0 0 0 0.21-0.47 7.23 7.23 0 0 1 0-2.93 0.45 0.45 0 0 0-0.21-0.47l-1.05-0.59a0.44 0.44 0 0 1-0.17-0.6c0.44-0.8 0.89-1.61 1.28-2.28a0.42 0.42 0 0 1 0.59-0.17l1.05 0.58a0.45 0.45 0 0 0 0.51-0.06 6.94 6.94 0 0 1 2.77-1.62 0.45 0.45 0 0 0 0.31-0.41v-1.2a0.43 0.43 0 0 1 0.44-0.44z m1.31 4.36a4.37 4.37 0 1 0 4.36 4.37 4.37 4.37 0 0 0-4.36-4.37z m0 0.88a3.49 3.49 0 1 1-3.49 3.49 3.49 3.49 0 0 1 3.49-3.49z"
            :class="currentRoute.path.includes(props.code) && configVars.fill1"
          ></path>
        </g>
      </svg>
    </template>
    <template v-if="props.code === 'Download'">
      <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
        <path class="fill-current" :class="[currentRoute.path.includes(props.code) && configVars.fill1, configVars.fill1]" d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"></path>
        <path class="fill-current" :class="[currentRoute.path.includes(props.code) && configVars.fill3, configVars.fill3]" d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"></path>
      </svg>
    </template>
  </div>
</template>
