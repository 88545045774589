<script setup>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const route = useRoute();

store.commit("user/setClienteAbbinato", {
  Cliente_dbKey: "",
  ClienteAbbinato: "",
  PostelTramite: "",
  Cliente: "",
  Gdpr: "",
  conto_dbKey: ''
});
store.commit("GRPOPERS/setClientiDropdown", []);
store.commit("amm/setCustomerData", null);

store.dispatch("user/logout").then(() => {
  router.push(route.query.from ? route.query.from : "/loginCliente");
});
</script>
<template>
  <router-link to="/">Homepage</router-link>
</template>
