<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import SidebarLinkGroup from "./SidebarLinkGroup.vue";
import IconsMenu from "./IconsMenu.vue";

const store = useStore();
const props = defineProps(["sidebarOpen"]);
const emit = defineEmits(["close-sidebar"]);

const trigger = ref(null);
const sidebar = ref(null);

const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
const sidebarExpanded = ref(
  storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
);

const currentRoute = computed(() => useRouter().currentRoute.value);

// close on click outside
const clickHandler = ({ target }) => {
  if (!sidebar.value || !trigger.value) return;
  if (
    !props.sidebarOpen ||
    sidebar.value.contains(target) ||
    trigger.value.contains(target)
  )
    return;
  emit("close-sidebar");
};

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
  if (!props.sidebarOpen || keyCode !== 27) return;
  emit("close-sidebar");
};

// close router link
const clickLink = () => {
  emit("close-sidebar");
};

onMounted(() => {
  document.addEventListener("click", clickHandler);
  document.addEventListener("keydown", keyHandler);
});

onUnmounted(() => {
  document.removeEventListener("click", clickHandler);
  document.removeEventListener("keydown", keyHandler);
});

watch(sidebarExpanded, () => {
  localStorage.setItem("sidebar-expanded", sidebarExpanded.value);

  if (sidebarExpanded.value) {
    document.querySelector("body").classList.add("sidebar-expanded");
  } else {
    document.querySelector("body").classList.remove("sidebar-expanded");
  }
});
const route = useRoute();
const resetValue = ref(false);

watch(
  () => route.path,
  () => {
    resetValue.value = Math.random();
  },
);

const menu = computed(() => store.getters["menu/getMainMenu"]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);

const router = useRouter();
const user = computed(() => store.getters["user/getUser"]);
const gotoLogout = () => {
  router.push(
    user.value.IsOper
      ? "/logout?from=loginOperatore"
      : "/logout?from=loginCliente",
  );
};

const findUrl = (params) => {
  let explode = params.split(";");
  let url = "";
  explode.forEach((el) => {
    if (el.includes("URL=")) {
      url = el.substring(4);
    }
    if (el.includes("URLAPP=")) {
      url = el.substring(7);
    }
  });

  return url;
};

const urlHtml = (params) => {
  return params.replace('URLHTML=', '').replace('<button', '<button class="text-sm font-medium block transition duration-150 truncate' + ' ' + configVars.value.tertiaryText + ' ' + configVars.value.sidebarTwo + '"')
}
</script>
<style>
.selectedLink {
  /*color: v-bind('menuColor.linkMenu') !important*/
}
</style>
<template>
  <div>
    <!-- Sidebar backdrop (mobile only) -->
    <div
      class="fixed inset-0 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200"
      :class="[
        sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none',
        configVars.primary,
      ]"
      aria-hidden="true"
    ></div>

    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      class="flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 p-4 transition-all duration-200 ease-in-out"
      :class="[
        sidebarOpen ? 'translate-x-0' : '-translate-x-64',
        configVars.primaryHover,
      ]"
    >
      <!-- Sidebar header -->
      <div class="flex justify-between mb-10 pr-3 sm:px-2">
        <!-- Close button -->
        <button
          ref="trigger"
          class="lg:hidden"
          :class="[configVars.sidebarThree, 'hover:' + configVars.sidebarTwo]"
          @click.stop="$emit('close-sidebar')"
          aria-controls="sidebar"
          :aria-expanded="sidebarOpen"
        >
          <span class="sr-only">Chiudi</span>
          <svg
            class="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
            />
          </svg>
        </button>
        <!-- Logo -->
        <router-link class="block" to="/">
          <img
            :src="'/data/' + configVars.licenseKey + '/' + configVars.logoSmall"
            alt=""
          />
        </router-link>
      </div>

      <!-- Links -->
      <div class="space-y-8" v-if="store">
        <!-- Pages group -->
        <div>
          <h3
            class="text-xs uppercase font-semibold pl-3"
            :class="configVars.sidebarThree"
          >
            <span
              class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
              aria-hidden="true"
              >•••</span
            >
            <span class="lg:hidden lg:sidebar-expanded:block 2xl:block"
              >Menu</span
            >
          </h3>
          <div class="!text-cyan-800"></div>
          <ul class="mt-3 side-nav">
            <!-- Dashboard -->
            <router-link
              to="/dashboard"
              custom
              v-slot="{ href, navigate, isExactActive }"
              v-if="!user.DatiUtenza[0].Gdpr"
            >
              <li
                class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
                :class="isExactActive && configVars.primary"
                @click="clickLink"
              >
                <a
                  class="block hover:text-white truncate transition duration-150"
                  :class="[
                    isExactActive && 'hover:' + configVars.sidebarSel,
                    configVars.fourthText,
                  ]"
                  :href="href"
                  @click="navigate"
                >
                  <div class="flex items-center">
                    <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        class="fill-current"
                        :class="[
                          (currentRoute.fullPath === '/' ||
                            currentRoute.fullPath.includes('dashboard')) &&
                            '!' + configVars.tertiaryText,
                          configVars.sidebarTwo,
                        ]"
                        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                      />
                      <path
                        class="fill-current"
                        :class="[
                          (currentRoute.fullPath === '/' ||
                            currentRoute.fullPath.includes('dashboard')) &&
                            configVars.tertiaryText,
                          configVars.tertiaryText,
                        ]"
                        d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                      />
                      <path
                        class="fill-current"
                        :class="[
                          (currentRoute.fullPath === '/' ||
                            currentRoute.fullPath.includes('dashboard')) &&
                            configVars.fourthText,
                          configVars.sidebarTwo,
                        ]"
                        d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                      />
                    </svg>
                    <span
                      class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                      >Dashboard</span
                    >
                  </div>
                </a>
              </li>
            </router-link>
            <!-- Community -->
            <template v-if="!user.DatiUtenza[0].Gdpr">
              <template v-if="!menu.length">
                <div class="flex w-full py-4 justify-center">
                  <img src="../images/loading.gif" alt="" class="w-9" />
                </div>
              </template>
              <template v-else>
                <template v-for="i in menu">
                  <template v-if="i.childs.length === 0">
                    <router-link
                      :to="'/' + i.code + '/' + i.code"
                      custom
                      v-slot="{ href, navigate, isExactActive }"
                    >
                      <li
                        class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
                        :class="isExactActive && configVars.primary"
                        @click="clickLink"
                      >
                        <a
                          class="block hover:text-white truncate transition duration-150"
                          :class="[
                            isExactActive && 'hover:' + configVars.fourthText,
                            configVars.fourthText,
                          ]"
                          :href="href"
                          @click="navigate"
                        >
                          <div class="flex items-center">
                            <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                              <path
                                class="fill-current"
                                :class="[
                                  currentRoute.fullPath.includes(i.code) &&
                                    '!' + configVars.tertiaryText,
                                  configVars.sidebarTwo,
                                ]"
                                d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                              />
                              <path
                                class="fill-current"
                                :class="[
                                  currentRoute.fullPath.includes(i.code) &&
                                    configVars.tertiaryText,
                                  configVars.tertiaryText,
                                ]"
                                d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                              />
                              <path
                                class="fill-current"
                                :class="[
                                  currentRoute.fullPath.includes(i.code) &&
                                    configVars.fourthText,
                                  configVars.sidebarTwo,
                                ]"
                                d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                              />
                            </svg>
                            <span
                              class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                              >{{ i.description }}</span
                            >
                          </div>
                        </a>
                      </li>
                    </router-link>
                  </template>
                  <template v-else>
                    <SidebarLinkGroup
                      v-slot="parentLink"
                      :resetValue="resetValue"
                      :currentRoute="i.group"
                      :activeCondition="currentRoute.fullPath.includes(i.group)"
                    >
                      <a
                        class="block hover:text-white truncate transition duration-150"
                        :class="[
                          currentRoute.fullPath.includes(i.group) &&
                            'hover:' + configVars.fourthText,
                          configVars.fourthText,
                        ]"
                        href="#0"
                        @click.prevent="
                          sidebarExpanded
                            ? parentLink.handleClick()
                            : (sidebarExpanded = true)
                        "
                      >
                        <div class="flex items-center justify-between">
                          <div class="flex items-center">
                            <IconsMenu :code="i.code" />
                            <span
                              class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                              >{{ i.description }}</span
                            >
                          </div>
                          <!-- Icon -->
                          <div class="flex shrink-0 ml-2">
                            <svg
                              class="w-3 h-3 shrink-0 ml-1 fill-current"
                              :class="[
                                parentLink.expanded && 'transform rotate-180',
                                configVars.sidebarTwo,
                              ]"
                              viewBox="0 0 12 12"
                            >
                              <path
                                d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z"
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div
                        class="lg:hidden lg:sidebar-expanded:block 2xl:block"
                      >
                        <ul
                          class="pl-9 mt-1 dacontr"
                          :class="!parentLink.expanded && 'hidden'"
                        >
                          <template v-for="k in i.childs">
                            <template
                              v-if="!k.autoexecute && !k.params.includes('URL')"
                            >
                              <router-link
                                :to="{
                                  path: '/' + i.group + '/' + k.code,
                                  query: { title: k.description },
                                }"
                                custom
                                v-slot="{ href, navigate, isExactActive }"
                              >
                                <li
                                  class="mb-1 last:mb-0"
                                  @click="clickLink"
                                  :title="k.description"
                                >
                                  <a
                                    class="block transition duration-150 truncate"
                                    :class="[
                                      isExactActive &&
                                        '!' + configVars.tertiaryText,
                                      configVars.sidebarTwo,
                                      'hover:' + configVars.fourthText,
                                    ]"
                                    :href="href"
                                    @click="navigate"
                                  >
                                    <span
                                      class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                                      >{{ k.description }}</span
                                    >
                                  </a>
                                </li>
                              </router-link>
                            </template>
                            <template
                              v-if="!k.autoexecute && k.params.includes('URL=')"
                            >
                              <a :href="findUrl(k.params)" target="_blank">
                                <li
                                  class="mb-1 last:mb-0"
                                  @click="clickLink"
                                  :title="k.description"
                                >
                                  <a
                                    class="block transition duration-150 truncate"
                                    :class="[
                                      configVars.tertiaryText,
                                      configVars.sidebarTwo,
                                      'hover:' + configVars.fourthText,
                                    ]"
                                  >
                                    <span
                                      class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                                      >{{ k.description }}</span
                                    >
                                  </a>
                                </li>
                              </a>
                            </template>
                            <template
                                v-if="!k.autoexecute && k.params.includes('URLHTML=')"
                            >
                              <li
                                  class="mb-1 last:mb-0"
                              >
                                <div v-html="urlHtml(k.params)"></div>
                              </li>
                            </template>
                            <template
                                v-if="!k.autoexecute && k.params.includes('URLAPP=')"
                            >
                              <a :href="findUrl(k.params)" target="_blank">
                                <li
                                    class="mb-1 last:mb-0"
                                    @click="clickLink"
                                    :title="k.description"
                                >
                                  <a
                                      class="block transition duration-150 truncate"
                                      :class="[
                                      configVars.tertiaryText,
                                      configVars.sidebarTwo,
                                      'hover:' + configVars.fourthText,
                                    ]"
                                  >
                                    <span
                                        class="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                                    >{{ k.description }}</span
                                    >
                                  </a>
                                </li>
                              </a>
                            </template>
                          </template>
                        </ul>
                      </div>
                    </SidebarLinkGroup>
                  </template>
                </template>
              </template>
            </template>
            <li
              class="px-3 py-2 rounded-sm mb-0.5 last:mb-0 cursor-pointer"
              @click="gotoLogout"
            >
              <a
                class="block hover:text-white truncate transition duration-150"
                :class="configVars.fourthText"
              >
                <div class="flex items-center">
                  <svg class="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                    <title>logout</title>
                    <g fill="none" class="nc-icon-wrapper">
                      <path
                        d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5-5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </svg>
                  <span
                    class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200"
                    >Log Out</span
                  >
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Expand / collapse button -->
      <div class="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
        <div class="px-3 py-2">
          <button @click.prevent="sidebarExpanded = !sidebarExpanded">
            <span class="sr-only">Expand / collapse sidebar</span>
            <svg
              class="w-6 h-6 fill-current sidebar-expanded:rotate-180"
              viewBox="0 0 24 24"
            >
              <path
                :class="configVars.sidebarTwo"
                d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
              />
              <path :class="configVars.tertiaryText" d="M3 23H1V1h2z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
