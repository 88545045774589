export default class GenericModel {
  public visibleName: string;
  public name: string;
  public visibleTable: boolean;
  public visibleDetail: boolean;
  public visibleDetailHidden: boolean;
  public type: string;
  public order: number;
  public cellTemplate: boolean | string;
  public group: string;
  public metadataField: boolean;

  constructor(
    name: string,
    visibleName: string,
    visibleTable: boolean,
    visibleDetail: boolean,
    visibleDetailHidden: boolean,
    type: string,
    cellTemplate: boolean | string,
    order: number,
    group: string = "",
    metadataField: boolean = true,
  ) {
    this.name = name;
    this.visibleName = visibleName;
    this.visibleTable = visibleTable;
    this.visibleTable = visibleTable;
    this.visibleDetail = visibleDetail;
    this.visibleDetailHidden = visibleDetailHidden;
    this.type = type;
    this.cellTemplate = cellTemplate;
    this.order = order;
    this.group = group;
    this.metadataField = metadataField;
  }
}
