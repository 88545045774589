<script setup lang="ts">
import { parseError } from "../../composables/parseError";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
const { errorSys, calcError } = parseError();
const store = useStore();
const loading = ref(true);
const loaded = ref(false);
const addTicket = ref(false)
import Table from "../../components/dom/Table.vue";
import { data } from "../../composables/api/data";
const {
  exportedData,
  exportedMetaDataFields,
  exportedTableInfo,
  elaborateMetaDataFields,
  elaborateData,
  elaborateTableInfo,
} = data();
const props = defineProps(["title"]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const addToggleTicket = () => {
  addTicket.value = !addTicket.value;
};

const user = computed(() => store.getters["user/getUser"]);

const loadData = () => {
  let payload

  if (user.value.DatiUtenza[0].Cliente_dbKey != "") {
    payload = {
      beName: "Tickets",
      beView: "crmTicketLista",
      includeMetadata: "view",
      solveDynamicMetadata: false,
      filter: "{Stato} == 2 and {conto_dbKey} == '" + user.value.DatiUtenza[0].conto_dbKey + "'"
    }
  } else {
    payload = {
      beName: "Tickets",
      beView: "crmTicketLista",
      includeMetadata: "view",
      solveDynamicMetadata: false,
    }
  }

  store
    .dispatch("generic/beRetrieve", payload)
    .then((res) => {
      elaborateData(res.data.apiResult.data);

      if (res.data.apiResult.data && res.data.apiResult.metadata) {
        if (res.data.apiResult.metadata.fields) {
          elaborateMetaDataFields(res.data.apiResult.metadata.fields);
          elaborateTableInfo(res.data.apiResult.metadata.tableInfo);
          loaded.value = true;
        }
      }

      loading.value = false;
    })
    .catch((e) => {
      calcError(e);
    });
};

onMounted(() => {
  loadData();
});

const deleteTicket = () => {
  loading.value = true;
  loaded.value = false;
  loadData();
};
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8">
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          <template v-if="user.DatiUtenza[0].Cliente_dbKey != ''">
            I ticket di {{ user.DatiUtenza[0].ClienteAbbinato }}
          </template>
          <template v-else>
            {{ props.title }}
          </template>
        </h1>
      </div>
      <div
          class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
      >
        <!-- Add customer button -->
        <button
            class="btn text-white"
            :class="configVars.primary + ' hover:' + configVars.primaryHover"
            @click="addToggleTicket"
        >
          <svg
              class="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
          >
            <path
                d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
            />
          </svg>
          <span class="hidden xs:block ml-2"
          >Aggiungi un ticket</span>
        </button>
      </div>
    </div>
    <div v-if="loading">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <Table
      :items="exportedData"
      :metadataFields="exportedMetaDataFields"
      :tableInfo="exportedTableInfo"
      type="tickets"
      :addTicket="addTicket"
      :loaded="loaded"
      @refreshData="loadData"
      @deleteTicket="deleteTicket"
    />
  </div>
</template>
