<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

const router = useRouter();
const store = useStore();

onMounted(() => {
  //store.dispatch('menu/fetchLicense', 'https://sviluppoweb.iv-srl.it:444')
  store
    .dispatch("menu/fetchLicense", "https://" + window.location.hostname)
    .then(() => {
      router.push("/loginCliente");
    });
});
</script>
<template>
  <h1 class="text-danger">Licenza non attiva</h1>
</template>
