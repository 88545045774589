<script setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { helpers, required, requiredIf, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

const emit = defineEmits(["updating", "errorUpdating"]);
const loading = ref(true);
const loading2 = ref(true);
const store = useStore();
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const user = computed(() => store.getters["user/getUser"]);
const data = computed(() => store.getters["amm/getCustomerData"]);

const form = reactive({
  Amministrativo: 0,
  Email: 0,
  Sms: 0,
  Terzi: 0,
});

const labels = reactive({
  Amministrativo: "",
  Email: "",
  Sms: "",
  Terzi: "",
});

onMounted(() => {
  store
    .dispatch("GRPPROFILO/fetchGdpr", {
      Cliente: user.value.DatiUtenza[0].Cliente,
    })
    .then((res) => {
      form.Amministrativo = res.GDPR.Amministrativo;
      form.Email = res.GDPR.Email;
      form.Sms = res.GDPR.Sms;
      form.Terzi = res.GDPR.Terzi;
      loading.value = false;
    })
    .catch((e) => {
      emit("errorUpdating", e);
    });

  if (data.value) {
    labels.Amministrativo = data.value.privacyAmm;
    labels.Email = data.value.privacyMail;
    labels.Sms = data.value.privacySms;
    labels.Terzi = data.value.privacyThird;
    loading2.value = false;
  } else {
    store
      .dispatch("amm/fetchCustomerData")
      .then(() => {
        labels.Amministrativo = data.value.privacyAmm;
        labels.Email = data.value.privacyMail;
        labels.Sms = data.value.privacySms;
        labels.Terzi = data.value.privacyThird;
        loading2.value = false;
      })
      .catch((e) => (errorSys.value = e.response.data.message));
  }
});

const rules = {
  Amministrativo: {
    sameAs: helpers.withMessage(
      "Campo obbligatorio",
      sameAs(
        computed(() => {
          if (labels.Amministrativo != "") {
            return true;
          }
          return form.Amministrativo;
        }),
      ),
    ),
  },
  Email: {},
  Sms: {},
  Terzi: {
    sameAs: helpers.withMessage(
      "Campo obbligatorio",
      sameAs(
        computed(() => {
          if (labels.Terzi != "") {
            return true;
          }
          return form.Terzi;
        }),
      ),
    ),
  },
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const upGdpr = () => {
  const data = form;
  data.Cliente = user.value.DatiUtenza[0].Cliente;

  store
    .dispatch("GRPPROFILO/updateGdpr", data)
    .then(() => {
      store.commit("user/setGdprRequired", 0);
      emit("updating", true);
    })
    .catch((e) => {
      emit("errorUpdating", e);
    });
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 font-bold mb-5">GDPR</h2>
      <div class="mb-5" v-if="loading || loading2">
        <div
          class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
          :class="configVars.secondaryText + ' ' + configVars.primary"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                ></path>
              </svg>
              <div class="loading_course">Caricamento in corso</div>
            </div>
          </div>
        </div>
      </div>
      <section v-if="!loading && !loading2">
        <div class="mt-5">
          <div class="w-full" v-if="labels.Amministrativo">
            <label class="flex items-center">
              <input
                type="checkbox"
                class="form-checkbox"
                :class="'!' + configVars.primaryText"
                v-model="vv.Amministrativo.$model"
              />
              <span class="text-sm ml-2"
                >{{ labels.Amministrativo }}
                <span
                  :class="
                    labels.Amministrativo != '' ? 'text-rose-500' : 'text-white'
                  "
                  >*</span
                ></span
              >
            </label>
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vv.Amministrativo.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="w-full mt-3" v-if="labels.Email">
            <label class="flex items-center">
              <input
                type="checkbox"
                class="form-checkbox"
                :class="'!' + configVars.primaryText"
                v-model="vv.Email.$model"
              />
              <span class="text-sm ml-2"
                >{{ labels.Email }} <span class="text-white">*</span></span
              >
            </label>
          </div>
          <div class="w-full mt-3" v-if="labels.Sms">
            <label class="flex items-center">
              <input
                type="checkbox"
                class="form-checkbox"
                :class="'!' + configVars.primaryText"
                v-model="vv.Sms.$model"
              />
              <span class="text-sm ml-2"
                >{{ labels.Sms }} <span class="text-white">*</span></span
              >
            </label>
          </div>
          <div class="w-full mt-3" v-if="labels.Terzi">
            <label class="flex items-center">
              <input
                type="checkbox"
                class="form-checkbox"
                :class="'!' + configVars.primaryText"
                v-model="vv.Terzi.$model"
              />
              <span class="text-sm ml-2"
                >{{ labels.Terzi }}
                <span
                  :class="labels.Terzi != '' ? 'text-rose-500' : 'text-white'"
                  >*</span
                ></span
              >
            </label>
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vv.Terzi.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="w-full mt-5">
            <button
              class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="upGdpr"
              :disabled="vv.$invalid"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
            >
              Modifica
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
