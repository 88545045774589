<script setup>
import { computed, reactive, ref, toRef, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { parseError } from "../composables/parseError";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import { restrictChars } from "../composables/restrictChars";

const store = useStore();
const route = useRoute();
const { errorSys, calcError } = parseError();
const { restrictCharsTot, allowType } = restrictChars();

const form = reactive({
  CodFis: "",
  DataFt: "",
  Email: "",
  ImportoFt: "",
  NumeroFt: "",
});

const router = useRouter();
const registerSuccess = ref(false);

const rules = {
  CodFis: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  DataFt: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  Email: {
    email: helpers.withMessage("Il campo deve essere in formato email", email),
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  ImportoFt: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  NumeroFt: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
};
const vv = useVuelidate(rules, { ...toRefs(form) });

const submit = () => {
  let formToSubmit = {
    ...form,
    ImportoFt: parseFloat(form.ImportoFt.replace(/,/g, ".")),
    //parseFloat(form.ImportoFt.replace(/,/g, '')).toFixed(2),
  };
  store
    .dispatch("user/register", formToSubmit)
    .then(() => {
      registerSuccess.value = true;
      errorSys.value = false;
    })
    .catch((e) => {
      registerSuccess.value = false;
      calcError(e);
    });
};

const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>

<template>
  <main class="bg-white">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-screen h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <div
              class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
            </div>
          </div>

          <div class="max-w-sm mx-auto px-4 py-8">
            <img
              class="object-cover"
              :src="'/data/' + configVars.licenseKey + '/' + configVars.logo"
            />
            <div class="h-px bg-slate-200 mt-8 mb-6 w-full"></div>
            <h1 class="text-3xl text-slate-800 font-bold mb-6">
              Nuova registrazione
            </h1>
            <div
              class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-900 mb-4"
              v-if="registerSuccess"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                    ></path>
                  </svg>
                  <div>
                    Utente registrato con successo, controlla la tua email,
                    riceverai i dati per accedere
                  </div>
                </div>
              </div>
            </div>
            <div
              class="inline-flex min-w-80 px-4 py-2 rounded-sm text-sm text-white bg-rose-500 mb-4"
              v-if="errorSys"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                    ></path>
                  </svg>
                  <div class="font-medium">{{ errorSys }}</div>
                </div>
              </div>
            </div>
            <!-- Form -->
            <form @submit.prevent="submit" novalidate>
              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium mb-1" for="username"
                    >Codice Fiscale dell'intestatario della fattura
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="username"
                    v-model="vv.CodFis.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.CodFis.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="DataFt"
                    >Data della fattura
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="DataFt"
                    v-model="vv.DataFt.$model"
                    class="form-input w-full"
                    type="date"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.DataFt.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="Email"
                    >Email <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="Email"
                    v-model="vv.Email.$model"
                    class="form-input w-full"
                    type="email"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.Email.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="ImportoFt"
                    >Importo della fattura
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="ImportoFt"
                    v-model="vv.ImportoFt.$model"
                    class="form-input w-full"
                    type="text"
                    @keypress="allowType($event)"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.ImportoFt.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="NumeroFt"
                    >Numero della fattura
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="NumeroFt"
                    v-model="vv.NumeroFt.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.NumeroFt.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div class="flex items-center justify-between mt-6">
                <div class="mr-1">
                  <router-link
                    class="text-sm underline hover:no-underline"
                    to="/loginCliente"
                    >Torna alla login</router-link
                  >
                </div>
                <button
                  v-if="!registerSuccess"
                  type="submit"
                  class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                  :class="
                    configVars.primary + ' hover:' + configVars.primaryHover
                  "
                  :disabled="vv.$invalid"
                >
                  Iscriviti
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          :src="'/data/' + configVars.licenseKey + '/' + configVars.bgImage"
          width="760"
          height="1024"
          alt="Authentication"
        />
      </div>
    </div>
  </main>
</template>
