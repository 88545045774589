<script setup lang="ts">
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import {computed, ref, toRefs, watch} from "vue";
import { ContattiModelRules } from "../../models/contatti";
import { NoteCreditoModelRules } from "../../models/noteCredito";
import { PagamentoFattureModelRules } from "../../models/pagamentoFatture";
import { RichiediNoteCreditoModelRules } from "../../models/richiediNoteCredito";
import { useStore } from "vuex";
import { colors} from "../../composables/colors";
const { listItemColors } = colors();
const { errorSys, calcError } = parseError();
import ActionsTableContatti from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTableNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTablePagamentoFatture from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTableRichiediNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTableTickets from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTablePrezziSconti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalContatti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalFormContatti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ModalPagamentoFatture from "../GPR-OPERS/ROUTING/Index.vue";
import ModalRichiediNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ModalPrezziSconti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalTickets from "../GPR-OPERS/ROUTING/Index.vue";
import ModalFormTicket from "../GPR-OPERS/ROUTING/Index.vue";

const user = computed(() => store.getters["user/getUser"]);

const { formatDate } = convertDate();
const { secondsToHms } = datesLimit();
import { compare } from "../../composables/compare";
import { TicketsModelRules } from "../../models/tickets";
import { convertDate } from "../../composables/convertDate";
import {datesLimit} from "../../composables/datesLimit";
import {PrezziScontiModelRules} from "../../models/prezziSconti";
import {parseError} from "../../composables/parseError";
import {useRouter} from "vue-router";
const router = useRouter();
const { compareData } = compare();

const openBasicModalValue = ref(false);
const openEditModalValue = ref(false);

const store = useStore();
const updateRecapiti = ref(false);
const updateNote = ref(false);
const updateVerification = ref(false);
const updateCategory = ref(false);
const item = ref();

const props = defineProps([
  "items",
  "metadataFields",
  "tableInfo",
  "type",
  "addContact",
  "loaded",
  "upNote",
  "addTicket",
]);
const emit = defineEmits([
  "setPredef",
  "upRec",
  "addContatto",
  "refreshData",
  "upNote",
  "upVerification",
  "upCategory",
  "deleteContact",
  "addTicket",
  "deleteTicket"
]);

const { fields } =
    props.type == "contatti"
        ? ContattiModelRules()
        : props.type == "noteCredito"
            ? NoteCreditoModelRules()
            : props.type == "pagamentoFatture"
                ? PagamentoFattureModelRules()
                : props.type == "tickets"
                    ? TicketsModelRules()
                    : props.type == "prezziSconti"
                        ? PrezziScontiModelRules()
                        : RichiediNoteCreditoModelRules();

const items = computed(() => props.items);
const metadataFields = computed(() => props.metadataFields);
const tableInfo = computed(() => props.tableInfo);
const addContact = computed(() => props.addContact);
const addTicket = computed(() => props.addTicket);

const assignItem = (itemSelected: any) => {
  console.log(itemSelected)
  item.value = itemSelected;
};

// NOTE CREDITO

const refreshData = () => {
  emit("refreshData", true);
  openBasicModal(false);
};

// CONTATTI

const deleteContact = () => {
  emit("deleteContact", true);
};

const setPredef = (record: string) => {
  updateRecapiti.value = true;
  emit("setPredef", record);
};

const upRec = () => {
  updateRecapiti.value = true;
  emit("upRec", true);
};

const openEditModal = (value: boolean) => {
  openEditModalValue.value = value;
};

const editContatto = (item: any) => {
  assignItem(item);
  openBasicModal(false);
  openEditModal(true);
};

const openContatto = (item: any) => {
  assignItem(item);
  openBasicModal(true);
  openEditModal(false);
  emit("addContatto", true);
};

// TICKET

const openTicket = (item: any) => {
  assignItem(item);
  openBasicModal(true);
  openEditModal(false);
  emit("addTicket", true);
};

const editTicket = (item: any) => {
  assignItem(item);
  openBasicModal(false);
  openEditModal(true);
};

const deleteTicket = () => {
  openBasicModal(false)
  assignItem('')
  emit("deleteTicket");
};

watch(
    () => [props.addContact, props.addTicket],
    (oldValue, newValue) => {
      assignItem(null);
      openEditModal(true);
    },
    { deep: true },
);

// AUTORIZZAZIONE PAGAMENTO FATTURE

const upNote = (data: any) => {
  updateNote.value = true;
  emit("upNote", true);
};

const upVerification = () => {
  updateVerification.value = true;
  emit("upVerification", true);
};

const upCategory = () => {
  updateCategory.value = true;
  emit("upCategory", true);
};

const refreshDataNc = () => {
  emit("refreshData", true);
};

// PREZZI/SCONTI

// CONDIVISI

const openBasicModal = (value: boolean) => {
  openBasicModalValue.value = value;
};

const selected = toRefs<any>(props).items;

watch(
    selected,
    (newValue, oldValue) => {
      if (item.value) {
        let i = newValue.find(
            (el: { __dbKey: any }) => el.__dbKey === item.value.__dbKey,
        );
        if (i) {
          assignItem(i);
        } else {
          if (props.type !== "richiediNc") {
            openBasicModal(false);
          }
        }

        updateRecapiti.value = false;
        updateNote.value = false;
        updateVerification.value = false;
      }
    },
    { deep: true },
);


const calculateCellValue = (data: any, field: any) => {

  if(field.type === 'Object') {
    if(data[field.name]) {
      if(data[field.name].description) {
        return data[field.name].description;
      }
      return '';
    }
    return '';
  }
  return data[field.name];
}

const selectCustomer = (d: any) => {
  store
      .dispatch("GRPOPERS/fetchClienti", {
        dbKey: d.cliente_dbKey,
        RagioneSociale: null,
        Cliente: null,
        Indirizzo: null,
        Cap: null,
        Comune: null,
        Provincia: null,
        CodiceFiscale: null,
        PartitaIva: null,
        Qualifica: null,
        MacroStato: null,
        Agente: null,
        CategoriaSconto: null,
        StatoCliente: null,
      })
      .then((res) => {
        let data = res.data.apiResult[0]

        if(res.data.apiResult.length) {
          store.commit("user/setClienteAbbinato", {
            Cliente_dbKey: "",
            ClienteAbbinato: "",
            PostelTramite: "",
            Cliente: "",
            Gdpr: 0,
            conto_dbKey: ''
          });
          store.commit("GRPOPERS/setClientiDropdown", []);
          store.commit("menu/setMainMenu", []);

          store
              .dispatch("menu/fetchMenu", {
                Cliente_dbKey: data.__dbKey,
              })
              .then(() => {
                const altrePosizioniContabili = computed(() =>
                    store.getters["menu/getMainMenu"]
                        .filter((c: any) => c.code === "GRP-PROFILO")[0]
                        .childs.filter((child: any) => child.code === "CHG-POS_CONTABILE"),
                );

                if (altrePosizioniContabili.value) {
                  const form = {
                    RagioneSociale: null,
                    Cliente: null,
                    Indirizzo: null,
                    Cap: null,
                    Comune: null,
                    Provincia: null,
                    CodiceFiscale: data["Conto/Soggetto/CodiceFiscale"],
                    PartitaIva: null,
                    Qualifica: null,
                    MacroStato: null,
                    Agente: null,
                    CategoriaSconto: null,
                    StatoCliente: null,
                  };

                  if (!data["Conto/Soggetto/CodiceFiscale"]) {
                    store.commit("GRPOPERS/setClientiDropdown", []);
                  } else {
                    store
                        .dispatch("GRPOPERS/fetchClientiDropDown", form)
                        .then()
                        .catch((e) => {
                          console.log(e)
                        });
                  }
                }
              })
              .catch((e) => {
                console.log(e)
              });

          store.commit("user/setClienteAbbinato", {
            Cliente_dbKey: data.__dbKey,
            ClienteAbbinato: data.RagioneSociale,
            PostelTramite: data.PostelTramite,
            Cliente: data.Cliente,
            Gdpr: 0,
            conto_dbKey: data.conto_dbKey
          });

          router.push("/dashboard");
        }
      })
      .catch((e) => {
        console.log(e)
      });
};

const totalFields = computed(() => fields.sort(compareData).filter(field =>
      field.visibleTable &&
      (metadataFields.value[field.name] ||
          (!metadataFields.value[field.name] && !field.metadataField))
    ))
</script>
<template>
  <template v-for="(data, index) in items" :key="index" v-if="props.loaded">
      <div
          class="shadow-lg rounded-sm border px-5 py-4 bg-white border-slate-200 cursor-pointer"
          @click="openBasicModal(true);assignItem(data)"
      >
        <div
            class="md:flex justify-between items-center space-y-4 md:space-y-0 space-x-2"
        >
          <!-- Left side -->
          <div class="flex items-center space-x-3 md:space-x-4">
            <div class="w-9 h-9 rounded-full shrink-0 text-center" :class="listItemColors(index)">
              <div class="flex h-full">
                <div class="text-bold text-white text-xl m-auto">
                  {{ calculateCellValue(data, totalFields[2]) ? calculateCellValue(data, totalFields[2]).trim().substring(0, 1).toUpperCase() : calculateCellValue(data, totalFields[0]) ? calculateCellValue(data, totalFields[0]).trim().substring(0, 1).toUpperCase() : '' }}
                </div>
              </div>
            </div>
            <div>
              <span class="font-semibold text-slate-800">{{ calculateCellValue(data, totalFields[0]) }}</span>
              <div class="text-sm">{{ calculateCellValue(data, totalFields[3]) }} {{ calculateCellValue(data, totalFields[1]) }} {{ calculateCellValue(data, totalFields[2]) }} <template v-if="calculateCellValue(data, totalFields[4])"> - <span class="text-sm text-slate-800 italic whitespace-nowrap">{{ calculateCellValue(data, totalFields[4]) }}</span></template></div>
              <div class="text-sm text-slate-800 italic whitespace-nowrap">
                {{ calculateCellValue(data, totalFields[5]) }}
              </div>
            </div>
          </div>
          <!-- Right side
          <div class="flex items-center space-x-4 space-y-1 pl-10 md:pl-0 text-right">
            <div>
              <div class="text-sm text-slate-800 italic whitespace-nowrap">

              </div>
            </div>
            <div class="text-sm text-slate-500 italic whitespace-nowrap">
              <ActionsTableNoteCredito
                  v-if="props.type === 'noteCredito'"
                  :version="user.Release"
                  component="ActionsTableNoteCredito"
                  folder="ActionsTable"
                  :data="data"
                  :tableInfo="tableInfo"
                  @assignItem="assignItem"
                  @openBasicModal="openBasicModal"
              />
              <ActionsTablePagamentoFatture
                  v-if="props.type === 'pagamentoFatture'"
                  :version="user.Release"
                  component="ActionsTablePagamentoFatture"
                  folder="ActionsTable"
                  :data="data"
                  :tableInfo="tableInfo"
                  @assignItem="assignItem"
                  @openBasicModal="openBasicModal"
              />
              <ActionsTableRichiediNoteCredito
                  v-if="props.type === 'richiediNc'"
                  :version="user.Release"
                  component="ActionsTableRichiediNoteCredito"
                  folder="ActionsTable"
                  :data="data"
                  :tableInfo="tableInfo"
                  @assignItem="assignItem"
                  @openBasicModal="openBasicModal"
              />
              <ActionsTableTickets
                  v-if="props.type === 'tickets'"
                  :version="user.Release"
                  component="ActionsTableTickets"
                  folder="ActionsTable"
                  :data="data"
                  :tableInfo="tableInfo"
                  @assignItem="assignItem"
                  @openBasicModal="openBasicModal"
              />
              <ActionsTablePrezziSconti
                  v-if="props.type === 'prezziSconti'"
                  :version="user.Release"
                  component="ActionsTablePrezziSconti"
                  folder="ActionsTable"
                  :data="data"
                  :tableInfo="tableInfo"
                  @assignItem="assignItem"
                  @openBasicModal="openBasicModal"
              />
            </div>
          </div>
           -->
        </div>
      </div>
  </template>
  <template v-if="props.type === 'contatti'">
    <ModalContatti
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalContatti"
        folder="Contatti"
        :metadataFields="metadataFields"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :updateRec="updateRecapiti"
        :tableInfo="tableInfo"
        @setPredef="setPredef"
        @upRec="upRec"
        @closeModal="openBasicModal"
        @editContatto="editContatto"
    />
    <ModalFormContatti
        v-if="openEditModalValue"
        :version="user.Release"
        component="ModalFormContatti"
        folder="Contatti"
        :openEditModal="openEditModalValue"
        :item="item"
        @closeModal="openEditModal"
        @save="openContatto"
    />
  </template>
  <template v-if="props.type === 'noteCredito'">
    <ModalNoteCredito
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalNoteCredito"
        folder="NoteCredito"
        :metadataFields="metadataFields"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :tableInfo="tableInfo"
        :updateVerification="updateVerification"
        @closeModal="openBasicModal"
        @verification="upVerification"
    />
  </template>
  <template v-if="props.type === 'pagamentoFatture'">
    <ModalPagamentoFatture
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalPagamentoFatture"
        folder="PagamentoFatture"
        :metadataFields="metadataFields"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :tableInfo="tableInfo"
        :updateNote="updateNote"
        :updateVerification="updateVerification"
        @closeModal="openBasicModal"
        @verification="upVerification"
        @upNote="upNote"
        @upCategory="upCategory"
    />
  </template>
  <template v-if="props.type === 'richiediNc'">
    <ModalRichiediNoteCredito
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalRichiediNoteCredito"
        folder="NoteCredito"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :tableInfo="tableInfo"
        @closeModal="openBasicModal"
        @reloadData="refreshDataNc"
    />
  </template>
  <template v-if="props.type === 'tickets'">
    <ModalTickets
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalTickets"
        folder="Ticket"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :tableInfo="tableInfo"
        @closeModal="openBasicModal"
        @editTicket="editTicket"
        @deleteTicket = "deleteTicket"
    />
    <ModalFormTicket
        v-if="openEditModalValue"
        :version="user.Release"
        component="ModalFormTicket"
        folder="Ticket"
        :openEditModal="openEditModalValue"
        :item="item"
        @closeModal="openEditModal"
        @save="openTicket"
    />
  </template>
  <template v-if="props.type === 'prezziSconti'">
    <ModalPrezziSconti
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalPrezziSconti"
        folder="PrezziSconti"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :tableInfo="tableInfo"
        @closeModal="openBasicModal"
        @verification="upVerification"
    />
  </template>
</template>