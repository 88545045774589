<script setup>
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

const props = defineProps(["detail"]);
</script>
<template>
  <DxDataGrid
    id="gridContainer"
    :data-source="props.detail.data.Imballi"
    :column-auto-width="true"
    :show-borders="true"
    :column-hiding-enabled="false"
    noDataText="Nessun risultato"
    :row-alternation-enabled="true"
  >
    <DxSearchPanel :visible="false" placeholder="Cerca..." />
    <DxLoadPanel :enabled="false" />
    <DxScrolling mode="infinite" />
    <DxSorting mode="single" />
    <DxPaging :enabled="false" />
    <DxColumnFixing :enabled="false" />
    <DxColumn
      header-cell-template="title-header"
      data-field="descrizione"
      caption="PRODOTTO"
      width="300"
    />
    <DxColumn
      header-cell-template="title-header"
      data-field="QtaFat"
      alignment="right"
      width="200"
      caption="QUANTIT&Agrave; FAT."
    />
    <DxColumn
      header-cell-template="title-header"
      data-field="Importo"
      alignment="right"
      width="200"
      caption="IMPORTO"
      cell-template="prezzo-template"
    />
    <DxColumn header-cell-template="title-header" data-field="" caption="" />
    <template #prezzo-template="{ data }">
      <span>{{
        new Intl.NumberFormat("it-IT", { minimumFractionDigits: 2 }).format(
          data.value,
        )
      }}</span>
    </template>
    <template #title-header="{ data }">
      <p
        style="font-size: 0.9rem; color: #536de6; font-weight: 700"
        v-html="data.column.caption"
      ></p>
    </template>
  </DxDataGrid>
</template>
