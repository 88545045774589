<script setup>
import { computed, ref } from "vue";
import DeleteButton from "../../../components/actions/DeleteButton.vue";
import SlidesTable from "../../../components/amm/slides/SlidesTable.vue";
import SearchForm from "../../../components/SearchForm.vue";
import ModalBlank from "../../../components/ModalBlank.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
const store = useStore();

const selectedItems = ref([]);
const loading = ref(true);
const removeItem = ref(false);
const errorSys = ref(false);

const updateSelectedItems = (selected) => {
  selectedItems.value = selected;
};

const updateLoaded = () => {
  loading.value = false;
};

const updateRemoveItem = () => {
  removeItem.value = true;

  setTimeout(() => {
    removeItem.value = false;
  }, 4000);
};

const updateErrorSystem = (error) => {
  errorSys.value = error;
};

const search = ref("");

const updateSearchValue = (value) => {
  search.value = value;
};

const deleteModalOpen = ref(false);

const deleteMultipleItems = () => {
  deleteModalOpen.value = true;
};

const actionDeleteItems = () => {
  const selectedItemsFiltered = selectedItems.value.map((key, value) => key);

  if (selectedItemsFiltered.length > 0) {
    selectedItemsFiltered.forEach((el) => {
      store
        .dispatch("amm/removeSlide", {
          id: el,
        })
        .then(() => {
          deleteModalOpen.value = false;
        })
        .catch((e) => {
          deleteModalOpen.value = false;
        });
    });
  }
};
const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div v-if="loading">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div>Caricamento in corso...</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8" v-else>
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          Elenco slide
        </h1>
      </div>

      <!-- Right: Actions  -->
      <div
        class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
      >
        <!-- Delete button -->
        <DeleteButton
          :selectedItems="selectedItems"
          :what="
            selectedItems.length === 1
              ? 'slide selezionata'
              : 'slide selezionate'
          "
          @click.stop="deleteMultipleItems"
        />

        <SearchForm @searchInput="updateSearchValue" />
        <!-- Add customer button -->
        <button
          class="btn text-white"
          :class="configVars.primary + ' hover:' + configVars.primaryHover"
        >
          <svg
            class="w-4 h-4 fill-current opacity-50 shrink-0"
            viewBox="0 0 16 16"
          >
            <path
              d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
            />
          </svg>
          <router-link
            :to="'/AMMINISTRAZIONE/GEST-SLIDE/add'"
            class="hidden xs:block ml-2"
            >Aggiungi</router-link
          >
        </button>
      </div>
    </div>
    <div class="mb-5" v-if="removeItem">
      <div
        class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
              ></path>
            </svg>
            <div>Slide eliminata con successo</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <SlidesTable
      :searchInput="search"
      @change-selection="updateSelectedItems($event)"
      @loaded="updateLoaded"
      @removeItem="updateRemoveItem"
      @errorSystem="updateErrorSystem"
    />

    <ModalBlank
      id="danger-modal"
      :modalOpen="deleteModalOpen"
      @close-modal="deleteModalOpen = false"
    >
      <div class="p-5 flex space-x-4">
        <!-- Icon -->
        <div
          class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100"
        >
          <svg
            class="w-4 h-4 shrink-0 fill-current text-rose-500"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
            />
          </svg>
        </div>
        <!-- Content -->
        <div>
          <!-- Modal header -->
          <div class="mb-2">
            <div class="text-lg font-semibold text-slate-800">
              Eliminare tutti i record selezionati?
            </div>
          </div>
          <!-- Modal content -->
          <div class="text-sm mb-10">
            <div class="space-y-2">
              <p>L'operazione è irreversibile</p>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex flex-wrap justify-end space-x-2">
            <button
              class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
              @click.stop="deleteModalOpen = false"
            >
              Non eliminare
            </button>
            <button
              class="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
              @click="actionDeleteItems()"
            >
              Sì, elimina
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  </div>
</template>
