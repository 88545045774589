<script setup lang="ts">
import { parseError } from "../../composables/parseError";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
const { errorSys, calcError } = parseError();
const store = useStore();
const loading = ref(true);
const loaded = ref(false);
import Table from "../../components/dom/Table.vue";
import { data } from "../../composables/api/data";
const {
  exportedData,
  exportedMetaDataFields,
  exportedTableInfo,
  elaborateMetaDataFields,
  elaborateData,
  elaborateTableInfo,
  elaborateDataGroupBy
} = data();
const props = defineProps(["title"]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);

const loadData = () => {
  store
    .dispatch("generic/beRetrieve", {
      beName: "PrezziSconti",
      beView: "prezziScontiLista",
      includeMetadata: "view",
      solveDynamicMetadata: false,
    })
    .then((res) => {
      elaborateData(res.data.apiResult.data);

      if (res.data.apiResult.data && res.data.apiResult.metadata) {
        if (res.data.apiResult.metadata.fields) {
          elaborateMetaDataFields(res.data.apiResult.metadata.fields);
          elaborateTableInfo(res.data.apiResult.metadata.tableInfo);
          loaded.value = true;
        }
      }

      loading.value = false;
    })
    .catch((e) => {
      calcError(e);
    });
};

const uniqueArray = computed(() =>
    [...new Map(exportedData.value.map((item: any) =>
        [item.tipologia['value'], item])).values()]
)

const filterValue = ref('')

const filterBy = (value: any) => {
  if(!filterValue.value) {
    return true
  }

  if(value.tipologia.value.indexOf(filterValue.value) > -1) {
    return true;
  }

  return false
}

const items = computed(() => exportedData.value.filter(filterBy))

onMounted(() => {
  loadData();
});
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8">
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ props.title }}
        </h1>
      </div>
    </div>
    <div v-if="loading">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="flex p-6 bg-white mb-5"
        v-if="uniqueArray.length"
    >
      <div class="flex items-center">
        <div>
          <h4 class="text-base font-semibold">Filtra per:</h4>
        </div>
        <template v-for="b in uniqueArray">
          <div
          >
            <button
                class="btn text-white ml-4"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
                @click="filterValue = b.tipologia.value"
            >
              {{ b.tipologia.description }}
            </button>
          </div>
        </template>
        <div
        >
          <button
              class="btn text-white ml-4 bg-yellow-500"
              @click="filterValue = ''"
          >
            Resetta il filtro
          </button>
        </div>
      </div>
    </div>
    <Table
      :items="items"
      :metadataFields="exportedMetaDataFields"
      :tableInfo="exportedTableInfo"
      type="prezziSconti"
      :loaded="loaded"
      @refreshData="loadData"
      @upVerification="loadData"
    />
  </div>
</template>
