<script setup>
import { computed, onMounted, ref, watch } from "vue";
import Slides from "./SlidesTableItem.vue";
import ModalBlank from "../../ModalBlank.vue";
import { useVirtualList } from "@vueuse/core";

import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();

const props = defineProps(["selectedItems", "searchInput", "filters"]);
const emit = defineEmits([
  "change-selection",
  "loaded",
  "removeItem",
  "errorSystem",
]);

const selectAll = ref(false);
const selected = ref([]);

const checkAll = () => {
  selected.value = [];
  if (!selectAll.value) {
    selected.value = items.value.map((user) => user.id);
  }
};

watch(selected, () => {
  selectAll.value = items.value.length === selected.value.length ? true : false;
  emit("change-selection", selected.value);
});

const loaded = ref(false);
onMounted(
  store.dispatch("amm/fetchSlides").then(() => {
    emit("loaded", true);
    loaded.value = true;
  }),
);

const filterGen = (value) => {
  if (props.searchInput.length === 0) {
    return true;
  } else {
    const rules = [];

    if (props.searchInput.length !== 0) {
      rules.push(
        value.title.toLowerCase().indexOf(props.searchInput.toLowerCase()) >
          -1 ||
          value.description
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) > -1,
      );
    }

    if (rules.filter((rule) => rule === true).length === rules.length) {
      return true;
    } else {
      return false;
    }
  }
};

const items = computed(() => store.getters["amm/getSlides"].filter(filterGen));

const { list, containerProps, wrapperProps } = useVirtualList(items, {
  itemHeight: 67,
  overscan: 10,
});

const dangerModalOpen = ref(false);
const itemToDelete = ref({});

const delItem = (item) => {
  itemToDelete.value = item;
  dangerModalOpen.value = true;
};

const actionDeleteItem = (itemId) => {
  store
    .dispatch("amm/removeSlide", {
      id: itemId,
    })
    .then(() => {
      dangerModalOpen.value = false;
      emit("removeItem", true);
    })
    .catch((e) => {
      dangerModalOpen.value = false;
      emit("errorSystem", e.response.data.message);
    });
};
</script>
<template>
  <div
    class="bg-white shadow-lg rounded-sm border border-slate-200 relative"
    v-if="loaded"
  >
    <header class="px-5 py-4">
      <h2 class="font-semibold text-slate-800">
        Totale
        <span class="text-slate-400 font-medium">{{ items.length }}</span>
      </h2>
    </header>
    <div>
      <!-- Table -->
      <div class="overflow-x-auto">
        <div class="w-full">
          <!-- Table header -->
          <div
            class="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200"
          >
            <div class="flex">
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-12"
              >
                <div class="flex items-center">
                  <label class="inline-flex">
                    <span class="sr-only">Seleziona tutti</span>
                    <input
                      class="form-checkbox"
                      type="checkbox"
                      v-model="selectAll"
                      @click="checkAll"
                    />
                  </label>
                </div>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-14"
              >
                <span class="font-semibold text-left">ID</span>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/3"
              >
                <div class="font-semibold text-left">Titolo</div>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/3"
              >
                <div class="font-semibold text-left">Descrizione</div>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/3"
              >
                <div class="font-semibold text-left">Immagine</div>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/3"
              >
                <div class="font-semibold text-left">Inizio</div>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/3"
              >
                <div class="font-semibold text-left">Fine</div>
              </div>
              <div
                class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-28"
              >
                <div class="font-semibold text-left">Azioni</div>
              </div>
            </div>
          </div>
          <!-- Table body -->
          <div v-bind="containerProps" class="md:min-height-max max-h-screen">
            <div v-bind="wrapperProps" class="mt-1 text-sm divide-y">
              <Slides
                v-for="{ index, data } in list"
                :key="index"
                :item="data"
                :key-item="data.id"
                v-model:selected="selected"
                @deleteItem="delItem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalBlank
      id="danger-modal"
      :modalOpen="dangerModalOpen"
      @close-modal="dangerModalOpen = false"
    >
      <div class="p-5 flex space-x-4">
        <!-- Icon -->
        <div
          class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100"
        >
          <svg
            class="w-4 h-4 shrink-0 fill-current text-rose-500"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
            />
          </svg>
        </div>
        <!-- Content -->
        <div>
          <!-- Modal header -->
          <div class="mb-2">
            <div class="text-lg font-semibold text-slate-800">
              Eliminare {{ itemToDelete.title }}?
            </div>
          </div>
          <!-- Modal content -->
          <div class="text-sm mb-10">
            <div class="space-y-2">
              <p>L'operazione è irreversibile</p>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex flex-wrap justify-end space-x-2">
            <button
              class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
              @click.stop="dangerModalOpen = false"
            >
              Non eliminare
            </button>
            <button
              class="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
              @click="actionDeleteItem(itemToDelete.id)"
            >
              Sì, elimina
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  </div>
</template>
<style>
.wrap > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
</style>
