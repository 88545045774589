<script setup>
import { computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { email, helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "lodash";
const emit = defineEmits(["updating", "errorUpdating"]);

const store = useStore();
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const user = computed(() => store.getters["user/getUser"]);

const form = reactive({
  EMail: _.cloneDeep(user.value.EmailWeb),
  dbKey: user.value.Web_dbKey,
});

const rules = {
  EMail: {
    required: helpers.withMessage("Campo obbligatorio", required),
    email: helpers.withMessage("Formato email non valido", email),
  },
  dbKey: {},
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const upEmail = () => {
  store
    .dispatch("GRPPROFILO/updateEmail", form)
    .then(() => {
      store.commit("user/setEmail", {
        EmailWeb: form.EMail,
      });
      emit("updating", true);
    })
    .catch((e) => {
      emit("errorUpdating", e);
    });
};
</script>
<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 font-bold mb-5">
        Modifica la tua email del sito
      </h2>
      <section>
        <h3 class="text-xl leading-snug text-slate-800 font-bold mb-1">
          Email
        </h3>
        <div class="flex flex-wrap mt-5">
          <div class="mr-2">
            <label class="sr-only" for="email">Email</label>
            <input
              id="email"
              class="form-input"
              type="email"
              v-model="vv.EMail.$model"
            />
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vv.EMail.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <button
            class="btn text-white ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
            @click="upEmail"
            :disabled="vv.$invalid"
            :class="configVars.primary + ' hover:' + configVars.primaryHover"
          >
            Modifica
          </button>
        </div>
      </section>
    </div>
  </div>
</template>
