<script setup>
import { ref, reactive, toRefs, computed, toRef } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, sameAs } from "@vuelidate/validators";

const store = useStore();

const emit = defineEmits(["save"]);
const user = computed(() => store.getters["user/getUser"]);

const form = reactive({
  username: user.value.username,
  newpwd: "",
  oldpwd: "",
  passwordConfirm: "",
});

const rules = {
  username: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  newpwd: {
    minLengthValue: helpers.withMessage(
      "Lunghezza minima 6 caratteri",
      minLength(6),
    ),
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  oldpwd: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  passwordConfirm: {
    minLengthValue: helpers.withMessage(
      "Lunghezza minima 6 caratteri",
      minLength(6),
    ),
    required: helpers.withMessage("Campo obbligatorio", required),
    sameAsRef: helpers.withMessage(
      'Conferma password deve essere uguale a "Nuova password"',
      sameAs(toRef(form, "newpwd")),
    ),
  },
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const onSubmit = () => {
  vv.value.$touch();

  if (vv.value.$invalid) return;
  emit("save", form);
};

const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <form @submit.prevent="onSubmit" novalidate>
    <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="mb-8">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          Inserire una nuova password
        </h1>
      </div>
      <div class="bg-white shadow-lg rounded-sm mb-8">
        <div class="p-6 space-y-8 mt-8">
          <!-- Input Types -->
          <div>
            <h2 class="text-2xl text-slate-800 font-bold mb-6">
              Compila il form
            </h2>
            <div class="grid gap-5 md:grid-cols-1">
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="username"
                    >Username <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="username"
                    v-model="vv.username.$model"
                    class="form-input w-full"
                    type="text"
                    readonly
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.username.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="oldpwd"
                    >Password attuale
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="oldpwd"
                    v-model="vv.oldpwd.$model"
                    class="form-input w-full"
                    type="password"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.oldpwd.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="newpwd"
                    >Nuova password <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="newpwd"
                    v-model="vv.newpwd.$model"
                    class="form-input w-full"
                    type="password"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.newpwd.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="passwordConfirm"
                    >Conferma password
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="passwordConfirm"
                    v-model="vv.passwordConfirm.$model"
                    class="form-input w-full"
                    type="password"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.passwordConfirm.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <footer>
              <div class="flex flex-col py-5 border-t border-slate-200 mt-6">
                <div class="flex self-end">
                  <button
                    class="btn text-white ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                    :disabled="vv.$invalid"
                    :class="
                      configVars.primary + ' hover:' + configVars.primaryHover
                    "
                    type="submit"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
