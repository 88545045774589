<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import FormSlide from "../../../components/amm/slides/FormSlide.vue";

const router = useRouter();
const store = useStore();
const itemAdded = ref(false);
const errorSys = ref(false);
const loading = ref(false);

const addItem = (postFromForm) => {
  store
    .dispatch("amm/addSlide", postFromForm)
    .then(() => {
      itemAdded.value = true;
      setTimeout(() => {
        router.push("/AMMINISTRAZIONE/GEST-SLIDE");
      }, 3000);
    })
    .catch((e) => {
      loading.value = false;
      errorSys.value = e.response.data.message;
    });
};
const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<template>
  <div
    class="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto"
    v-if="loading"
  >
    <div
      class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
      :class="configVars.secondaryText + ' ' + configVars.primary"
    >
      <div class="flex w-full justify-between items-start">
        <div class="flex">
          <svg
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
            ></path>
          </svg>
          <div class="loading_course">Caricamento in corso</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto"
    v-if="itemAdded"
  >
    <div
      class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
    >
      <div class="flex w-full justify-between items-start">
        <div class="flex">
          <svg
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
            ></path>
          </svg>
          <div>Slide aggiunta con successo</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto"
    v-if="errorSys"
  >
    <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
      <div class="flex w-full justify-between items-start">
        <div class="flex">
          <svg
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
            ></path>
          </svg>
          <div class="font-medium">{{ errorSys }}</div>
        </div>
      </div>
    </div>
  </div>
  <FormSlide v-if="!itemAdded" @save="addItem"></FormSlide>
</template>
