<script>
import { defineAsyncComponent, h } from "vue";

export default {
  props: {
    folder: {
      type: String,
    },
    component: {
      type: String,
    },
    version: {
      type: String,
      default: import.meta.env.VITE_APP_DEFAULT_VERSION,
    },
  },

  setup(props) {
    const versionComponent = (version, component, folder) =>
      defineAsyncComponent(() => {
        if(folder) {
          return import(`../${version}/${folder}/${component}.vue`).catch(
              () =>
                  import(
                      `../${import.meta.env.VITE_APP_DEFAULT_VERSION}/${folder}/${component}.vue`
                      ),
          );
        } else {
          return import(`../${version}/${component}.vue`).catch(
              () =>
                  import(
                      `../${import.meta.env.VITE_APP_DEFAULT_VERSION}/${component}.vue`
                      ),
          );
        }

      });
    const getComponent = versionComponent(props.version, props.component, props.folder);

    return () => h(getComponent);
  },
};
</script>
