<script setup>
import SettingsSidebar from "../../components/GRP-PROFILO/SettingsSidebar.vue";
import DocumentiOnline from "../../components/GRP-PROFILO/DocumentiOnline.vue";
import { parseError } from "../../composables/parseError";
import { useStore } from "vuex";
import { computed } from "vue";
const store = useStore();
const props = defineProps(["title"]);
const user = computed(() => store.getters["user/getUser"]);
const { errorSys, calcError } = parseError();

import { ref } from "vue";

const updating = ref(false);

const configVars = computed(() => store.getters["menu/getConfigVars"]);

if (user.value.DatiUtenza[0].Cliente_dbKey == "") {
  errorSys.value =
    "Deve essere selezionato un cliente prima di procedere alla ricerca";
}

const upVal = () => {
  updating.value = true;

  setTimeout(() => {
    updating.value = false;
  }, 3000);
};

const erUp = (e) => {
  calcError(e);
};
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-5xl mx-auto">
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5" v-if="updating">
      <div
        class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
              ></path>
            </svg>
            <div>Dato modificato con successo</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="mb-8" v-if="!errorSys">
      <!-- Title -->
      <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
        {{ props.title }}
      </h1>
    </div>

    <!-- Content -->
    <div class="bg-white shadow-lg rounded-sm mb-8" v-if="!errorSys">
      <div class="flex flex-col md:flex-row md:-mr-px">
        <SettingsSidebar />
        <DocumentiOnline @updating="upVal" @errorUpdating="erUp" />
      </div>
    </div>
  </div>
</template>
