import GenericModel from "./generic";

export function TicketsModelRules() {
  const fields = [
    new GenericModel(
      "__dbKey",
      "__dbKey",
      false,
      false,
      false,
      "String",
      false,
      99,
    ),
    new GenericModel(
        "soggetto",
        "soggetto",
        false,
        false,
        false,
        "String",
        false,
        99,
    ),
    new GenericModel(
      "__recordDescription",
      "__recordDescription",
      false,
      false,
      false,
      "String",
      false,
      99,
    ),
    new GenericModel(
        "cliente_dbKey",
        "cliente_dbKey",
        false,
        false,
        false,
        "String",
        false,
        99,
    ),
    new GenericModel(
      "idTicket",
      "idTicket",
      false,
      false,
      false,
      "String",
      false,
      99,
    ),
    new GenericModel(
      "data",
      "Data",
      true,
      true,
      false,
      "Date",
      "date-hour-start",
      1,
    ),
    new GenericModel("ora", "Ora", false, false, false, "String", false, 2),
    new GenericModel(
      "priorita",
      "Priorità",
      true,
      true,
      false,
      "Object",
      "object-template",
      3,
    ),
    new GenericModel(
      "allegato",
      "Allegato",
      false,
      true,
      false,
      "File",
      "blob",
      4,
    ),
    new GenericModel(
      "descrizione",
      "Descrizione",
      true,
      true,
      false,
      "String",
      false,
      5,
    ),
    new GenericModel(
      "soggettoDenominazioneSoggetto",
      "Denominazione",
      true,
      true,
      false,
      "String",
      false,
      6,
    ),
    new GenericModel(
      "nomeContatto",
      "Nome del contatto",
      true,
      true,
      false,
      "String",
      "name-surname",
      7,
    ),
    new GenericModel(
      "cognomeContatto",
      "Cognome del contatto",
      false,
      false,
      false,
      "String",
      false,
      8,
    ),
    new GenericModel(
      "telefono",
      "Telefono del contatto",
      false,
      true,
      false,
      "String",
      false,
      9,
    ),
    new GenericModel(
      "email",
      "Email del contatto",
      false,
      true,
      false,
      "String",
      false,
      10,
    ),
    new GenericModel(
      "entroIl",
      "Entro il",
      true,
      true,
      false,
      "Date",
      false,
      11,
    ),
    new GenericModel(
      "dataUltimaAttivita",
      "Data ultima attività",
      true,
      true,
      false,
      "Date",
      false,
      12,
    ),
    new GenericModel(
        "operatoreGruppo",
        "Operatore/Gruppo",
        true,
        true,
        false,
        "Object",
        "object-template",
        13,
    ),
    new GenericModel(
      "operatoreNome",
      "Operatore",
      true,
      true,
      false,
      "String",
      false,
      14,
    ),
    new GenericModel(
      "tipoTicketDescrizione",
      "Descrizione del tipo ticket",
      true,
      true,
      false,
      "String",
      false,
      15,
    ),
    new GenericModel(
      "impiegato",
      "Tempo impiegato",
      true,
      true,
      false,
      "String",
      "second-to-hms",
      16,
    ),
    new GenericModel(
        "riservato",
        "Riservato",
        false,
        true,
        false,
        "Boolean",
        false,
        17,
    ),
    new GenericModel("ditta", "Ditta", true, true, false, "Object", "object-template", 18),
    new GenericModel(
        "stato",
        "Stato",
        true,
        true,
        false,
        "Object",
        "object-template",
        19,
    ),
    new GenericModel(
        "esito",
        "Esito",
        true,
        true,
        false,
        "Object",
        "object-template",
        20,
    ),
  ];

  return {
    fields,
  };
}
