<script setup>
import { computed, ref } from "vue";
import UserMenu from "../components/DropdownProfile.vue";
import DropdownSwitch from "../components/DropdownSwitch.vue";
import { useStore } from "vuex";
import ModalBasic from "../components/ModalBasic.vue";
import { parseError } from "../composables/parseError";
import { SituazioneClienteModelRules } from "../models/situazioneCliente";
import { colors } from "../composables/colors"
const {semaforoInterno, semaforoCribis, statoCliente} = colors()

import { data } from "../composables/api/data";
import { compare } from "../composables/compare";
defineProps(["sidebarOpen"]);
import fieldCheck from "../composables/api/fieldCheck";
const loaded = ref(false);
const searchModalOpen = ref(false);
const store = useStore();
const user = computed(() => store.getters["user/getUser"]);
const dropdownCustomer = computed(
  () => store.getters["GRPOPERS/getClientiDropdown"],
);
const basicModalOpen = ref(false);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const { errorSys, calcError } = parseError();
const { fields } = SituazioneClienteModelRules();
const {
  exportedData: item,
  exportedMetaDataFields: metadataFields,
  exportedTableInfo,
  elaborateMetaDataFields,
  elaborateData,
  elaborateTableInfo,
} = data();
const { compareData } = compare();

const loadSitCliente = () => {
  if (user.value.DatiUtenza[0].Cliente_dbKey != "") {
    store
      .dispatch("generic/beRetrieve", {
        beName: "SituazioneCliente",
        beView: "situazioneClienteDettaglio",
        includeMetadata: "view",
        solveDynamicMetadata: true,
        filter:
          "{cliente_dbKey} == '" + user.value.DatiUtenza[0].Cliente_dbKey + "'",
      })
      .then((res) => {
        elaborateData(res.data.apiResult.data[0]);

        if (res.data.apiResult.metadata) {
          if (res.data.apiResult.metadata.fields) {
            elaborateMetaDataFields(res.data.apiResult.metadata.fields);
            elaborateTableInfo(res.data.apiResult.metadata.tableInfo);
            loaded.value = true;
          }
        }
      })
      .catch((e) => {
        calcError(e);
      });
  }
};

const paramsPosContabile = computed(() => {
  if (store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-OPERS")) {
    if (
      store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-OPERS")[0]
    ) {
      return store.getters["menu/getMainMenu"]
        .filter((c) => c.code === "GRP-OPERS")[0]
        .childs.filter((child) => child.code === "VIS-CLIENTI")[0]
        .buttons.filter((c) => c.code === "CHG-POS_CONTABILE");
    }
  }
  return null;
});

const paramsEsposizioneCliente = computed(() => {
  if (store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-OPERS")) {
    if (
      store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-OPERS")[0]
    ) {
      return store.getters["menu/getMainMenu"]
        .filter((c) => c.code === "GRP-OPERS")[0]
        .childs.filter((child) => child.code === "VIS-CLIENTI")[0]
        .buttons.filter((c) => c.code === "ESPOSIZIONE-CLI");
    }
  }
  return [];
});
</script>
<template>
  <header class="sticky top-0 bg-white border-b border-slate-200 z-30">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16 -mb-px">
        <!-- Header: Left side -->
        <div class="flex">
          <!-- Hamburger button -->
          <button
            class="text-slate-500 hover:text-slate-600 lg:hidden"
            @click.stop="$emit('toggle-sidebar')"
            aria-controls="sidebar"
            :aria-expanded="sidebarOpen"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              class="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="4" y="5" width="16" height="2" />
              <rect x="4" y="11" width="16" height="2" />
              <rect x="4" y="17" width="16" height="2" />
            </svg>
          </button>
        </div>

        <!-- Header: Right side -->
        <div class="flex items-center space-x-3">
          <!-- Divider -->
          <div
            class="relative inline-flex"
            v-if="user.DatiUtenza[0].ClienteAbbinato"
          >
            <div
              class="inline-flex font-medium text-rose-600 text-center mt-1 text-sm"
            >
              <span class="hidden lg:block">Stai visualizzando i dati di </span
              ><b class="ml-2">{{ user.DatiUtenza[0].ClienteAbbinato }}</b>
            </div>
          </div>
          <template v-if="window.location.hostname.includes('prova') || window.location.hostname.includes('test') || window.location.hostname.includes('sviluppo')">
            <div
                class="relative inline-flex"
            >
              <div
                  class="inline-flex font-medium text-rose-800 text-center mt-1 text-xl"
              >
              <b class="ml-2">AMBIENTE DI TEST</b>
              </div>
            </div>
          </template>
          <button
            class="w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full"
            aria-controls="basic-modal"
            @click.stop="
              basicModalOpen = true;
              loadSitCliente();
            "
            v-if="
              user.DatiUtenza[0].Cliente_dbKey != '' && paramsEsposizioneCliente.length
            "
            title="Posizione del cliente"
          >
            <span class="sr-only">Info</span>
            <svg
              class="w-4 h-4"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="fill-current text-slate-500"
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
              />
            </svg>
          </button>
          <template v-if="dropdownCustomer.length > 1 && paramsPosContabile.length">
            <hr class="w-px h-6 bg-slate-200" />
            <DropdownSwitch :data="dropdownCustomer" align="right" />
          </template>
          <hr class="w-px h-6 bg-slate-200" />
          <UserMenu align="right" />
        </div>
      </div>
    </div>
    <ModalBasic
      id="basic-modal"
      :modalOpen="basicModalOpen"
      @close-modal="basicModalOpen = false"
      title="Situazione cliente"
    >
      <!-- Modal content -->
      <div class="px-5 pt-4 pb-1">
        <div v-if="errorSys">
          <div class="py-2 px-4 rounded-sm text-sm text-white bg-rose-500">
            <div class="flex w-full justify-between items-start">
              <div class="flex">
                <svg
                  class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                  ></path>
                </svg>
                <div class="font-medium">{{ errorSys }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loaded && !errorSys">
          <div
            class="py-2 px-4 rounded-sm text-sm border border-indigo-200"
            :class="configVars.secondaryText + ' ' + configVars.primary"
          >
            <div class="flex w-full justify-between items-start">
              <div class="flex">
                <svg
                  class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                  ></path>
                </svg>
                <div class="loading_course">Caricamento in corso</div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2" v-if="loaded && item">
          <!-- Left -->
          <template v-for="field in fields.sort(compareData)">
            <template v-if="field.visibleDetail && metadataFields[field.name]">
              <div
                class="col-start-1 text-sm text-slate-800 font-medium border-b border-slate-200 py-3 flex"
              >
                <div>{{ metadataFields[field.name].label }}</div>
              </div>
            </template>
            <template v-if="field.visibleDetail && metadataFields[field.name]">
              <div
                class="text-sm text-slate-800 ml-4 border-b border-slate-200 py-3"
                :class="field.cellTemplate === 'format-currency' ? 'text-right' : ''"
              >
                <template v-if="field.name === 'clienteSemaforoInterno'">
                  <template v-if="item.clienteRatingInterno">
                    <div v-html="semaforoInterno(item.clienteRatingInterno.value)"></div>
                  </template>
                </template>
                <template v-else-if="field.name === 'clienteSemaforoCribis'">
                  <template v-if="item.soggettoRatingInterno">
                    <div v-html="semaforoCribis(item.soggettoRatingInterno.value)"></div>
                  </template>
                </template>
                <template v-else-if="field.name === 'statoCliente'">
                  <span class="mr-3" :class="statoCliente(item)">{{ fieldCheck(item, field) }}</span>
                </template>
                <template v-else>
                  <span class="mr-3">{{ fieldCheck(item, field) }}</span>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="px-5 py-4">
        <div class="flex flex-wrap justify-end space-x-2">
          <button
            class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
            @click.stop="basicModalOpen = false"
          >
            Chiudi
          </button>
        </div>
      </div>
    </ModalBasic>
  </header>
</template>
