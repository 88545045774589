<script setup>
import _ from "lodash";
import { computed, reactive } from "vue";
import Recapito from "./Recapito.vue";
import { useStore } from "vuex";
import { parseError } from "../../composables/parseError";
const store = useStore();
const emit = defineEmits(["updating", "errorUpdating"]);
const recapiti = reactive(
  _.cloneDeep(
    computed(() => store.getters["GRPPROFILO/getRecapiti"]),
  ).value.map((v) => ({ ...v, mod: false })),
);

recapiti.sort(function (a, b) {
  return a["Descrizione"].localeCompare(b["Descrizione"]);
});

const { errorSys, calcError } = parseError();
const user = computed(() => store.getters["user/getUser"]);

const updateModVal = (data) => {
  const r = recapiti.find((rec) => rec.dbKey === data.dbKey);
  r.mod = data.value;
};

const updateRecapitoValore = (data) => {
  const r = recapiti.find((rec) => rec.dbKey === data.dbKey);
  r.Recapito = data.value;
};

const updateRecapito = (data) => {
  updateModVal({
    dbKey: data.dbKey,
    value: false,
  });
  emit("updating", true);
  updateRecapitoValore({
    dbKey: data.dbKey,
    value: data.value,
  });

  store
    .dispatch("GRPPROFILO/updateRecapito", {
      dbKey: data.dbKey,
      Recapito: data.value,
    })
    .then(() => {
      store
        .dispatch("GRPPROFILO/fetchRecapiti", {
          Cliente_dbKey: user.value.DatiUtenza[0].Cliente_dbKey,
        })
        .then()
        .catch((e) => calcError(e));
    })
    .catch((e) => {
      emit("errorUpdating", e);
    });
};
</script>
<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <!-- Billing Information -->
      <section>
        <h3 class="text-xl leading-snug text-slate-800 font-bold mb-1">
          I tuoi recapiti
        </h3>
        <small>* recapito predefinito</small>
        <ul v-if="recapiti.length">
          <li
            class="md:flex md:justify-between md:items-center py-3 border-b border-slate-200"
            v-for="(rec, key) in recapiti"
            :key="key"
          >
            <Recapito
              :data="rec"
              @modVal="updateModVal"
              @upRec="updateRecapito"
            />
          </li>
        </ul>
        <h4 v-else>Nessun recapito presente</h4>
      </section>
    </div>
  </div>
</template>
