<script setup>
import { useStore } from "vuex";
import { computed, onMounted, watch } from "vue";
const { errorSys, calcError } = parseError();
const store = useStore();
const user = computed(() => store.getters["user/getUser"]);

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

import { parseError } from "../composables/parseError";

const filterSlide = computed(() =>
  user.value.DatiUtenza[0].Cliente_dbKey ? "user" : "oper",
);

const slides = computed(() => store.getters["amm/getSlides"]);

store.dispatch("amm/fetchSlides", !!user.value.DatiUtenza[0].Cliente_dbKey);

const configVars = computed(() => store.getters["menu/getConfigVars"]);

const qualifiche = computed(() => store.getters["GRPOPERS/getQualifiche"]);
const macroStati = computed(() => store.getters["GRPOPERS/getMacroStati"]);
const agenti = computed(() => store.getters["GRPOPERS/getAgenti"]);
const categorieSconto = computed(
  () => store.getters["GRPOPERS/getCategorieSconto"],
);
const statiCliente = computed(() => store.getters["GRPOPERS/getStatiCliente"]);

onMounted(() => {
  if (qualifiche.value.length === 0) {
    store
      .dispatch("GRPOPERS/fetchQualifiche", {
        Codice: "",
        Descrizione: "",
        numRecords: "",
        qua_id: "",
      })
      .then()
      .catch((e) => {
        calcError(e);
      });
  }

  if (macroStati.value.length === 0) {
    store
      .dispatch("GRPOPERS/fetchMacroStati", {
        Codice: "",
        Descrizione: "",
        numRecords: "",
      })
      .then()
      .catch((e) => {
        calcError(e);
      });
  }

  if (agenti.value.length === 0) {
    store
      .dispatch("GRPOPERS/fetchAgenti", {
        age_id: "",
        Agente: "",
        numRecords: "",
        RagioneSociale: "",
      })
      .then()
      .catch((e) => {
        calcError(e);
      });
  }

  if (categorieSconto.value.length === 0) {
    store
      .dispatch("GRPOPERS/fetchCategorieSconto", {
        Codice: "",
        Descri: "",
        numRecords: "",
        IdCatsco: "",
      })
      .then()
      .catch((e) => {
        calcError(e);
      });
  }

  if (statiCliente.value.length === 0) {
    store
      .dispatch("GRPOPERS/fetchStatiCliente", {
        Codice: "",
        Descrizione: "",
        numRecords: "",
      })
      .then()
      .catch((e) => {
        calcError(e);
      });
  }
});

const dropdownCustomer = computed(
  () => store.getters["GRPOPERS/getClientiDropdown"],
);
</script>
<style>
.carousel__prev,
.carousel__next {
  background-color: rgb(22 78 99) !important;
  display: none !important;
}
.carousel__pagination-button {
  background-color: rgb(14 116 144) !important;
}
.carousel__pagination-button--active {
  background-color: rgb(22 78 99) !important;
}

.loading_course:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.ql-indent-1 {

}
</style>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div
      class="relative p-4 sm:p-6 rounded-sm overflow-hidden mb-8"
      :class="configVars.primary"
    >
      <!-- Content -->
      <div class="relative">
        <h1
          class="text-2xl md:text-3xl font-bold mb-1"
          :class="configVars.secondaryText"
        >
          Bentornato {{ user ? user.DatiUtenza[0].Nome : ""
          }}<span v-if="user.DatiUtenza[0].UltimoAccesso"
            >, ultimo accesso
            {{ $filters.formatDate(user.DatiUtenza[0].UltimoAccesso) }}</span
          >
        </h1>
      </div>
    </div>
    <carousel
      :items-to-show="1"
      :autoplay="6000"
      :wrap-around="true"
      v-if="slides.filter((el) => el.dest == filterSlide).length"
    >
      <slide
        v-for="slide in slides.filter((el) => el.dest == filterSlide)"
        :key="slide.id"
      >
        <div class="w-full my-12 flex">
          <div class="flex-1 text-left pr-6">
            <h2 class="text-4xl mb-4" :class="configVars.primaryText">
              {{ slide.title }}
            </h2>
            <p v-html="slide.description"></p>
          </div>
          <div v-if="slide.image" class="flex-1">
            <img :src="slide.image" alt="" class="object-cover" />
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>
