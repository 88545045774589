<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import IconsProfilo from "../IconsProfilo.vue";

const store = useStore();
const user = computed(() => store.getters["user/getUser"]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const childs = computed(
  () =>
    store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-PROFILO")[0]
      .childs,
);
</script>
<template>
  <!--<div class="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 min-w-60 md:space-y-3" v-if="childs">
    <div>
      <ul class="flex flex-nowrap md:block mr-3 md:mr-0">
        <router-link :to="'/' + child.group + '/' + child.code" custom v-slot="{ href, navigate, isExactActive }" v-for="(child, key) in childs" :key="key">
          <li class="mr-0.5 md:mr-0 md:mb-0.5" v-if="!child.autoexecute">
            <a class="flex items-center px-2.5 py-2 rounded whitespace-nowrap" :class="isExactActive &&  configVars.primary" :href="href" @click="navigate">
              <IconsProfilo :code="child.code" />
              <span class="text-sm font-medium" :class="isExactActive ? configVars.secondaryText : 'hover:' + configVars.primaryTextHover">{{ child.description }}</span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </div>-->
</template>
