<script setup>
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { gestCookies } from "../composables/cookies";
const { getCookieFromComposable } = gestCookies();
const store = useStore();

const formOperatore = reactive({
  username: "",
  password: "",
});

const router = useRouter();
const loading = ref(false);
const passwordSent = ref(false);
const errorLicense = ref(false);
const tryLicense = ref(false);
const errorSys = ref(false);

const submitOperatore = () => {
  loading.value = true;
  store
    .dispatch("user/loginOperatore", formOperatore)
    .then(() => {
      store.dispatch("menu/fetchMenu").then(() => {
        loading.value = false;
        router.push("/dashboard");
      });
    })
    .catch((e) => {
      loading.value = false;

      if (e) {
        if (e.response && e.response.data) {
          if (e.response.data.errorCode) {
            console.log(e);
            errorSys.value = e.response.data.errorMessage;
            if (e.response.data.errorCode === "E003") {
              router.push("/change-password?from=loginOperatore");
            }
          }
        }
      }
    });
};

const showInput = ref(false);

const configVars = computed(() => store.getters["menu/getConfigVars"]);
const changeVisPwd = () => {
  showInput.value = !showInput.value;
};

const iconView = ref(
  '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 48 48"><title>view</title><g fill="#000000" class="nc-icon-wrapper"><path d="M46.424,22.162C43.458,17.9,35.336,8,24,8,12.565,8,4.5,17.911,1.566,22.176a3.235,3.235,0,0,0,.009,3.648h0C4.537,30.087,12.65,40,24,40c11.246,0,19.42-9.9,22.414-14.164A3.228,3.228,0,0,0,46.424,22.162ZM24,33a9,9,0,1,1,9-9A9,9,0,0,1,24,33Z" fill="#000000"></path></g></svg>',
);
const iconHide = ref(
  '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 48 48"><title>hide</title><g fill="#000000" class="nc-icon-wrapper"><path d="M38.576,13.667l-6.46,6.459A8.984,8.984,0,0,1,20.128,32.114l-5.556,5.557A20.967,20.967,0,0,0,24,40c11.246,0,19.421-9.9,22.414-14.164a3.228,3.228,0,0,0,.007-3.674A41.736,41.736,0,0,0,38.576,13.667Z" fill="#000000"></path><path d="M1.575,25.825a41.617,41.617,0,0,0,7.849,8.509l6.463-6.463A8.984,8.984,0,0,1,27.873,15.884l5.555-5.554A20.947,20.947,0,0,0,24,8C12.564,8,4.5,17.911,1.565,22.176a3.237,3.237,0,0,0,.009,3.648Z" fill="#000000"></path><path data-color="color-2" d="M4,45a1,1,0,0,1-.707-1.707l40-40a1,1,0,1,1,1.414,1.414l-40,40A1,1,0,0,1,4,45Z"></path></g></svg>',
);

const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

let username = getCookieFromComposable("username");
let password = getCookieFromComposable("password");

if (
  username !== "" &&
  password !== "" &&
  username !== null &&
  password !== null
) {
  store
    .dispatch("user/loginOperatore", {
      username: username,
      password: password,
    })
    .then(() => {
      setCookie("username", "", -1);
      setCookie("password", "", -1);

      store.dispatch("menu/fetchMenu").then(() => {
        router.push("/dashboard");
      });
    })
    .catch((e) => {
      setCookie("username", "", -1);
      setCookie("password", "", -1);

      if (e) {
        if (e.response && e.response.data) {
          errorSys.value = e.response.data.errorMessage;
        }
      }
    });
}

const submitResetPassword = () => {
  if (confirm("Sei sicuro di voler inviare la nuova password?"))
    store
      .dispatch("user/resetPassword", formOperatore)
      .then(() => {
        passwordSent.value = true;
        errorSys.value = false;
      })
      .catch((e) => {
        passwordSent.value = false;
        if (e) {
          if (e.response && e.response.data) {
            errorSys.value = e.response.data.errorMessage;
          }
        }
        calcError(e);
      });
};

onBeforeMount(() => {
  if (!configVars.value) {
    store
      .dispatch("menu/fetchLicense", "https://" + window.location.hostname)
      .then(() => (tryLicense.value = true))
      .catch(() => {
        tryLicense.value = true;
        errorLicense.value = true;
      });
  } else {
    tryLicense.value = true;
  }
});

const checkWord = () => {
  if(window.location.hostname.includes('prova') || window.location.hostname.includes('test') || window.location.hostname.includes('sviluppo')) {
    return '<p class="text-lg font-semibold mb-3">(Ambiente di test)</p>'
  }

  return ''
}
</script>

<template>
  <main class="bg-white" v-if="tryLicense">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-screen h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <div
              class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
            </div>
          </div>

          <div class="max-w-sm mx-auto px-4 py-8">
            <img
              class="object-cover"
              :src="'/data/' + configVars.licenseKey + '/' + configVars.logo"
            />
            <div class="h-px bg-slate-200 mt-8 mb-6 w-full"></div>
            <h1 class="text-3xl text-slate-800 font-bold mb-2">
              Accesso Operatore
            </h1>
            <div v-html="checkWord()" />
            <p
              v-if="configVars.loginText"
              v-html="configVars.loginText"
              class="text-sm mb-4"
            ></p>
            <div
              class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600 mb-4"
              v-if="passwordSent"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                    ></path>
                  </svg>
                  <div>
                    Ti è stata inviata una nuova password, controlla la tua
                    casella di posta
                  </div>
                </div>
              </div>
            </div>
            <div
              class="inline-flex min-w-80 px-4 py-2 rounded-sm text-sm text-white bg-rose-500 mb-4"
              v-if="errorLicense"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                    ></path>
                  </svg>
                  <div class="font-medium">Licenza non attiva</div>
                </div>
              </div>
            </div>
            <div
              class="inline-flex min-w-80 px-4 py-2 rounded-sm text-sm text-white bg-rose-500 mb-4"
              v-if="errorSys"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                    ></path>
                  </svg>
                  <div class="font-medium">{{ errorSys }}</div>
                </div>
              </div>
            </div>
            <div
              class="inline-flex min-w-80 px-4 py-2 rounded-sm text-sm text-white mb-4"
              :class="configVars.primary"
              v-if="loading"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                    ></path>
                  </svg>
                  <div class="font-medium loading_course">
                    Caricamento in corso
                  </div>
                </div>
              </div>
            </div>
            <!-- Form -->
            <form @submit.prevent="submitOperatore" novalidate>
              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium mb-1" for="username"
                    >Username <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="username"
                    v-model="formOperatore.username"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
                <div class="relative">
                  <label class="block text-sm font-medium mb-1" for="password"
                    >Password <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="password"
                    v-model="formOperatore.password"
                    class="form-input w-full pr-9"
                    :type="showInput ? 'text' : 'password'"
                    autoComplete="on"
                  />
                  <button
                    class="absolute right-2 top-9 group"
                    type="button"
                    aria-label="View"
                    @click="changeVisPwd"
                    v-html="showInput ? iconView : iconHide"
                  ></button>
                </div>
              </div>
              <div class="flex items-center justify-between mt-6">
                <div class="mr-1">
                  <a
                    href="javascript:void(0)"
                    class="text-sm underline hover:no-underline"
                    @click="submitResetPassword"
                    >Password dimenticata?</a
                  >
                </div>
                <button
                  type="submit"
                  class="btn text-white"
                  :class="
                    configVars.primary + ' hover:' + configVars.primaryHover
                  "
                >
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          :src="'/data/' + configVars.licenseKey + '/' + configVars.bgImage"
          width="760"
          height="1024"
          alt="Authentication"
        />
      </div>
    </div>
  </main>
</template>
