import axios from "axios"; // Vue router instance

const state: any = {
  serbatoi: [],
  storico: [],
  contatori: [],
  letture: [],
  utenze: [],
  prezziPraticati: [],
  prezziSfuso: [],
  utenzeBombole: [],
  saldiImballi: [],
  prodottiBombole: [],
  prodottiAccessori: [],
  prezziBombole: [],
  ordiniBombole: [],
  storicoBombole: [],
};

const getters: any = {
  getSerbatoi: (state: any) => {
    return state.serbatoi;
  },
  getStorico: (state: any) => {
    return state.storico;
  },
  getContatori: (state: any) => {
    return state.contatori;
  },
  getLetture: (state: any) => {
    return state.letture;
  },
  getUtenze: (state: any) => {
    return state.utenze;
  },
  getPrezziPraticati: (state: any) => {
    return state.prezziPraticati;
  },
  getPrezziSfuso: (state: any) => {
    return state.prezziSfuso;
  },
  getUtenzeBombole: (state: any) => {
    return state.utenzeBombole;
  },
  getSaldiImballi: (state: any) => {
    return state.saldiImballi;
  },
  getProdottiBombole: (state: any) => {
    return state.prodottiBombole;
  },
  getProdottiAccessori: (state: any) => {
    return state.prodottiAccessori;
  },
  getPrezziBombole: (state: any) => {
    return state.prezziBombole;
  },
  getOrdiniBombole: (state: any) => {
    return state.ordiniBombole;
  },
  getStoricoBombole: (state: any) => {
    return state.storicoBombole;
  },
};

const actions: any = {
  async fetchSerbatoi(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Locatari/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setSerbatoi", result.data.apiResult);

    return result;
  },
  async fetchStorico(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/StoricoRifornimenti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setStorico", result.data.apiResult);

    return result;
  },
  async fetchPrezziSfuso(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/StoricoPrezziSfuso/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setPrezziSfuso", result.data.apiResult);

    return result;
  },
  async richiediRifornimento(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/NewOrdineSerbatoio/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async nuovoLivelloSerbatoi(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/NewLivelloSerbatoi/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },

  async fetchContatori(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Contatori/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setContatori", result.data.apiResult);

    return result;
  },
  async fetchLetture(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Letture/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setLetture", result.data.apiResult);

    return result;
  },
  async fetchUtenze(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Utenti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setUtenze", result.data.apiResult);

    return result;
  },
  async fetchPrezziPraticati(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/PrezziPraticati/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setPrezziPraticati", result.data.apiResult);

    return result;
  },
  async nuovaLettura(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/NewLetture/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async fetchUtenzeBombole(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/UtentiBombole/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setUtenzeBombole", result.data.apiResult.Cliente);

    return result;
  },
  async nuovoOrdineBombola(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/NewOrdineBombola/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async fetchSaldiImballi(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/SaldiImballi/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setSaldiImballi", result.data.apiResult);

    return result;
  },
  async fetchProdottiBombole(context: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ProdottiBombole/",
      {},
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setProdottiBombole", result.data.apiResult);

    return result;
  },
  async fetchProdottiAccessori(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ProdottiAccessori/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setProdottiAccessori", result.data.apiResult);

    return result;
  },
  async fetchPrezziBombole(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/DettaglioPrezziBombole/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setPrezziBombole", result.data.apiResult);

    return result;
  },
  async fetchOrdiniBombole(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/OrdiniBombole/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setOrdiniBombole", result.data.apiResult);

    return result;
  },
  async fetchStoricoBombole(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/StoricoBombole/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setStoricoBombole", result.data.apiResult.Documento);

    return result;
  },
};

const mutations: any = {
  setSerbatoi(state: any, serbatoiData: any) {
    state.serbatoi = serbatoiData;
  },
  setStorico(state: any, storicoData: any) {
    state.storico = storicoData;
  },
  setContatori(state: any, contatoriData: any) {
    state.contatori = contatoriData;
  },
  setLetture(state: any, lettureData: any) {
    state.letture = lettureData;
  },
  setUtenze(state: any, utenzeData: any) {
    state.utenze = utenzeData;
  },
  setPrezziPraticati(state: any, prezziData: any) {
    state.prezziPraticati = prezziData;
  },
  setPrezziSfuso(state: any, prezziSfusoData: any) {
    state.prezziSfuso = prezziSfusoData;
  },
  setUtenzeBombole(state: any, utenzeData: any) {
    state.utenzeBombole = utenzeData;
  },
  setSaldiImballi(state: any, saldiImballiData: any) {
    state.saldiImballi = saldiImballiData;
  },
  setProdottiBombole(state: any, prodottiBomboleData: any) {
    state.prodottiBombole = prodottiBomboleData;
  },
  setProdottiAccessori(state: any, prodottiAccessoriData: any) {
    state.prodottiAccessori = prodottiAccessoriData;
  },
  setPrezziBombole(state: any, prezziBomboleData: any) {
    state.prezziBombole = prezziBomboleData;
  },
  setOrdiniBombole(state: any, ordiniBomboleData: any) {
    state.ordiniBombole = ordiniBomboleData;
  },
  setStoricoBombole(state: any, storicoBomboleData: any) {
    state.storicoBombole = storicoBomboleData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
