import axios from "axios"; // Vue router instance

const actions: any = {
  async paymentStep2(context: any, dati: any) {
    const options: any = {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      data: dati.info,
      url: dati.destinationUrl,
    };
    return await axios(options);

    /*return await axios.post(data.destinationUrl, data.info, {
      headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type':  'application/x-www-form-urlencoded',
        //post["Content-Type"] = "text/plain";
      }
    })*/
  },
};

export default {
  namespaced: true,
  actions,
};
