<script setup>
import { computed, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "lodash";
import Multiselect from "@vueform/multiselect";

const emit = defineEmits(["updating", "errorUpdating"]);

const store = useStore();
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const user = computed(() => store.getters["user/getUser"]);

const form = reactive({
  PostelTramite: _.cloneDeep(user.value.DatiUtenza[0].PostelTramite.value),
  dbKey: user.value.DatiUtenza[0].Cliente_dbKey,
});

const rules = {
  PostelTramite: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  dbKey: {},
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const upSpedizione = () => {
  store
    .dispatch("GRPPROFILO/updateSpedizione", form)
    .then(() => {
      store.commit("user/setPostelTramite", {
        PostelTramite: form.PostelTramite,
      });
      emit("updating", true);
    })
    .catch((e) => {
      emit("errorUpdating", e);
    });
};

const valoriTot = ref([
  {
    value: 1,
    label: "Cartaceo",
  },
  {
    value: 2,
    label: "Mail",
  },
]);
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 font-bold mb-5">
        Modalità spedizione documenti
      </h2>
      <section>
        <div class="flex items-center space-y-4 space-y-0 space-x-4 mt-5">
          <div class="w-1/3">
            <label class="block text-sm font-medium mb-1" for="spedizione"
              >Tipologie di spedizione</label
            >
            <Multiselect
              id="spedizione"
              v-model="vv.PostelTramite.$model"
              :searchable="true"
              mode="single"
              :options="valoriTot"
              :filterResults="true"
              :minChars="1"
              :resolveOnLoad="false"
              :canClear="false"
            />
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vv.PostelTramite.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="w-1/3 self-end">
            <button
              class="btn text-white ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="upSpedizione"
              :disabled="vv.$invalid"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
            >
              Modifica
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
