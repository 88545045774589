import axios from "axios"; // Vue router instance

const state: any = {
  slides: [],
  customerData: null,
  files: [],
  filesTypes: [],
};

const getters: any = {
  getSlides: (state: any) => {
    return state.slides;
  },
  getCustomerData: (state: any) => {
    return state.customerData;
  },
  getFiles: (state: any) => {
    return state.files;
  },
  getFilesTypes: (state: any) => {
    return state.filesTypes;
  },
};

const actions: any = {
  async fetchSlides(context: any, isUser: boolean = false) {
    const result: any = await axios.get(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/SlidesList.php",
      !isUser
        ? {}
        : {
            headers: {
              ONLYACTIVE: 1,
            },
          },
    );

    await context.commit("setSlides", result.data.slides);

    return result;
  },
  async fetchSlide(context: any, itemId: any) {
    return await axios.get(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/SlideDetail.php",
      {
        headers: {
          ID: itemId,
        },
      },
    );
  },
  async addSlide(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/AddSlide.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );
  },
  async editSlide(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/EditSlide.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );
  },
  async removeSlide(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/DeleteSlide.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );

    await context.commit("delSlide", formData.id);

    return result;
  },
  async fetchCustomerData(context: any) {
    const result: any = await axios.get(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/Detail.php",
    );

    await context.commit("setCustomerData", result.data.customer);

    return result;
  },
  async editLoginSignupText(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/EditCustomerTexts.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );
  },
  async downloadBlobFile(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/resources/downloadBlob/",
      {
        ref: formData,
      },
      {
        responseType: "blob",
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async downloadFile(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/resources/download/",
      {
        resourceId: formData,
      },
      {
        responseType: "blob",
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async downloadFileWithoutToken(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/resources/download/",
      {
        resourceId: formData.resourceId,
      },
      {
        responseType: "blob",
        headers: {
          apiSessionToken: formData.apiSessionToken,
        },
      },
    );
  },
  async fetchFiles(context: any) {
    const result: any = await axios.get(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/FilesList.php",
    );

    await context.commit("setFiles", result.data.files);

    return result;
  },
  async fetchFile(context: any, itemId: any) {
    return await axios.get(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/FileDetail.php",
      {
        headers: {
          ID: itemId,
        },
      },
    );
  },
  async addFile(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/AddFile.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );
  },
  async editFile(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/EditFile.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );
  },
  async removeFile(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/DeleteFile.php",
      `dataValue=${encodeURIComponent(JSON.stringify(formData))}`,
    );

    await context.commit("delFile", formData.id);

    return result;
  },
  async fetchFilesTypes(context: any) {
    const result: any = await axios.get(
      this.getters["menu/getConfigVars"].hostname +
        "/api/WebServices/Customers/FilesTypesList.php",
    );

    await context.commit("setFilesTypes", result.data.filesTypes);

    return result;
  },
};

const mutations: any = {
  setSlides(state: any, slidesData: any) {
    state.slides = slidesData;
  },
  delSlide(state: any, itemId: any) {
    const index = state.slides.findIndex((slide: any) => slide.id == itemId);
    state.slides.splice(index, 1);
  },
  setCustomerData(state: any, customerData: any) {
    state.customerData = customerData;
  },
  setFiles(state: any, filesData: any) {
    state.files = filesData;
  },
  setFilesTypes(state: any, filesData: any) {
    state.filesTypes = filesData;
  },
  delFile(state: any, itemId: any) {
    const index = state.files.findIndex((file: any) => file.id == itemId);
    state.files.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
