<script setup>
import { computed, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps(["data"]);
const emit = defineEmits(["modVal", "upRec"]);

const recapito = ref(props.data);

const form = reactive({
  valore: recapito.value.Recapito,
});

const configVars = computed(() => store.getters["menu/getConfigVars"]);
const params = computed(() =>
  store.getters["menu/getMainMenu"]
    .filter((c) => c.code === "GRP-PROFILO")[0]
    .childs.filter((child) => child.code === "VIS-RECAPITI")[0]
    .params,
);

const modRecapiti = computed(() => {
  const checkMod = (value) => {
    let p = value.split("=")
    return p[1]
  }

  return params.value ? checkMod(params.value) : 'SI'
})

const modPro = (dbKey, value) => {
  emit("modVal", {
    dbKey: dbKey,
    value: value,
  });
};

const updateRecapito = (dbKey) => {
  emit("upRec", {
    dbKey: dbKey,
    value: form.valore,
  });
};

watch(
  () => recapito.value,
  (newVal, prevVal) => {},
  {
    immediate: true,
    deep: false,
  },
);
</script>
<template>
  <div class="text-sm text-slate-800 font-medium">
    {{ recapito.Descrizione }}
    <span v-if="recapito.Predefinito">*</span>
  </div>
  <!-- Right -->
  <div class="text-sm text-slate-800 ml-4">
    <template v-if="recapito.mod === false">
      <span class="mr-3">{{ recapito.Recapito }}</span>
    </template>
    <template v-else>
      <div class="flex">
        <button @click="updateRecapito(recapito.dbKey)" class="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <path
                fill="#52cc99"
                d="M6.03 10.83l-2.79-2.79-0.96 0.94 3.75 3.75 8.04-8.04-0.94-0.94-7.1 7.08z"
                class="color000 svgShape"
              />
            </svg>
          </svg>
        </button>
        <button @click="modPro(recapito.dbKey, false)" class="mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path
                fill="red"
                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41 15.59 7z"
                class="color000 svgShape"
              />
            </svg>
          </svg>
        </button>
        <input class="form-input w-full" v-model="form.valore" />
      </div>
    </template>
    <a
      class="font-medium"
      :class="configVars.tertiaryText + ' hover:' + configVars.primaryTextHover"
      href="#0"
      v-if="modRecapiti === 'SI' && recapito.mod === false"
      @click="modPro(recapito.dbKey, true)"
      >Modifica</a
    >
  </div>
</template>
