export function restrictChars() {
  const restrictCharsTot = ($event: any) => {
    if (
      $event.charCode === 0 ||
      /^[0-9]*\.?[0-9]*\.?[,]*$/.test(String.fromCharCode($event.charCode))
    ) {
      return true;
    } else {
      $event.preventDefault();
    }
  };

  const restrictCharsOnly = ($event: any) => {
    const char = String.fromCharCode($event.keyCode);
    if (!/[0-9]/.test(char)) {
      $event.preventDefault();
    }
  };

  const allowType = ($event: any, l = 2) => {
    if (
      $event.charCode === 0 ||
      /^[0-9]*\.?[0-9]*\.?[,]*$/.test(String.fromCharCode($event.charCode))
    ) {
      let val = $event.target.value;

      let i = val.search(/\./gim);
      if (val.length === 1) {
        val = val.replaceAll(/[^0-9]/gim, "");
      }
      if (i >= 0) {
        if (l) {
          val =
            val.substr(0, i + 1) +
            val.substr(i).substr(0, l).replaceAll(/\./gim, "");
        } else {
          val = val.substr(0, i + 1) + val.substr(i).replaceAll(/\./gim, "");
        }
      }

      let b = val.search(/,/gim);
      if (val.length === 1) {
        val = val.replaceAll(/[^0-9]/gim, "");
      }
      if (b >= 0) {
        if (l) {
          val =
            val.substr(0, b + 1) +
            val.substr(b).substr(0, l).replaceAll(/,/gim, "");
        } else {
          val = val.substr(0, b + 1) + val.substr(b).replaceAll(/,/gim, "");
        }
      }

      val = val.replaceAll(/[^0-9.,]/gim, "");

      $event.target.value = val;

      return true;
    } else {
      $event.preventDefault();
    }
  };

  return {
    restrictCharsTot,
    restrictCharsOnly,
    allowType,
  };
}
