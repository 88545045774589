import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import "./css/style.scss";
import moment from "moment";
import VueCookies from "vue-cookies";

const app = createApp(App);
app.config.globalProperties.$filters = {
  formatDate(value: string) {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  },
};

app.config.globalProperties.window = window;
app.config.globalProperties.ricercaClienti = ref(null);
app.config.globalProperties.$store = store;
app.use(store).use(router).use(VueCookies).mount("#app");
