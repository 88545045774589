<script setup>
import Banner2 from "../../components/Banner2.vue";
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
const route = useRoute();
const currentRoute = ref(useRouter().currentRoute.value);
const store = useStore();
const errorLicense = ref(false);
const tryLicense = ref(false);
const dataUser = ref(null);
const loaded1 = ref(false);
const loaded2 = ref(false);
const loaded3 = ref(false);
const errorSys = ref("");
const loadMenu = () => {
  store
    .dispatch("menu/fetchMenuWithoutToken", {
      Cliente_dbKey: dataUser.value.DatiCliente[0].Cliente_dbKey,
      apiSessionToken: dataUser.value.apiSessionToken,
    })
    .then(() => (loaded3.value = true));
};

const params = computed(() => {
  if (
    store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-FATTURE")
  ) {
    if (
      store.getters["menu/getMainMenu"].filter(
        (c) => c.code === "GRP-FATTURE",
      )[0]
    ) {
      return store.getters["menu/getMainMenu"]
        .filter((c) => c.code === "GRP-FATTURE")[0]
        .childs.filter((child) => child.code === "VIS-SOSPESI")[0].buttons;
    }
  }
  return null;
});

const configVars = computed(() => store.getters["menu/getConfigVars"]);

const downloading = ref(false);
const items = computed(() => store.getters["GRPFATTURE/getVisSospesi"]);
const user = computed(() => store.getters["user/getUser"]);
const basicModalOpen = ref(false);
const paymentModalOpen = ref(false);
const modalTitle = ref("");
const modalPaymentTitle = ref("Esegui il pagamento");
const modalText = ref("");
const modalFile = ref("");
const total = ref(0);
const totalFatture = ref(0);
const totalSelected = ref(0);
const totalSusp = ref(0);
const totalSelectedTot = ref(0);
import { gestCookies } from "../../composables/cookies";
const { getCookieFromComposable } = gestCookies();
const errorPayment = ref(false);
import ModalBasic from "../../components/ModalBasic.vue";
import FileSaver from "file-saver";

const loadData = () => {
  store
      .dispatch("GRPFATTURE/payByLink", {
        ShortId: currentRoute.value.query.p,
      })
      .then((res) => {
        loaded2.value = true;
        dataUser.value = res.data.apiResult;

        store
            .dispatch("menu/fetchMenuWithoutToken", {
              Cliente_dbKey: dataUser.value.DatiCliente[0].Cliente_dbKey,
              apiSessionToken: dataUser.value.apiSessionToken,
            })
            .then(() => {
              store
                  .dispatch("GRPFATTURE/fetchVisSospesiWithoutToken", {
                    Cliente_dbKey: dataUser.value.DatiCliente[0].Cliente_dbKey,
                  })
                  .then(() => {
                    loaded3.value = true;
                    if (!items.value.length) {
                      errorSys.value = "Nessun elemento trovato";
                    }
                  })
                  .catch((e) => {
                    errorSys.value = e.response.data.errorMessage;
                  });
            });
      });
};
const loadLicense = () => {
  //store.dispatch('menu/fetchLicense', 'https://sviluppoweb.iv-srl.it:444')
  store
      .dispatch("menu/fetchLicense", "https://" + window.location.hostname)
      .then(() => {
        tryLicense.value = true;
        loaded1.value = true;

        loadData()

      })
      .catch((e) => {
        tryLicense.value = true;
        errorLicense.value = true;
        errorSys.value = "Licenza non attiva";
        console.log(e)
      });
};
onMounted(async () => {
  await loadLicense();
});

const totale = (data) => {
  total.value = data.value;
  return "Totale: " + data.value;
};

const totaleFatt = (data) => {
  return (totalFatture.value = new Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 2,
  }).format(data.value));
};

const loadTableData = () => {
  loaded3.value = false;
  store
    .dispatch("GRPFATTURE/fetchVisSospesiWithoutToken", {
      Cliente_dbKey: dataUser.value.DatiCliente[0].Cliente_dbKey,
    })
    .then(() => {
      loaded3.value = true;
      if (!items.value.length) {
        errorSys.value = "Nessun elemento trovato";
      }
    })
    .catch((e) => {
      errorSys.value = e.response.data.errorMessage;
    });
};

const dataGrid = ref({});
const selectAllCheckBox = ref();
const checkBoxUpdating = ref(false);

const onInitialized = (e) => {
  dataGrid.value = e.component;
};

const isSelectable = (item) => {
  return item.PagaOnline;
};

const isSelectAll = async () => {
  let items = [];
  dataGrid.value
    .getDataSource()
    .store()
    .load()
    .done(function (data) {
      items = data;
    });

  let selectedRowKeys = 0;
  let selectableItems = items.filter(isSelectable);

  const result = await dataGrid.value.getSelectedRowsData().then((rowData) => {
    for (let i = 0; i < rowData.length; i += 1) {
      selectedRowKeys++;
    }
  });

  if (!result.length) {
    return false;
  }
  return result.length >= selectableItems.length ? true : undefined;
};

const onEditorPreparing = (e) => {
  let dataGrid = e.component;
  if (e.command === "select") {
    if (e.parentType === "dataRow" && e.row) {
      if (!isSelectable(e.row.data)) {
        e.editorOptions.disabled = true;
        e.cssClass = "disablecheckbox";
      }
    } else if (e.parentType === "headerRow") {
      e.editorOptions.onInitialized = (e) => {
        selectAllCheckBox.value = e.component;
      };
      isSelectAll().then((res) => (e.editorOptions.value = res));
      e.editorOptions.onValueChanged = (e) => {
        if (!e.event) {
          if (e.previousValue && !checkBoxUpdating) {
            e.component.option("value", e.previousValue);
          }
          return;
        }

        isSelectAll().then((res) => {
          //console.log(res)
          //console.log(e.value)
          if (res === e.value) {
            return;
          }
        });

        e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
        e.event.preventDefault();
      };
    }
  }
};

const dbKeys = ref([]);

const onSelectionChanged = (e) => {
  totalSusp.value = 0;
  let deselectRowKeys = [];
  dbKeys.value = [];

  dataGrid.value.getSelectedRowsData().then((rowData) => {
    totalSelectedTot.value = rowData.length;

    for (let i = 0; i < rowData.length; i += 1) {
      if (!isSelectable(rowData[i])) {
        deselectRowKeys.push(e.component.keyOf(rowData[i]));
      } else {
        dbKeys.value.push(rowData[i].__dbKey);
        totalSusp.value = totalSusp.value * 1 + rowData[i].ResiduoConSegno * 1;
      }
    }

    if (deselectRowKeys.length) {
      e.component.deselectRows(deselectRowKeys);
    }

    checkBoxUpdating.value = true;
    isSelectAll().then((res) => selectAllCheckBox.value.option("value", res));
    checkBoxUpdating.value = false;

    totalSelected.value = totalSusp.value;
  });
};

const modalBank = (tipo) => {
  const data = computed(() => store.getters["amm/getCustomerData"]);
  if (data.value) {
    if (tipo === "account") {
      modalTitle.value = "Indicazioni per bonifico bancario";
      modalText.value = data.value.bankAccount;
      modalFile.value = "";
    } else {
      modalTitle.value = "Domiciliazione bancaria";
      modalText.value = data.value.bankDebit;
      modalFile.value = data.value.bankDebitFile
        ? "/data/" + data.value.license + "/" + data.value.bankDebitFile
        : "";
    }
  } else {
    store
      .dispatch("amm/fetchCustomerData")
      .then(() => {
        if (tipo === "account") {
          modalTitle.value = "Indicazioni per bonifico bancario";
          modalText.value = data.value.bankAccount;
          modalFile.value = "";
        } else {
          modalTitle.value = "Domiciliazione bancaria";
          modalText.value = data.value.bankDebit;
          modalFile.value = data.value.bankDebitFile
            ? "/data/" + data.value.license + "/" + data.value.bankDebitFile
            : "";
        }
      })
      .catch((e) => (errorSys.value = e.response.data.message));
  }
};

const downloadFileContent = (ref, filename) => {
  downloading.value = true;

  store
    .dispatch("amm/downloadBlobFile", ref)
    .then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      FileSaver.saveAs(blob, filename);

      downloading.value = false;
    })
    .catch(() => (downloading.value = false));
};

const downloadSimpleFile = (ref, filename) => {
  let data = {
    resourceId: ref,
    apiSessionToken: dataUser.value.apiSessionToken,
  };

  store
    .dispatch("amm/downloadFileWithoutToken", data)
    .then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      FileSaver.saveAs(blob, filename);

      downloading.value = false;
    })
    .catch(() => (downloading.value = false));
};

let generateUrl = "https://" + window.location.hostname;

const paymentLoading = ref(false);

const startPayment = () => {
  paymentLoading.value = true;
  errorPayment.value = false;
  generateUrl = "https://" + window.location.hostname;

  let hidelements = document.getElementsByClassName("hidelements");

  while (hidelements.length > 0) {
    hidelements[0].parentNode.removeChild(hidelements[0]);
  }

  store
    .dispatch("GRPFATTURE/startPaymentWithoutToken", {
      Partite__dbKeys: dbKeys.value,
      apiSessionToken: dataUser.value.apiSessionToken,
    })
    .then((res) => {
      let paramPayment = import.meta.env.VITE_APP_URL_PAYMENT_OPTION;

      let host = window.location.host;
      let subdomain = host.split(".")[0];

      if (
        route.query.dev ||
        subdomain.includes("test") ||
        subdomain.includes("sviluppoweb")
      ) {
        paramPayment = "dev";
      }

      generateUrl +=
        "/pagamenti/index.php/init/" +
        res.data.apiResult.engineType.toLowerCase() +
        "/" +
        paramPayment +
        "/" +
        res.data.apiResult.presaldocode;

      store
        .dispatch("external/paymentStep2", {
          destinationUrl: generateUrl,
          info: {
            mailToNotify: res.data.apiResult.mailToNotify,
            transactionCode: res.data.apiResult.transactionCode,
            key: res.data.apiResult.key,
            alias: res.data.apiResult.alias,
            tid: res.data.apiResult.tid,
            infoText: res.data.apiResult.infoText,
            transactionFees: res.data.apiResult.transactionFees,
            transactionAmount: res.data.apiResult.transactionAmount,
          },
        })
        .then((res2) => {
          //console.log(res2)
          if (res2.data.type === "FORM") {
            document.getElementById("dynamicForm").action = res2.data.action;
            document.getElementById("dynamicForm").method = res2.data.method;

            Object.keys(res2.data.fields).forEach(function (k) {
              //console.log(k + ' - ' + res2.data.fields[k]);
              let x = document.createElement("INPUT");
              x.setAttribute("type", "hidden");
              x.setAttribute("name", k);
              x.setAttribute("class", "hidelements");
              x.setAttribute("value", res2.data.fields[k]);
              document.getElementById("dynamicForm").appendChild(x);
            });

            paymentModalOpen.value = true;
            setTimeout(() => {
              document.forms["dynamicForm"].submit();
            }, 1000);
          } else {
            paymentModalOpen.value = true;
            document.getElementById("ifr").src = res2.data.url;
          }
        })
        .catch((e) => {
          if (e.response && e.response.data) {
            errorPayment.value = e.response.data.errorMessage;
          }
        });
    })
    .catch((e) => {
      if (e.response && e.response.data) {
        errorPayment.value = e.response.data.errorMessage;
      }
    });
};

watch(paymentModalOpen, () => {
  if (paymentModalOpen.value === false) {
    paymentLoading.value = false;
    document.getElementById("ifr").src = "";
    loadTableData();

    totalSelected.value = 0;
    totalSelectedTot.value = 0;
    totalSusp.value = 0;
    dbKeys.value = [];

    dataGrid.value.deselectAll();
  }
});

const richiestaBollettino = () => {
  downloading.value = true;
  store
    .dispatch("GRPFATTURE/richiestaBollettinoWithoutToken", {
      Partite__dbKeys: dbKeys.value,
      apiSessionToken: dataUser.value.apiSessionToken,
    })
    .then((res) => {
      downloadSimpleFile(
        res.data.apiResult.resourceId,
        res.data.apiResult.filename,
      );
    })
    .catch((e) => {
      errorSys.value = e.response.data.errorMessage;
    });
};


</script>
<template>
  <main>
    <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="sm:flex sm:justify-between sm:items-center mb-8">
        <!-- Left: Title -->
        <div class="mb-4 sm:mb-0">
          <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
            Benvenuto nell'area clienti di {{ configVars.company }}
          </h1>
        </div>
      </div>
      <div class="mt-5" v-if="(!loaded1 || !loaded2 || !loaded3) && !errorSys">
        <div
          class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
          :class="configVars.secondaryText + ' ' + configVars.primary"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                ></path>
              </svg>
              <div class="loading_course">Caricamento in corso</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="errorSys">
        <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                ></path>
              </svg>
              <div class="font-medium">{{ errorSys }}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="border-t border-slate-200"
        v-if="loaded1 && loaded2 && loaded3 && !errorSys"
      >
        <div class="max-w-2xl m-auto mt-16">
          <div class="text-center px-4">
            <div
              class="inline-flex items-center justify-center to-slate-100 mb-4"
            >
              <img
                :src="
                  '/data/' + configVars.licenseKey + '/' + configVars.logoSmall
                "
              />
            </div>
            <h2 class="text-2xl text-slate-800 font-bold mb-2">
              {{ configVars.titlePayPage }}
            </h2>
            <div class="mb-6" v-html="configVars.descriptionPayPage"></div>
          </div>
        </div>
      </div>
      <div
        class="flex p-6 bg-white mb-5"
        v-if="loaded1 && loaded2 && loaded3 && !errorSys"
      >
        <template v-if="params">
          <template v-for="b in params">
            <div
              class="sm:w-1/2 xl:w-1/5"
              v-if="b.code === 'PAGA-ONLINE' && totalSelectedTot > 0"
            >
              <button
                class="btn text-white"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
              >
                <svg
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                >
                  <title>credit_card</title>
                  <g fill="none" class="nc-icon-wrapper">
                    <path
                      d="M13.4 2.68h-10.72c-0.74 0-1.33 0.6-1.33 1.34l-0.01 8.04c0 0.74 0.6 1.34 1.34 1.34h10.72c0.74 0 1.34-0.6 1.34-1.34v-8.04c0-0.74-0.6-1.34-1.34-1.34z m0 9.38h-10.72v-4.02h10.72v4.02z m0-6.7h-10.72v-1.34h10.72v1.34z"
                      fill="#ffffff"
                    ></path>
                  </g>
                </svg>
                <button class="hidden xs:block ml-2" @click="startPayment()">
                  Paga ora
                </button>
              </button>
            </div>
            <div
              class="sm:w-1/2 xl:w-1/5"
              v-if="b.code === 'PAGA-BOLLETTINO' && totalSelectedTot > 0"
            >
              <button
                class="btn text-white"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
                @click="richiestaBollettino"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="16"
                  viewBox="0 0 16 16"
                >
                  <title>file text</title>
                  <g
                    stroke-width="1"
                    stroke-linecap="round"
                    fill="none"
                    stroke="#ffffff"
                    stroke-miterlimit="10"
                    class="nc-icon-wrapper"
                    stroke-linejoin="round"
                  >
                    <line
                      x1="4.5"
                      y1="11.5"
                      x2="11.5"
                      y2="11.5"
                      stroke="#ffffff"
                    ></line>
                    <line
                      x1="4.5"
                      y1="8.5"
                      x2="11.5"
                      y2="8.5"
                      stroke="#ffffff"
                    ></line>
                    <line
                      x1="4.5"
                      y1="5.5"
                      x2="6.5"
                      y2="5.5"
                      stroke="#ffffff"
                    ></line>
                    <polygon
                      points="9.5,0.5 1.5,0.5 1.5,15.5 14.5,15.5 14.5,5.5 "
                    ></polygon>
                    <polyline points="9.5,0.5 9.5,5.5 14.5,5.5 "></polyline>
                  </g>
                </svg>
                <button class="hidden xs:block ml-2">Bollettino postale</button>
              </button>
            </div>
            <div class="sm:w-1/2 xl:w-1/5" v-if="b.code === 'PAGA_BONIFICO'">
              <button
                class="btn text-white"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
                aria-controls="basic-modal"
                @click.stop="
                  basicModalOpen = true;
                  modalBank('account');
                "
              >
                <svg
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                >
                  <title>food_bank</title>
                  <g fill="none" class="nc-icon-wrapper">
                    <path
                      d="M8.04 3.69l4.02 3.01v6.03h-8.04v-6.03l4.02-3.01z m0-1.68l-5.36 4.02v8.04h10.72v-8.04l-5.36-4.02z m-0.33 4.36v2.01h-0.34v-2.01h-0.67v2.01h-0.33v-2.01h-0.67v2.01c0 0.56 0.45 1.01 1 1v2.68h0.67v-2.68c0.56 0 1.01-0.45 1.01-1v-2.01h-0.67z m1 1.34v2.01h0.67v2.34h0.67v-5.69c-0.74 0-1.34 0.6-1.34 1.34z"
                      fill="#ffffff"
                    ></path>
                  </g>
                </svg>
                <button class="hidden xs:block ml-2">
                  Coordinate bancarie
                </button>
              </button>
            </div>
            <div class="sm:w-1/2 xl:w-1/5" v-if="b.code === 'ATTIVA-SDD'">
              <button
                class="btn text-white"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
                aria-controls="basic-modal"
                @click.stop="
                  basicModalOpen = true;
                  modalBank('debit');
                "
              >
                <svg
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                >
                  <title>attach_money</title>
                  <g fill="none" class="nc-icon-wrapper">
                    <path
                      d="M7.71 11.46c-1.38 0-1.92-0.62-2-1.41h-1.48c0.08 1.47 1.18 2.29 2.47 2.57v1.45h2.01v-1.44c1.31-0.25 2.35-1.01 2.35-2.38 0-1.9-1.63-2.55-3.15-2.95-1.52-0.4-2.01-0.8-2.01-1.44 0-0.73 0.68-1.24 1.8-1.24 1.19 0 1.63 0.57 1.68 1.41h1.48c-0.05-1.15-0.75-2.21-2.15-2.55v-1.47h-2.01v1.45c-1.3 0.28-2.35 1.13-2.35 2.42 0 1.55 1.28 2.32 3.15 2.76 1.68 0.4 2.01 0.99 2.01 1.62 0 0.46-0.33 1.2-1.81 1.2z"
                      fill="#ffffff"
                    ></path>
                  </g>
                </svg>
                <button class="hidden xs:block ml-2">
                  Attiva addebito bancario
                </button>
              </button>
            </div>
          </template>
        </template>
        <div class="sm:w-1/2 xl:w-1/5">
          <h2 class="text-base font-semibold">Totale €: {{ totalFatture }}</h2>
          <h4 class="text-sm">
            Totale selezionato €:
            {{
              new Intl.NumberFormat("it-IT", {
                minimumFractionDigits: 2,
              }).format(totalSelected)
            }}
          </h4>
        </div>
      </div>
      <div class="mb-5" v-if="paymentLoading">
        <Banner2 type="info" :open="true"> Pagamento in corso... </Banner2>
      </div>
      <div class="mb-5" v-if="errorPayment">
        <Banner2 type="error" :open="true">
          {{ errorPayment }}
        </Banner2>
      </div>
      <div class="mb-5" v-if="downloading">
        <Banner2 type="warning" :open="true">
          Attendi, download in corso...
        </Banner2>
      </div>
      <div
        class="py-4 bg-white"
        v-if="loaded1 && loaded2 && loaded3 && !errorSys"
      >
        <DxDataGrid
          id="gridContainer"
          :data-source="items"
          :column-auto-width="true"
          :show-borders="true"
          noDataText="Nessun risultato"
          :row-alternation-enabled="true"
          @selection-changed="onSelectionChanged"
          key-expr="IdPartita"
          :on-initialized="onInitialized"
          :remote-operations="false"
          @editor-preparing="onEditorPreparing"
        >
          <DxScrolling
            :use-native="false"
            :scroll-by-content="true"
            :scroll-by-thumb="true"
            show-scrollbar="onHover"
            mode="infinite"
          />
          <DxSelection
            showCheckBoxesMode="always"
            mode="multiple"
            :deferred="true"
          />
          <DxSearchPanel :visible="true" placeholder="Cerca..." />
          <DxToolbar>
            <DxItem name="searchPanel" />
          </DxToolbar>
          <DxLoadPanel :enabled="false" />
          <DxSorting mode="single" />
          <DxPaging :enabled="false" />

          <DxColumn
            header-cell-template="title-header"
            data-field="Desnum"
            caption="N° DOCUMENTO"
            alignment="left"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="DataDocumento"
            data-type="date"
            format="dd/MM/yyyy"
            caption="DATA"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="TipoPagamento/Descri"
            caption="TIPO PAGAMENTO"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="ImportoConSegno"
            caption="IMPORTO €"
            data-type="number"
            alignment="right"
            cell-template="number-cell"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="ResiduoConSegno"
            caption="RESIDUO €"
            data-type="number"
            alignment="right"
            cell-template="number-cell"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="TipoVendita.description"
            caption="TIPOLOGIA VENDITA"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="Scadenza"
            data-type="date"
            format="dd/MM/yyyy"
            caption="SCADENZA"
          />
          <DxColumn
            header-cell-template="title-header"
            data-field="NGiorniScaduto"
            caption="N° GIORNI SCADUTO"
          />
          <!--<DxColumn header-cell-template="title-header" data-field="Fattura" caption="FILE" alignment="right" cell-template="fattura-cell" />-->
          <DxColumn type="selection" />
          <template #fattura-cell="{ data }">
            <div class="float-right">
              <template v-if="data.value">
                <a
                  href="#"
                  @click.prevent="
                    downloadFileContent(
                      data.data.Fattura.ref,
                      data.data.Fattura.filename,
                    )
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="24"
                    viewBox="0 0 75.32 92.604"
                  >
                    <title>icon</title>
                    <g class="nc-icon-wrapper">
                      <g transform="translate(53.548 -183.975) scale(1.4843)">
                        <path
                          fill="#ff2116"
                          d="M-29.633 123.947c-3.552 0-6.443 2.894-6.443 6.446v49.498c0 3.551 2.891 6.445 6.443 6.445h37.85c3.552 0 6.443-2.893 6.443-6.445v-40.702s.102-1.191-.416-2.351a6.516 6.516 0 0 0-1.275-1.844 1.058 1.058 0 0 0-.006-.008l-9.39-9.21a1.058 1.058 0 0 0-.016-.016s-.802-.764-1.99-1.274c-1.4-.6-2.842-.537-2.842-.537l.021-.002z"
                          color="#000"
                          font-family="sans-serif"
                          overflow="visible"
                          paint-order="markers fill stroke"
                          style="
                            line-height: normal;
                            font-variant-ligatures: normal;
                            font-variant-position: normal;
                            font-variant-caps: normal;
                            font-variant-numeric: normal;
                            font-variant-alternates: normal;
                            font-feature-settings: normal;
                            text-indent: 0;
                            text-align: start;
                            text-decoration-line: none;
                            text-decoration-style: solid;
                            text-decoration-color: #000000;
                            text-transform: none;
                            text-orientation: mixed;
                            shape-padding: 0;
                            isolation: auto;
                            mix-blend-mode: normal;
                          "
                          white-space="normal"
                          solid-color="#000000"
                          solid-opacity="1"
                        ></path>
                        <path
                          fill="#f5f5f5"
                          d="M-29.633 126.064h28.38a1.058 1.058 0 0 0 .02 0s1.135.011 1.965.368a5.385 5.385 0 0 1 1.373.869l9.368 9.19s.564.595.838 1.208c.22.495.234 1.4.234 1.4a1.058 1.058 0 0 0-.002.046v40.746a4.294 4.294 0 0 1-4.326 4.328h-37.85a4.294 4.294 0 0 1-4.326-4.328v-49.498a4.294 4.294 0 0 1 4.326-4.328z"
                          color="#000"
                          font-family="sans-serif"
                          overflow="visible"
                          paint-order="markers fill stroke"
                          style="
                            line-height: normal;
                            font-variant-ligatures: normal;
                            font-variant-position: normal;
                            font-variant-caps: normal;
                            font-variant-numeric: normal;
                            font-variant-alternates: normal;
                            font-feature-settings: normal;
                            text-indent: 0;
                            text-align: start;
                            text-decoration-line: none;
                            text-decoration-style: solid;
                            text-decoration-color: #000000;
                            text-transform: none;
                            text-orientation: mixed;
                            shape-padding: 0;
                            isolation: auto;
                            mix-blend-mode: normal;
                          "
                          white-space="normal"
                          solid-color="#000000"
                          solid-opacity="1"
                        ></path>
                        <path
                          fill="#ff2116"
                          d="M-23.408 161.093c-1.456-1.457.12-3.458 4.397-5.584l2.691-1.337 1.049-2.294a90.314 90.314 0 0 0 1.912-4.575l.864-2.281-.595-1.688c-.732-2.075-.994-5.194-.529-6.316.63-1.519 2.69-1.363 3.506.265.638 1.272.572 3.575-.183 6.48l-.62 2.381.546.926c.3.51 1.177 1.719 1.948 2.687l1.449 1.803 1.803-.235c5.73-.748 7.692.523 7.692 2.345 0 2.299-4.499 2.489-8.277-.165-.85-.597-1.433-1.19-1.433-1.19s-2.367.482-3.532.796c-1.203.324-1.803.527-3.564 1.122 0 0-.619.898-1.021 1.55-1.499 2.428-3.249 4.44-4.498 5.173-1.4.82-2.866.875-3.605.137zm2.286-.817c.82-.506 2.477-2.466 3.624-4.285l.464-.737-2.115 1.064c-3.266 1.642-4.76 3.19-3.984 4.126.437.526.96.483 2.011-.168zm21.218-5.955c.801-.561.685-1.692-.22-2.148-.705-.354-1.273-.427-3.103-.4-1.125.076-2.934.303-3.24.372 0 0 .993.687 1.434.94.587.335 2.015.957 3.057 1.276 1.027.315 1.622.282 2.072-.04zm-8.531-3.546c-.485-.51-1.309-1.573-1.832-2.364-.683-.896-1.026-1.528-1.026-1.528s-.5 1.606-.91 2.573l-1.278 3.161-.37.717s1.97-.646 2.973-.908c1.062-.277 3.218-.701 3.218-.701zm-2.75-11.026c.124-1.038.177-2.074-.157-2.596-.924-1.01-2.04-.168-1.851 2.235.063.809.264 2.19.533 3.042l.488 1.549.343-1.167c.19-.641.48-2.02.645-3.063z"
                        ></path>
                        <path
                          fill="#2c2c2c"
                          d="M-20.93 167.839h2.365q1.133 0 1.84.217.706.21 1.19.944.482.728.482 1.756 0 .945-.392 1.624-.392.678-1.056.98-.658.3-2.03.3h-.818v3.73h-1.581zm1.58 1.224v3.33h.785q1.05 0 1.448-.391.406-.392.406-1.274 0-.657-.266-1.063-.266-.413-.588-.504-.315-.098-1-.098zm5.508-1.224h2.148q1.56 0 2.49.552.938.553 1.414 1.645.483 1.091.483 2.42 0 1.4-.434 2.499-.427 1.091-1.316 1.763-.881.672-2.518.672h-2.267zm1.58 1.266v7.018h.659q1.378 0 2-.952.623-.958.623-2.553 0-3.513-2.623-3.513zm6.473-1.266h5.304v1.266h-3.723v2.855h2.981v1.266h-2.98v4.164H-5.79z"
                          font-family="Franklin Gothic Medium Cond"
                          letter-spacing="0"
                          style="
                            line-height: 125%;
                            -inkscape-font-specification: &quot;Franklin Gothic Medium Cond&quot;;
                          "
                          word-spacing="4.26"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </template>
            </div>
          </template>
          <template #totalCountTemplate>
            <h2 class="font-semibold !text-slate-800 text-lg ml-5">
              Totale:
              <span class="text-slate-400 font-medium">{{ total }}</span>
            </h2>
          </template>
          <template #number-cell="{ data }">
            <div>
              {{
                new Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 2,
                }).format(data.value)
              }}
            </div>
          </template>
          <template #title-header="{ data }">
            <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
              {{ data.column.caption }}
            </p>
          </template>
          <DxSummary>
            <DxTotalItem
              column="ResiduoConSegno"
              summary-type="sum"
              :customize-text="totaleFatt"
            />
          </DxSummary>
        </DxDataGrid>
      </div>
      <!-- Table -->
      <ModalBasic
        id="basic-modal"
        :modalOpen="basicModalOpen"
        @close-modal="basicModalOpen = false"
        :title="modalTitle"
      >
        <!-- Modal content -->
        <div class="px-5 pt-4 pb-1">
          <div class="text-sm">
            <div class="space-y-2" v-html="modalText"></div>
            <div v-if="modalFile">
              <a :href="modalFile" target="_blank">
                <button
                  class="btn text-white mt-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                  :class="
                    configVars.primary + ' hover:' + configVars.primaryHover
                  "
                  type="button"
                >
                  Scarica l'allegato
                </button>
              </a>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4">
          <div class="flex flex-wrap justify-end space-x-2">
            <button
              class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
              @click.stop="basicModalOpen = false"
            >
              Chiudi
            </button>
          </div>
        </div>
      </ModalBasic>

      <ModalBasic
        id="payment-modal"
        :modalOpen="paymentModalOpen"
        @close-modal="paymentModalOpen = false"
        :title="modalPaymentTitle"
      >
        <!-- Modal content -->
        <div class="px-5 pt-4 pb-1">
          <div class="text-sm">
            <div class="space-y-2">
              <form
                name="dynamicForm"
                id="dynamicForm"
                target="if"
                novalidate
              ></form>
              <iframe
                style="overflow: hidden; height: 100vh; width: 100%; border: 0"
                src=""
                width="100%"
                name="if"
                id="ifr"
                height="100vh"
              ></iframe>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="px-5 py-4">
          <div class="flex flex-wrap justify-end space-x-2">
            <button
              class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
              @click.stop="paymentModalOpen = false"
            >
              Chiudi
            </button>
          </div>
        </div>
      </ModalBasic>
    </div>
  </main>
</template>
