<script setup xmlns="http://www.w3.org/1999/html">
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { computed, onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
const store = useStore();
import ModalBasic from "../../components/ModalBasic.vue";
import ModalBasicLarge from "../../components/ModalBasicLarge.vue";
import { parseError } from "../../composables/parseError";
import { restrictChars } from "../../composables/restrictChars";
import FileSaver from "file-saver";
const props = defineProps([
  "item",
  "doc",
  "firstLimit",
  "secondLimit",
  "thirdLimit",
  "today",
  "fourthLimit",
  "fifthLimit",
  "sixthLimit",
]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const { errorSys, calcError } = parseError();
const { restrictCharsOnly, restrictCharsTot } = restrictChars();
const downloading = ref(false);
const dettagli = (id) => {
  if (document.getElementById("dettagli_" + id).classList.contains("hidden")) {
    document.getElementById("dettagli_" + id).classList.remove("hidden");
  } else {
    document.getElementById("dettagli_" + id).classList.add("hidden");
  }
};
import Banner2 from "../../components/Banner2.vue";
import {
  email,
  helpers,
  maxValue,
  minValue,
  required,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

onMounted(() => {
  if (props.item.V_serbatoi) {
    if (props.item.V_serbatoi.length) {
      props.item.V_serbatoi.forEach((el) => {
        capacitaTot.value += el.capacita;
      });
    }
  }
});

const params = computed(() => {
  if (
    store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-CONTRATTI")
  ) {
    if (
      store.getters["menu/getMainMenu"].filter(
        (c) => c.code === "GRP-CONTRATTI",
      )[0]
    ) {
      return store.getters["menu/getMainMenu"]
        .filter((c) => c.code === "GRP-CONTRATTI")[0]
        .childs.filter((child) => child.code === "VIS-SERBATOI")[0].buttons;
    }
  }
  return null;
});
const basicModalOpen = ref(false);

// STORICO
const storicoModalOpen = ref(false);
const loadingStorico = ref(false);
const formStorico = reactive({
  dataDa: props.thirdLimit,
  dataA: props.today,
});
const itemsStorico = computed(() => store.getters["GRPCONTRATTI/getStorico"]);

const storicoWithDates = () => {
  loadingStorico.value = true;
  store
    .dispatch("GRPCONTRATTI/fetchStorico", {
      Destinatario_dbKey: props.item.V_Destinatario.length
        ? props.item.V_Destinatario[0].__dbKey
        : "",
      DataMovimento_from: formStorico.dataDa,
      DataMovimento_to: formStorico.dataA,
    })
    .then(() => {
      loadingStorico.value = false;
    })
    .catch((e) => {
      loadingStorico.value = false;
      calcError(e);
    });
};

const downloadFileContent = (ref, filename) => {
  downloading.value = true;

  store
    .dispatch("amm/downloadBlobFile", ref)
    .then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      FileSaver.saveAs(blob, filename);

      downloading.value = false;
    })
    .catch(() => (downloading.value = false));
};

// RIFORNIMENTO
const capacitaTot = ref(0);
const rifornimentoModalOpen = ref(false);
const rifornimentoAdded = ref(false);
const formRifornimento = reactive({
  litri: "",
  importo: "",
  livello: "",
  note: "",
});

const calcMaxRifornimento = () => {
  return (capacitaTot.value * 80) / 100;
};

const calcLitri = () => {
  let capacitaMassima =
    capacitaTot.value - (capacitaTot.value - (capacitaTot.value * 80) / 100);
  let litriRimanenti =
    capacitaTot.value -
    (capacitaTot.value - (capacitaTot.value * formRifornimento.livello) / 100);
  formRifornimento.litri = capacitaMassima - litriRimanenti;
};

const richiediRifornimento = () => {
  store
    .dispatch("GRPCONTRATTI/richiediRifornimento", {
      dbKeyDestin: props.item.V_Destinatario.length
        ? props.item.V_Destinatario[0].__dbKey
        : "",
      Litri: formRifornimento.litri,
      Livello: formRifornimento.livello,
      ImportoRichiesto: formRifornimento.importo.replace(",", "."),
      Note: formRifornimento.note,
    })
    .then(() => {
      rifornimentoAdded.value = true;
      setTimeout(() => {
        rifornimentoAdded.value = false;
        rifornimentoModalOpen.value = false;
        formRifornimento.litri = "";
        formRifornimento.livello = "";
        formRifornimento.importo = "";
        formRifornimento.note = "";
      }, 2500);
    })
    .catch((e) => {
      calcError(e);
    });
};

const rulesRifornimento = computed(() => {
  return {
    litri: {
      maxValue: helpers.withMessage(
        "Il valore massimo non può superare l'80%",
        helpers.withAsync(async (value) => {
          if (calcMaxRifornimento() < value) {
            return false;
          }
          return true;
        }),
      ),
      minValue: helpers.withMessage(
        "Il valore minimo non può essere inferiore a 0",
        minValue(0),
      ),
    },
    livello: {
      maxValue: helpers.withMessage(
        "Il valore massimo non può superare l'80%",
        maxValue(80),
      ),
      minValue: helpers.withMessage(
        "Il valore minimo non può essere inferiore a 0",
        minValue(0),
      ),
    },
    importo: {
      minValue: helpers.withMessage(
        "Il valore minimo non può essere inferiore a 0",
        minValue(0),
      ),
    },
    note: {},
  };
});
const vvRifornimento = useVuelidate(rulesRifornimento.value, {
  ...toRefs(formRifornimento),
});

// NUOVO LIVELLO
const capacita = ref(0);
const livelloModalOpen = ref(false);
const livelloAdded = ref(false);
const calcLivello = ref(0);
const modLivello = () => {
  if (formLivello.Livello > 100) formLivello.Livello = 100;
  calcLivello.value = (capacita.value * formLivello.Livello) / 100;
};
const formLivello = reactive({
  Data: props.today,
  dbKeyLocatario: "",
  dbKeySerbatoio: "",
  Livello: 0,
});

const nuovoLivello = () => {
  store
    .dispatch("GRPCONTRATTI/nuovoLivelloSerbatoi", formLivello)
    .then(() => {
      livelloAdded.value = true;
      setTimeout(() => {
        livelloAdded.value = false;
        livelloModalOpen.value = false;
        calcLivello.value = 0;
        formLivello.Livello = 0;
      }, 2500);
    })
    .catch((e) => {
      calcError(e);
    });
};

const rulesLivello = {
  Livello: {
    required: helpers.withMessage("Livello obbligatorio", required),
    maxValue: helpers.withMessage(
      "Il valore massimo non può superare il 100%",
      maxValue(100),
    ),
    minValue: helpers.withMessage(
      "Il valore minimo non può essere inferiore a 0",
      minValue(0),
    ),
  },
  Data: {},
  dbKeyLocatario: {},
  dbKeySerbatoio: {},
};
const vvLivello = useVuelidate(rulesLivello, { ...toRefs(formLivello) });

// PREZZI SFUSO
const sfusoModalOpen = ref(false);
const loadingSfuso = ref(false);
const formSfuso = reactive({
  dataDa: props.sixthLimit,
  dataA: props.today,
});
const itemsSfuso = computed(
  () => store.getters["GRPCONTRATTI/getPrezziSfuso"].PrezzoFasce,
);

const sfusoWithDates = () => {
  loadingSfuso.value = true;
  store
    .dispatch("GRPCONTRATTI/fetchPrezziSfuso", {
      Destin_dbKey: props.item.V_Destinatario.length
        ? props.item.V_Destinatario[0].__dbKey
        : "",
      DaData: formSfuso.dataDa,
      AData: formSfuso.dataA,
    })
    .then(() => {
      loadingSfuso.value = false;
    })
    .catch((e) => {
      loadingSfuso.value = false;
      calcError(e);
    });
};

// PREZZI PRATICATI
const prezziModalOpen = ref(false);
const loadingPrezzi = ref(false);
const formPrezzi = reactive({
  dataDa: props.thirdLimit,
  dataA: props.today,
});
const itemsPrezzi = computed(
  () => store.getters["GRPCONTRATTI/getPrezziPraticati"].PrezzoFasce,
);

const prezzi = () => {
  loadingPrezzi.value = true;
  store
    .dispatch("GRPCONTRATTI/fetchPrezziPraticati", {
      Destin_dbKey: props.item.V_Destinatario.length
        ? props.item.V_Destinatario[0].__dbKey
        : "",
    })
    .then(() => {
      loadingPrezzi.value = false;
    })
    .catch((e) => {
      loadingPrezzi.value = false;
      calcError(e);
    });
};

const replaceField = (data) => {
  let myJSONString = JSON.stringify(data);
  return JSON.parse(myJSONString.replace(/\//g, ""));
};
</script>
<template>
  <div
    class="col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200"
  >
    <div class="flex flex-col h-full">
      <!-- Card top -->
      <div class="grow p-5">
        <div class="flex justify-between items-start">
          <!-- Image + name -->
          <header class="w-max">
            <div class="flex">
              <div class="mt-1 pr-1">
                <div class="inline-flex text-slate-800 hover:text-slate-900">
                  <h2 class="text-xl leading-snug justify-center font-semibold">
                    {{
                      props.item.V_Destinatario.length
                        ? props.item.V_Destinatario[0].ragioneSociale
                          ? props.item.V_Destinatario[0].ragioneSociale
                          : props.item.V_Destinatario[0].intestazioneContratto
                        : ""
                    }}
                  </h2>
                </div>
              </div>
            </div>
          </header>
          <div class="w-max" v-if="params.length">
            <template v-for="p in params">
              <button
                class="mr-1 mb-1 p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm"
                :title="p.description"
                v-if="p.code === 'PRZ-SFUSO'"
                aria-controls="prezzi-modal"
                @click="prezzi()"
                @click.stop="prezziModalOpen = true"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  :class="configVars.primaryText"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M 22 7 C 12.0625 7 4 15.0625 4 25 C 4 34.9375 12.0625 43 22 43 C 23.222656 43 24.410156 42.855469 25.5625 42.625 C 26.613281 42.8125 27.78125 43 29 43 C 38.9375 43 47 34.9375 47 25 C 47 15.0625 38.9375 7 29 7 C 27.847656 7 26.691406 7.101563 25.5625 7.375 C 24.410156 7.144531 23.222656 7 22 7 Z M 22 9 C 23.042969 9 24.046875 9.09375 25.03125 9.28125 C 25.203125 9.378906 25.398438 9.421875 25.59375 9.40625 C 32.707031 11.035156 38 17.378906 38 25 C 38 32.640625 32.675781 38.988281 25.53125 40.59375 C 25.367188 40.597656 25.207031 40.640625 25.0625 40.71875 C 24.066406 40.910156 23.054688 41 22 41 C 13.140625 41 6 33.859375 6 25 C 6 16.140625 13.140625 9 22 9 Z M 30.375 9.09375 C 38.582031 9.796875 45 16.609375 45 25 C 45 33.390625 38.582031 40.203125 30.375 40.90625 C 36.097656 37.890625 40 31.917969 40 25 C 40 18.082031 36.097656 12.109375 30.375 9.09375 Z M 21 14 L 21 16.1875 C 19.398438 16.386719 16.5 17.789063 16.5 21.1875 C 16.5 27.585938 25.8125 24.292969 25.8125 29.09375 C 25.8125 30.695313 25.101563 32.1875 22 32.1875 C 18.898438 32.1875 18 29.800781 18 28.5 L 16 28.5 C 16.300781 32.800781 19.300781 33.792969 21 34.09375 L 21 36 L 23 36 L 23 34.09375 C 24.5 33.992188 28 32.90625 28 28.90625 C 28 25.605469 25.289063 24.695313 22.6875 24.09375 C 20.585938 23.59375 18.6875 23.101563 18.6875 21 C 18.6875 20.101563 19.09375 18.09375 22.09375 18.09375 C 24.195313 18.09375 25.199219 19.398438 25.5 21 L 27.5 21 C 26.898438 18.800781 25.898438 16.8125 23 16.3125 L 23 14 Z"
                  />
                </svg>
              </button>
              <button
                class="mr-1 mb-1 p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm"
                :title="p.description"
                v-if="p.code === 'PRZ-SFUSTORICO'"
                aria-controls="sfuso-modal"
                @click="sfusoWithDates()"
                @click.stop="sfusoModalOpen = true"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  :class="configVars.primaryText"
                  viewBox="0 0 50 44"
                >
                  <path
                    d="M 28 4 C 26.408649 4 24.883169 4.6324568 23.757812 5.7578125 L 5.7578125 23.757812 C 3.4278601 26.087765 3.4278601 29.912235 5.7578125 32.242188 L 15.757812 42.242188 C 16.883169 43.367542 18.408649 44 20 44 C 21.591351 44 23.116831 43.367543 24.242188 42.242188 L 42.242188 24.242188 C 43.367959 23.116416 44 21.589398 44 19.998047 L 44 9 C 44 6.2565285 41.743472 4 39 4 L 28 4 z M 28 7 L 39 7 C 40.116528 7 41 7.8834715 41 9 L 41 19.998047 C 41 20.810696 40.695322 21.546865 40.121094 22.121094 L 22.121094 40.121094 C 21.546449 40.695738 20.812649 41 20 41 C 19.187351 41 18.453551 40.695738 17.878906 40.121094 L 7.8789062 30.121094 C 6.6988587 28.941046 6.6988586 27.058954 7.8789062 25.878906 L 25.878906 7.8789062 C 26.453551 7.3042619 27.187351 7 28 7 z M 34 11 C 32.343 11 31 12.343 31 14 C 31 15.657 32.343 17 34 17 C 35.657 17 37 15.657 37 14 C 37 12.343 35.657 11 34 11 z M 23.65625 19.015625 C 22.310201 19.11834 20.995069 19.702196 19.998047 20.699219 L 19.658203 21.037109 L 19.560547 20.939453 A 1.50015 1.50015 0 1 0 17.439453 23.060547 L 17.537109 23.158203 L 16.261719 24.433594 C 16.053828 24.641484 15.762578 24.641484 15.554688 24.433594 A 1.50015 1.50015 0 0 0 13.433594 26.554688 L 20.439453 33.560547 A 1.50015 1.50015 0 1 0 22.560547 31.439453 L 17.957031 26.835938 C 18.095642 26.734072 18.257904 26.679595 18.382812 26.554688 L 19.658203 25.279297 L 20.939453 26.560547 A 1.50015 1.50015 0 1 0 23.060547 24.439453 L 21.779297 23.158203 L 22.119141 22.820312 C 23.079096 21.860357 24.612621 21.720915 25.591797 22.529297 C 26.600904 23.362628 26.756777 24.700403 26.152344 25.726562 A 1.500661 1.500661 0 1 0 28.738281 27.25 C 30.065848 24.996159 29.638846 21.981466 27.501953 20.216797 A 1.50015 1.50015 0 0 0 27.5 20.216797 C 26.378635 19.290988 25.002299 18.91291 23.65625 19.015625 z"
                  />
                </svg>
              </button>
              <button
                class="mr-1 mb-1 p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm"
                :title="p.description"
                v-if="p.code === 'STOR-SFU'"
                aria-controls="storico-modal"
                @click="storicoWithDates()"
                @click.stop="storicoModalOpen = true"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  :class="configVars.primaryText"
                  viewBox="4 0 112 112"
                >
                  <path
                    d="M 63.416016 12.007812 A 20 20 0 0 0 44 32 L 44 48 L 33 48 A 2 2 0 0 0 31 49.640625 L 21.519531 101.38086 A 9 9 0 0 0 30.400391 112 L 97.599609 112 A 9 9 0 0 0 106.44922 101.38086 L 97 49.640625 A 2 2 0 0 0 95 48 L 84 48 L 84 32 A 20 20 0 0 0 63.416016 12.007812 z M 64.466797 16.005859 A 16 16 0 0 1 80 32 L 80 48 L 48 48 L 48 32 A 16 16 0 0 1 64.466797 16.005859 z M 34.669922 52 L 93.330078 52 L 102.51953 102.09961 A 5 5 0 0 1 101.43945 106.19922 A 5 5 0 0 1 97.599609 108 L 30.400391 108 A 5 5 0 0 1 25.480469 102.09961 L 34.669922 52 z M 48 60 A 8 8 0 1 0 56 68 A 8 8 0 0 0 48 60 z M 80 60 A 8 8 0 1 0 88 68 A 8 8 0 0 0 80 60 z M 47.833984 64.003906 A 4 4 0 0 1 52 68 A 4 4 0 0 1 48 72 A 4 4 0 0 1 47.833984 64.003906 z M 79.833984 64.003906 A 4 4 0 0 1 84 68 A 4 4 0 0 1 80 72 A 4 4 0 0 1 79.833984 64.003906 z"
                  />
                </svg>
              </button>
              <button
                class="mr-1 mb-1 p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm"
                :title="p.description"
                v-if="p.code === 'ORD-SFUSO'"
                aria-controls="rifornimento-modal"
                @click.stop="rifornimentoModalOpen = true"
                @click="errorSys = ''"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  :class="configVars.primaryText"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M 1.8125 2 C 1.78125 2.007813 1.75 2.019531 1.71875 2.03125 C 0.746094 2.167969 0 2.992188 0 4 C 0 5.105469 0.894531 6 2 6 C 3.105469 6 4 5.105469 4 4 L 8.65625 4 C 9.902344 4 10.550781 4.257813 11.03125 4.6875 C 11.503906 5.113281 11.886719 5.832031 12.21875 6.9375 L 20.21875 39.21875 C 20.519531 40.363281 20.820313 41.542969 21.65625 42.5 C 22.003906 42.898438 22.441406 43.222656 22.96875 43.46875 C 22.382813 44.164063 22 45.027344 22 46 C 22 48.199219 23.800781 50 26 50 C 28.199219 50 30 48.199219 30 46 C 30 45.265625 29.785156 44.59375 29.4375 44 L 35.5625 44 C 35.214844 44.59375 35 45.265625 35 46 C 35 48.199219 36.800781 50 39 50 C 41.199219 50 43 48.199219 43 46 C 43 44.972656 42.582031 44.054688 41.9375 43.34375 C 42.050781 43.039063 42.003906 42.695313 41.820313 42.429688 C 41.632813 42.160156 41.328125 42 41 42 L 25.71875 42 C 24.175781 42 23.546875 41.671875 23.125 41.1875 C 22.707031 40.707031 22.453125 39.867188 22.15625 38.75 L 22.15625 38.71875 L 21.46875 36 L 39.8125 36 C 40.230469 36 40.609375 35.738281 40.75 35.34375 L 47.9375 16.34375 C 48.054688 16.039063 48.011719 15.695313 47.824219 15.425781 C 47.636719 15.15625 47.328125 14.996094 47 15 L 16.28125 15 L 14.15625 6.46875 C 14.15625 6.449219 14.15625 6.425781 14.15625 6.40625 C 13.773438 5.117188 13.277344 4 12.375 3.1875 C 11.472656 2.375 10.203125 2 8.65625 2 L 2 2 C 1.96875 2 1.9375 2 1.90625 2 C 1.875 2 1.84375 2 1.8125 2 Z M 16.78125 17 L 45.5625 17 L 39.125 34 L 21 34 Z M 26 44 C 27.117188 44 28 44.882813 28 46 C 28 47.117188 27.117188 48 26 48 C 24.882813 48 24 47.117188 24 46 C 24 44.882813 24.882813 44 26 44 Z M 39 44 C 40.117188 44 41 44.882813 41 46 C 41 47.117188 40.117188 48 39 48 C 37.882813 48 37 47.117188 37 46 C 37 44.882813 37.882813 44 39 44 Z"
                  />
                </svg>
              </button>
              <button
                class="mr-1 mb-1 p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm"
                :title="p.description"
                v-if="p.code === 'DOC-SFU' && props.doc.length"
                aria-controls="basic-modal"
                @click.stop="basicModalOpen = true"
              >
                <svg
                  class="w-5 h-5 fill-current"
                  :class="configVars.primaryText"
                  viewBox="0 0 50 50"
                >
                  <path
                    d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z"
                  />
                </svg>
              </button>
            </template>
          </div>
        </div>
        <div class="flex mb-2">
          <div class="pr-1">
            <div>
              <span v-if="props.item.V_Destinatario.length"
                >{{
                  props.item.V_Destinatario[0].Via
                    ? props.item.V_Destinatario[0].Via
                    : props.item.V_Destinatario[0].via
                }}<br />{{
                  props.item.V_Destinatario[0].Comune
                    ? props.item.V_Destinatario[0].Comune
                    : props.item.V_Destinatario[0].comune
                }}
                ({{
                  props.item.V_Destinatario[0].Provincia
                    ? props.item.V_Destinatario[0].Provincia
                    : props.item.V_Destinatario[0].provincia
                }})</span
              >
              <template v-if="props.item.V_Destinatario.length">
                <template
                  v-if="
                    props.item.V_Destinatario[0].LatiGradi &&
                    props.item.V_Destinatario[0].LongGradi
                  "
                >
                  <a
                    :href="
                      'http://www.google.com/maps/place/' +
                      props.item.V_Destinatario[0].LatiGradi +
                      ',' +
                      props.item.V_Destinatario[0].LongGradi
                    "
                    target="_blank"
                  >
                    <button class="p-1.5 shrink-0">
                      <svg
                        class="w-4 h-4 fill-current"
                        :class="configVars.primaryText"
                        viewBox="0 0 50 50"
                      >
                        <path
                          d="M 25 1 C 16.160156 1 9 8.160156 9 17 C 9 24.308594 12.859375 32.164063 16.65625 38.25 C 20.453125 44.335938 24.25 48.65625 24.25 48.65625 C 24.441406 48.871094 24.714844 48.996094 25 48.996094 C 25.285156 48.996094 25.558594 48.871094 25.75 48.65625 C 25.75 48.65625 29.550781 44.246094 33.34375 38.125 C 37.136719 32.003906 41 24.167969 41 17 C 41 8.160156 33.839844 1 25 1 Z M 25 3 C 32.761719 3 39 9.238281 39 17 C 39 23.433594 35.363281 31.082031 31.65625 37.0625 C 28.550781 42.074219 25.921875 45.300781 25 46.40625 C 24.070313 45.308594 21.441406 42.152344 18.34375 37.1875 C 14.640625 31.25 11 23.589844 11 17 C 11 9.238281 17.238281 3 25 3 Z M 25 11 C 21.144531 11 18 14.144531 18 18 C 18 21.855469 21.144531 25 25 25 C 28.855469 25 32 21.855469 32 18 C 32 14.144531 28.855469 11 25 11 Z M 25 13 C 27.773438 13 30 15.226563 30 18 C 30 20.773438 27.773438 23 25 23 C 22.226563 23 20 20.773438 20 18 C 20 15.226563 22.226563 13 25 13 Z"
                        />
                      </svg>
                    </button>
                  </a>
                </template>
                <template
                  v-if="
                    props.item.V_Destinatario[0].latitudine &&
                    props.item.V_Destinatario[0].longitudine
                  "
                >
                  <a
                    :href="
                      'http://www.google.com/maps/place/' +
                      props.item.V_Destinatario[0].latitudine +
                      ',' +
                      props.item.V_Destinatario[0].longitudine
                    "
                    target="_blank"
                  >
                    <button class="p-1.5 shrink-0">
                      <svg
                        class="w-4 h-4 fill-current"
                        :class="configVars.primaryText"
                        viewBox="0 0 50 50"
                      >
                        <path
                          d="M 25 1 C 16.160156 1 9 8.160156 9 17 C 9 24.308594 12.859375 32.164063 16.65625 38.25 C 20.453125 44.335938 24.25 48.65625 24.25 48.65625 C 24.441406 48.871094 24.714844 48.996094 25 48.996094 C 25.285156 48.996094 25.558594 48.871094 25.75 48.65625 C 25.75 48.65625 29.550781 44.246094 33.34375 38.125 C 37.136719 32.003906 41 24.167969 41 17 C 41 8.160156 33.839844 1 25 1 Z M 25 3 C 32.761719 3 39 9.238281 39 17 C 39 23.433594 35.363281 31.082031 31.65625 37.0625 C 28.550781 42.074219 25.921875 45.300781 25 46.40625 C 24.070313 45.308594 21.441406 42.152344 18.34375 37.1875 C 14.640625 31.25 11 23.589844 11 17 C 11 9.238281 17.238281 3 25 3 Z M 25 11 C 21.144531 11 18 14.144531 18 18 C 18 21.855469 21.144531 25 25 25 C 28.855469 25 32 21.855469 32 18 C 32 14.144531 28.855469 11 25 11 Z M 25 13 C 27.773438 13 30 15.226563 30 18 C 30 20.773438 27.773438 23 25 23 C 22.226563 23 20 20.773438 20 18 C 20 15.226563 22.226563 13 25 13 Z"
                        />
                      </svg>
                    </button>
                  </a>
                </template>
              </template>
            </div>
          </div>
        </div>
        <!-- Bio -->
        <div class="mt-2">
          <div class="text-sm">Ultimo rifornimento:</div>
          <ul>
            <li class="flex items-center border-b border-slate-200 py-2">
              <div class="text-sm">
                Data:
                <strong>{{
                  $filters.formatDate(props.item.UltimoRifData)
                }}</strong>
              </div>
            </li>
            <li class="flex items-center border-b border-slate-200 py-2">
              <div class="text-sm">
                Litri:
                <strong>{{
                  new Intl.NumberFormat("it-IT", {
                    minimumFractionDigits: 2,
                  }).format(props.item.UltimoRifQta)
                }}</strong>
              </div>
            </li>
            <li class="flex items-center border-b border-slate-200 py-2">
              <div class="text-sm">
                Prezzo:
                <strong>{{
                  new Intl.NumberFormat("it-IT", {
                    minimumFractionDigits: 2,
                  }).format(props.item.UltimoRifPrezzo)
                }}</strong>
              </div>
            </li>
            <li
              class="flex items-center border-slate-200 py-2"
              v-if="props.item.V_Destinatario.length"
            >
              <div v-if="props.item.V_Destinatario[0]" class="text-sm">
                Attuali condizioni contrattuali:
                <strong>{{
                  props.item.V_Destinatario[0].condizioniPagamentoDescrizione
                }}</strong>
              </div>
            </li>
          </ul>
        </div>
        <template v-if="!props.item.DestinatarioV_OrdineSerbatoi.length">
          <div class="mt-2">
            <div class="text-sm font-bold">Nessun ordine da evadere</div>
          </div>
        </template>
        <template v-else>
          <div class="mt-2">
            <ul>
              <li class="flex items-center border-b border-slate-200 py-2">
                <div class="text-sm">
                  Data ultimo ordine:
                  <strong>{{
                    $filters.formatDate(
                      props.item.DestinatarioV_OrdineSerbatoi[0].Data,
                    )
                  }}</strong>
                </div>
              </li>
              <li class="flex items-center border-slate-200 py-2">
                <div class="text-sm">
                  Stato:
                  <strong>{{
                    props.item.DestinatarioV_OrdineSerbatoi[0].Stato.description
                  }}</strong>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
      <!-- Card footer -->
      <div
        class="border-t border-slate-200"
        v-if="props.item.V_serbatoi.length"
      >
        <div class="flex">
          <button
            class="block flex-1 text-center text-base font-medium px-3 py-4"
            :class="
              configVars.primaryText + ' hover:' + configVars.primaryTextHover
            "
            @click="dettagli(props.item.id)"
          >
            <div class="flex items-center justify-center">
              <span>Dettagli</span>
            </div>
          </button>
        </div>
      </div>
      <div
        class="border-t border-slate-200 hidden"
        :id="'dettagli_' + props.item.id"
        v-if="props.item.V_serbatoi.length"
      >
        <ul
          v-for="(rec, key) in props.item.V_serbatoi"
          :key="key"
          class="border-t border-slate-800"
        >
          <template v-if="params.length">
            <template v-for="p in params">
              <li
                v-if="p.code === 'INS-LIVELLO'"
                class="py-3 cursor-pointer flex justify-center border-b border-slate-200"
                aria-controls="livello-modal"
                @click="
                  formLivello.dbKeyLocatario = props.item.__dbKey;
                  formLivello.dbKeySerbatoio = rec.__dbKey;
                  capacita = rec.capacita;
                "
                @click.stop="livelloModalOpen = true"
              >
                <p class="mr-2">Comunica nuovo livello</p>
                <button
                  class="p-1.5 shrink-0 rounded border border-slate-200 hover:border-slate-300 shadow-sm"
                  :title="p.description"
                >
                  <svg
                    class="w-4 h-4 fill-current"
                    :class="configVars.primaryText"
                    viewBox="0 0 50 50"
                  >
                    <path
                      d="M 24.978516 1 A 1.0001 1.0001 0 0 0 24.230469 1.3632812 C 24.230469 1.3632812 20.195969 6.2333682 16.160156 12.455078 C 12.124343 18.676788 8 26.165016 8 31.998047 C 8 41.37629 15.621682 49 25 49 C 34.378318 49 42 41.377304 42 32 C 42 26.166516 37.875658 18.676917 33.839844 12.455078 C 29.804029 6.2332392 25.769531 1.3632812 25.769531 1.3632812 A 1.0001 1.0001 0 0 0 24.978516 1 z M 25 3.6191406 C 25.959993 4.806348 28.811624 8.3826362 32.160156 13.544922 C 36.124342 19.656333 40 27.166484 40 32 C 40 40.296696 33.297682 47 25 47 C 16.702318 47 10 40.295804 10 31.998047 C 10 27.165078 13.875657 19.656212 17.839844 13.544922 C 21.188377 8.3827385 24.040006 4.8063463 25 3.6191406 z M 13.984375 30.986328 A 1.0001 1.0001 0 0 0 13 32 C 13 37.134702 15.777111 40.264576 18.472656 41.939453 C 21.168201 43.61433 23.859375 43.990234 23.859375 43.990234 A 1.0001694 1.0001694 0 1 0 24.140625 42.009766 C 24.140625 42.009766 21.831799 41.672108 19.527344 40.240234 C 17.222889 38.808361 15 36.438298 15 32 A 1.0001 1.0001 0 0 0 13.984375 30.986328 z"
                    />
                  </svg>
                </button>
              </li>
            </template>
          </template>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200 bg-slate-100"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">Tipo</div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{ rec.tipologiaSerbatoiodescrizione }}</span>
            </div>
          </li>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">Matricola</div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{ rec.Matricola }}</span>
            </div>
          </li>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200 bg-slate-100"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">N. Fabbrica</div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{ rec.numeroFabbrica }}</span>
            </div>
          </li>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">Capacità</div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{ rec.capacita }}</span>
            </div>
          </li>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200 bg-slate-100"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">Costruttore</div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{ rec.CostruttoreCostruttore }}</span>
            </div>
          </li>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">
              Data installazione
            </div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{ $filters.formatDate(rec.DtInstal) }}</span>
            </div>
          </li>
          <li
            class="flex justify-between items-center py-3 border-b border-slate-200 bg-slate-100"
          >
            <div class="text-sm text-slate-800 font-bold ml-4">
              Ultimo intervento
            </div>
            <!-- Right -->
            <div class="text-sm text-slate-800">
              <span class="mr-4">{{
                $filters.formatDate(rec.DataUltimoIntervento)
              }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ModalBasicLarge
    id="prezzi-modal"
    :modalOpen="prezziModalOpen"
    @close-modal="prezziModalOpen = false"
    title="Il tuo prezzo attuale"
  >
    <!-- Modal content -->
    <div class="px-5 pb-1">
      <div class="text-sm">
        <div>
          <div class="mt-5" v-if="loadingPrezzi">
            <div
              class="px-4 py-2 rounded-sm text-sm border border-indigo-200 w-1/2"
              :class="configVars.secondaryText + ' ' + configVars.primary"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                    ></path>
                  </svg>
                  <div class="loading_course">Caricamento in corso</div>
                </div>
              </div>
            </div>
          </div>
          <DxDataGrid
            v-if="!loadingPrezzi"
            id="gridContainer"
            :data-source="itemsPrezzi"
            :column-auto-width="true"
            :show-borders="true"
            :column-hiding-enabled="false"
            noDataText="Nessun risultato"
            :row-alternation-enabled="true"
          >
            <DxToolbar>
              <DxItem name="searchPanel" />
            </DxToolbar>
            <DxLoadPanel :enabled="false" />
            <DxScrolling mode="infinite" />
            <DxSorting mode="single" />
            <DxPaging :enabled="false" />
            <DxColumnFixing :enabled="false" />
            <DxColumn
              header-cell-template="title-header"
              data-field="DesPag"
              caption="MODALIT&Agrave; DI PAGAMENTO"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="PrzNetto"
              caption="NETTO"
              cell-template="prezzo-template"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="PrezzoIva"
              caption="CON IVA"
              cell-template="prezzo-template"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="PrezzoCbtax"
              caption="CON RIDUZIONE<br>CLIMATICA"
              cell-template="prezzo-template"
            />
            <template #prezzo-template="{ data }">
              <span>{{
                new Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 5,
                }).format(data.value)
              }}</span>
            </template>
            <template #title-header="{ data }">
              <p
                style="font-size: 0.9rem; color: #536de6; font-weight: 700"
                v-html="data.column.caption"
              ></p>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="px-5 py-4">
      <div class="flex flex-wrap justify-end space-x-2">
        <button
          class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
          @click.stop="prezziModalOpen = false"
        >
          Chiudi
        </button>
      </div>
    </div>
  </ModalBasicLarge>
  <ModalBasicLarge
    id="sfuso-modal"
    :modalOpen="sfusoModalOpen"
    @close-modal="sfusoModalOpen = false"
    title="I tuoi prezzi storici"
  >
    <!-- Modal content -->
    <div class="p-6 space-y-8 bg-white">
      <!-- Input Types -->
      <div>
        <div class="grid gap-5 md:grid-cols-4 items-end">
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataDa"
                >Dal</label
              >
              <input
                id="dataDa"
                class="form-input w-full"
                type="date"
                v-model="formSfuso.dataDa"
                :min="props.fifthLimit"
                :max="props.fourthLimit"
              />
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataAl"
                >Al</label
              >
              <input
                id="dataAl"
                class="form-input w-full"
                v-model="formSfuso.dataA"
                type="date"
              />
            </div>
          </div>
          <div>
            <button
              class="btn text-white md:ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="sfusoWithDates()"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
              type="button"
            >
              Cerca
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-5 pb-1">
      <div class="text-sm">
        <div>
          <div class="mb-5" v-if="downloading">
            <Banner2 type="warning" :open="true">
              Attendi, download in corso...
            </Banner2>
          </div>
          <div class="mb-5" v-if="loadingSfuso">
            <div
              class="px-4 py-2 rounded-sm text-sm border border-indigo-200 w-1/2"
              :class="configVars.secondaryText + ' ' + configVars.primary"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                    ></path>
                  </svg>
                  <div class="loading_course">Caricamento in corso</div>
                </div>
              </div>
            </div>
          </div>
          <DxDataGrid
            v-if="!loadingSfuso"
            id="gridContainer"
            :data-source="itemsSfuso"
            :column-auto-width="true"
            :show-borders="true"
            :column-hiding-enabled="false"
            noDataText="Nessun risultato"
            :row-alternation-enabled="true"
          >
            <DxSearchPanel :visible="false" placeholder="Cerca..." />
            <DxToolbar>
              <DxItem name="searchPanel" />
            </DxToolbar>
            <DxLoadPanel :enabled="false" />
            <DxScrolling mode="infinite" />
            <DxSorting mode="single" />
            <DxPaging :enabled="false" />
            <DxColumnFixing :enabled="false" />
            <DxColumn
              header-cell-template="title-header"
              data-field="DaData"
              caption="DA"
              data-type="date"
              format="dd/MM/yyyy"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="AData"
              caption="A"
              data-type="date"
              format="dd/MM/yyyy"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="PrzNetto"
              caption="PREZZO NETTO"
              cell-template="prezzo-template"
            />
            <template #prezzo-template="{ data }">
              <span>{{
                new Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 2,
                }).format(data.value)
              }}</span>
            </template>
            <template #title-header="{ data }">
              <p
                style="font-size: 0.9rem; color: #536de6; font-weight: 700"
                v-html="data.column.caption"
              ></p>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="px-5 py-4">
      <div class="flex flex-wrap justify-end space-x-2">
        <button
          class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
          @click.stop="sfusoModalOpen = false"
        >
          Chiudi
        </button>
      </div>
    </div>
  </ModalBasicLarge>
  <ModalBasic
    id="livello-modal"
    :modalOpen="livelloModalOpen"
    @close-modal="livelloModalOpen = false"
    :title="
      'Livello attuale del serbatoio ' +
      new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2 }).format(
        calcLivello,
      ) +
      ' litri'
    "
  >
    <!-- Modal content -->
    <div class="p-6 space-y-8 mb-5 bg-white">
      <div class="w-full max-w-9xl mx-auto" v-if="livelloAdded">
        <div
          class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                ></path>
              </svg>
              <div>Livello acquisito correttamente</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="errorSys">
        <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                ></path>
              </svg>
              <div class="font-medium">{{ errorSys }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Input Types -->
      <div>
        <div class="grid gap-5 md:grid-cols-1 items-end">
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataLivello"
                >Data</label
              >
              <input
                id="dataLivello"
                class="form-input w-full"
                type="date"
                v-model="vvLivello.Data.$model"
              />
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="livello"
                >Livello (% sulla capacità max di
                {{
                  new Intl.NumberFormat("it-IT", {
                    minimumFractionDigits: 2,
                  }).format(capacita)
                }}
                litri)</label
              >
              <input
                id="livello"
                class="form-input w-full"
                type="number"
                v-model="vvLivello.Livello.$model"
                min="1"
                max="100"
                @keypress="restrictCharsOnly($event)"
                @change="modLivello"
                @keyup="modLivello"
              />
            </div>
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vvLivello.Livello.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="text-rose-500 text-sm">
            Livello attuale del serbatoio
            {{
              new Intl.NumberFormat("it-IT", {
                minimumFractionDigits: 2,
              }).format(calcLivello)
            }}
            litri
          </div>
          <div>
            <button
              class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="nuovoLivello()"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
              type="button"
              :disabled="vvLivello.$invalid"
            >
              Invia
            </button>
          </div>
        </div>
      </div>
    </div>
  </ModalBasic>
  <ModalBasic
    id="rifornimento-modal"
    :modalOpen="rifornimentoModalOpen"
    @close-modal="rifornimentoModalOpen = false"
    title="Richiedi rifornimento"
  >
    <!-- Modal content -->
    <div class="p-6 space-y-8 mb-5 bg-white">
      <div class="w-full max-w-9xl mx-auto" v-if="rifornimentoAdded">
        <div
          class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                ></path>
              </svg>
              <div>Rifornimento richiesto con successo</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="errorSys">
        <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                ></path>
              </svg>
              <div class="font-medium">{{ errorSys }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Input Types -->
      <div>
        <div class="grid gap-5 md:grid-cols-1 items-end">
          <div>
            <span class="font-semibold"
              >Inserire il livello O i litri O l'importo richiesto</span
            >
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="litri"
                >Litri (max.
                {{
                  new Intl.NumberFormat("it-IT", {
                    minimumFractionDigits: 0,
                  }).format(calcMaxRifornimento())
                }})</label
              >
              <input
                id="litri"
                class="form-input w-full"
                type="number"
                v-model="vvRifornimento.litri.$model"
                min="0"
                :max="calcMaxRifornimento()"
                @keypress="restrictCharsOnly($event)"
                @keyup="
                  formRifornimento.importo = '';
                  formRifornimento.livello = '';
                "
              />
            </div>
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vvRifornimento.litri.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="livelloRif"
                >Livello %</label
              >
              <input
                id="livelloRif"
                class="form-input w-full"
                type="number"
                v-model="vvRifornimento.livello.$model"
                min="0"
                max="100"
                @keypress="restrictCharsOnly($event)"
                @keyup="
                  formRifornimento.importo = '';
                  calcLitri();
                "
              />
            </div>
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vvRifornimento.livello.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="importo"
                >Importo €</label
              >
              <input
                id="importo"
                class="form-input w-full"
                type="number"
                v-model="vvRifornimento.importo.$model"
                min="0"
                @keypress="restrictCharsTot($event)"
                @keyup="
                  formRifornimento.litri = '';
                  formRifornimento.livello = '';
                "
              />
            </div>
            <div
              class="text-xs mt-1 text-rose-500"
              v-for="(error, index) of vvRifornimento.importo.$errors"
              :key="index"
            >
              {{ error.$message }}
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="note"
                >Note</label
              >
              <textarea
                id="note"
                class="form-input w-full"
                v-model="vvRifornimento.note.$model"
              ></textarea>
            </div>
          </div>
          <div>
            <button
              class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              :disabled="vvRifornimento.$invalid"
              @click="richiediRifornimento()"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
              type="button"
            >
              Invia ordine
            </button>
          </div>
        </div>
      </div>
    </div>
  </ModalBasic>
  <ModalBasicLarge
    id="storico-modal"
    :modalOpen="storicoModalOpen"
    @close-modal="storicoModalOpen = false"
    title="I tuoi acquisti"
  >
    <!-- Modal content -->
    <div class="p-6 space-y-8 bg-white">
      <!-- Input Types -->
      <div>
        <div class="grid gap-5 md:grid-cols-4 items-end">
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataDaStorico"
                >Dal</label
              >
              <input
                id="dataDaStorico"
                class="form-input w-full"
                type="date"
                v-model="formStorico.dataDa"
                :min="props.secondLimit"
                :max="props.firstLimit"
              />
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataAlStorico"
                >Al</label
              >
              <input
                id="dataAlStorico"
                class="form-input w-full"
                v-model="formStorico.dataA"
                type="date"
              />
            </div>
          </div>
          <div>
            <button
              class="btn text-white md:ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="storicoWithDates()"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
              type="button"
            >
              Cerca
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-5 pb-1">
      <div class="text-sm">
        <div>
          <div class="mb-5" v-if="downloading">
            <Banner2 type="warning" :open="true">
              Attendi, download in corso...
            </Banner2>
          </div>
          <div class="mb-5" v-if="loadingStorico">
            <div
              class="px-4 py-2 rounded-sm text-sm border border-indigo-200 w-1/2"
              :class="configVars.secondaryText + ' ' + configVars.primary"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                    ></path>
                  </svg>
                  <div class="loading_course">Caricamento in corso</div>
                </div>
              </div>
            </div>
          </div>
          <DxDataGrid
            v-if="!loadingStorico"
            id="gridContainer"
            :data-source="itemsStorico"
            :column-auto-width="true"
            :show-borders="true"
            :column-hiding-enabled="false"
            noDataText="Nessun risultato"
            :row-alternation-enabled="true"
          >
            <DxSearchPanel :visible="false" placeholder="Cerca..." />
            <DxToolbar>
              <DxItem name="searchPanel" />
            </DxToolbar>
            <DxLoadPanel :enabled="false" />
            <DxScrolling mode="infinite" />
            <DxSorting mode="single" />
            <DxPaging :enabled="false" />
            <DxColumnFixing :enabled="false" />
            <DxColumn
              header-cell-template="title-header"
              data-field="DataMovimento"
              caption="DATA"
              data-type="date"
              format="dd/MM/yyyy"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="name"
              caption="DOCUMENTO"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="TestataBolla/NumeroDoc"
              caption="NUMERO"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="QtaFat"
              caption="QUANTIT&Agrave;"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="Prezzo"
              caption="PREZZO NETTO"
              cell-template="prezzo-template"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="TestataBolla/TestataFattura/PdfDocumento"
              caption="PDF Fat."
              cell-template="fattura-cell"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="TestataBolla/PdfDocumento"
              caption="PDF Bolla"
              cell-template="bolla-cell"
            />
            <template #title-header="{ data }">
              <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
                {{ data.column.caption }}
              </p>
            </template>
            <template #fattura-cell="{ data }">
              <div>
                <template v-if="data.value">
                  <a
                    href="#"
                    @click.prevent="
                      downloadFileContent(
                        data.value.ref,
                        replaceField(data.data).TestataBollaNumeroDoc,
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 75.32 92.604"
                    >
                      <title>icon</title>
                      <g class="nc-icon-wrapper">
                        <g transform="translate(53.548 -183.975) scale(1.4843)">
                          <path
                            fill="#ff2116"
                            d="M-29.633 123.947c-3.552 0-6.443 2.894-6.443 6.446v49.498c0 3.551 2.891 6.445 6.443 6.445h37.85c3.552 0 6.443-2.893 6.443-6.445v-40.702s.102-1.191-.416-2.351a6.516 6.516 0 0 0-1.275-1.844 1.058 1.058 0 0 0-.006-.008l-9.39-9.21a1.058 1.058 0 0 0-.016-.016s-.802-.764-1.99-1.274c-1.4-.6-2.842-.537-2.842-.537l.021-.002z"
                            color="#000"
                            font-family="sans-serif"
                            overflow="visible"
                            paint-order="markers fill stroke"
                            style="
                              line-height: normal;
                              font-variant-ligatures: normal;
                              font-variant-position: normal;
                              font-variant-caps: normal;
                              font-variant-numeric: normal;
                              font-variant-alternates: normal;
                              font-feature-settings: normal;
                              text-indent: 0;
                              text-align: start;
                              text-decoration-line: none;
                              text-decoration-style: solid;
                              text-decoration-color: #000000;
                              text-transform: none;
                              text-orientation: mixed;
                              shape-padding: 0;
                              isolation: auto;
                              mix-blend-mode: normal;
                            "
                            white-space="normal"
                            solid-color="#000000"
                            solid-opacity="1"
                          ></path>
                          <path
                            fill="#f5f5f5"
                            d="M-29.633 126.064h28.38a1.058 1.058 0 0 0 .02 0s1.135.011 1.965.368a5.385 5.385 0 0 1 1.373.869l9.368 9.19s.564.595.838 1.208c.22.495.234 1.4.234 1.4a1.058 1.058 0 0 0-.002.046v40.746a4.294 4.294 0 0 1-4.326 4.328h-37.85a4.294 4.294 0 0 1-4.326-4.328v-49.498a4.294 4.294 0 0 1 4.326-4.328z"
                            color="#000"
                            font-family="sans-serif"
                            overflow="visible"
                            paint-order="markers fill stroke"
                            style="
                              line-height: normal;
                              font-variant-ligatures: normal;
                              font-variant-position: normal;
                              font-variant-caps: normal;
                              font-variant-numeric: normal;
                              font-variant-alternates: normal;
                              font-feature-settings: normal;
                              text-indent: 0;
                              text-align: start;
                              text-decoration-line: none;
                              text-decoration-style: solid;
                              text-decoration-color: #000000;
                              text-transform: none;
                              text-orientation: mixed;
                              shape-padding: 0;
                              isolation: auto;
                              mix-blend-mode: normal;
                            "
                            white-space="normal"
                            solid-color="#000000"
                            solid-opacity="1"
                          ></path>
                          <path
                            fill="#ff2116"
                            d="M-23.408 161.093c-1.456-1.457.12-3.458 4.397-5.584l2.691-1.337 1.049-2.294a90.314 90.314 0 0 0 1.912-4.575l.864-2.281-.595-1.688c-.732-2.075-.994-5.194-.529-6.316.63-1.519 2.69-1.363 3.506.265.638 1.272.572 3.575-.183 6.48l-.62 2.381.546.926c.3.51 1.177 1.719 1.948 2.687l1.449 1.803 1.803-.235c5.73-.748 7.692.523 7.692 2.345 0 2.299-4.499 2.489-8.277-.165-.85-.597-1.433-1.19-1.433-1.19s-2.367.482-3.532.796c-1.203.324-1.803.527-3.564 1.122 0 0-.619.898-1.021 1.55-1.499 2.428-3.249 4.44-4.498 5.173-1.4.82-2.866.875-3.605.137zm2.286-.817c.82-.506 2.477-2.466 3.624-4.285l.464-.737-2.115 1.064c-3.266 1.642-4.76 3.19-3.984 4.126.437.526.96.483 2.011-.168zm21.218-5.955c.801-.561.685-1.692-.22-2.148-.705-.354-1.273-.427-3.103-.4-1.125.076-2.934.303-3.24.372 0 0 .993.687 1.434.94.587.335 2.015.957 3.057 1.276 1.027.315 1.622.282 2.072-.04zm-8.531-3.546c-.485-.51-1.309-1.573-1.832-2.364-.683-.896-1.026-1.528-1.026-1.528s-.5 1.606-.91 2.573l-1.278 3.161-.37.717s1.97-.646 2.973-.908c1.062-.277 3.218-.701 3.218-.701zm-2.75-11.026c.124-1.038.177-2.074-.157-2.596-.924-1.01-2.04-.168-1.851 2.235.063.809.264 2.19.533 3.042l.488 1.549.343-1.167c.19-.641.48-2.02.645-3.063z"
                          ></path>
                          <path
                            fill="#2c2c2c"
                            d="M-20.93 167.839h2.365q1.133 0 1.84.217.706.21 1.19.944.482.728.482 1.756 0 .945-.392 1.624-.392.678-1.056.98-.658.3-2.03.3h-.818v3.73h-1.581zm1.58 1.224v3.33h.785q1.05 0 1.448-.391.406-.392.406-1.274 0-.657-.266-1.063-.266-.413-.588-.504-.315-.098-1-.098zm5.508-1.224h2.148q1.56 0 2.49.552.938.553 1.414 1.645.483 1.091.483 2.42 0 1.4-.434 2.499-.427 1.091-1.316 1.763-.881.672-2.518.672h-2.267zm1.58 1.266v7.018h.659q1.378 0 2-.952.623-.958.623-2.553 0-3.513-2.623-3.513zm6.473-1.266h5.304v1.266h-3.723v2.855h2.981v1.266h-2.98v4.164H-5.79z"
                            font-family="Franklin Gothic Medium Cond"
                            letter-spacing="0"
                            style="
                              line-height: 125%;
                              -inkscape-font-specification: &quot;Franklin Gothic Medium Cond&quot;;
                            "
                            word-spacing="4.26"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </template>
              </div>
            </template>
            <template #bolla-cell="{ data }">
              <div>
                <template v-if="data.value">
                  <a
                    href="#"
                    @click.prevent="
                      downloadFileContent(
                        data.value.ref,
                        replaceField(data.data)
                          .TestataBollaTipoDocumentoDescrizione,
                      )
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 75.32 92.604"
                    >
                      <title>icon</title>
                      <g class="nc-icon-wrapper">
                        <g transform="translate(53.548 -183.975) scale(1.4843)">
                          <path
                            fill="#ff2116"
                            d="M-29.633 123.947c-3.552 0-6.443 2.894-6.443 6.446v49.498c0 3.551 2.891 6.445 6.443 6.445h37.85c3.552 0 6.443-2.893 6.443-6.445v-40.702s.102-1.191-.416-2.351a6.516 6.516 0 0 0-1.275-1.844 1.058 1.058 0 0 0-.006-.008l-9.39-9.21a1.058 1.058 0 0 0-.016-.016s-.802-.764-1.99-1.274c-1.4-.6-2.842-.537-2.842-.537l.021-.002z"
                            color="#000"
                            font-family="sans-serif"
                            overflow="visible"
                            paint-order="markers fill stroke"
                            style="
                              line-height: normal;
                              font-variant-ligatures: normal;
                              font-variant-position: normal;
                              font-variant-caps: normal;
                              font-variant-numeric: normal;
                              font-variant-alternates: normal;
                              font-feature-settings: normal;
                              text-indent: 0;
                              text-align: start;
                              text-decoration-line: none;
                              text-decoration-style: solid;
                              text-decoration-color: #000000;
                              text-transform: none;
                              text-orientation: mixed;
                              shape-padding: 0;
                              isolation: auto;
                              mix-blend-mode: normal;
                            "
                            white-space="normal"
                            solid-color="#000000"
                            solid-opacity="1"
                          ></path>
                          <path
                            fill="#f5f5f5"
                            d="M-29.633 126.064h28.38a1.058 1.058 0 0 0 .02 0s1.135.011 1.965.368a5.385 5.385 0 0 1 1.373.869l9.368 9.19s.564.595.838 1.208c.22.495.234 1.4.234 1.4a1.058 1.058 0 0 0-.002.046v40.746a4.294 4.294 0 0 1-4.326 4.328h-37.85a4.294 4.294 0 0 1-4.326-4.328v-49.498a4.294 4.294 0 0 1 4.326-4.328z"
                            color="#000"
                            font-family="sans-serif"
                            overflow="visible"
                            paint-order="markers fill stroke"
                            style="
                              line-height: normal;
                              font-variant-ligatures: normal;
                              font-variant-position: normal;
                              font-variant-caps: normal;
                              font-variant-numeric: normal;
                              font-variant-alternates: normal;
                              font-feature-settings: normal;
                              text-indent: 0;
                              text-align: start;
                              text-decoration-line: none;
                              text-decoration-style: solid;
                              text-decoration-color: #000000;
                              text-transform: none;
                              text-orientation: mixed;
                              shape-padding: 0;
                              isolation: auto;
                              mix-blend-mode: normal;
                            "
                            white-space="normal"
                            solid-color="#000000"
                            solid-opacity="1"
                          ></path>
                          <path
                            fill="#ff2116"
                            d="M-23.408 161.093c-1.456-1.457.12-3.458 4.397-5.584l2.691-1.337 1.049-2.294a90.314 90.314 0 0 0 1.912-4.575l.864-2.281-.595-1.688c-.732-2.075-.994-5.194-.529-6.316.63-1.519 2.69-1.363 3.506.265.638 1.272.572 3.575-.183 6.48l-.62 2.381.546.926c.3.51 1.177 1.719 1.948 2.687l1.449 1.803 1.803-.235c5.73-.748 7.692.523 7.692 2.345 0 2.299-4.499 2.489-8.277-.165-.85-.597-1.433-1.19-1.433-1.19s-2.367.482-3.532.796c-1.203.324-1.803.527-3.564 1.122 0 0-.619.898-1.021 1.55-1.499 2.428-3.249 4.44-4.498 5.173-1.4.82-2.866.875-3.605.137zm2.286-.817c.82-.506 2.477-2.466 3.624-4.285l.464-.737-2.115 1.064c-3.266 1.642-4.76 3.19-3.984 4.126.437.526.96.483 2.011-.168zm21.218-5.955c.801-.561.685-1.692-.22-2.148-.705-.354-1.273-.427-3.103-.4-1.125.076-2.934.303-3.24.372 0 0 .993.687 1.434.94.587.335 2.015.957 3.057 1.276 1.027.315 1.622.282 2.072-.04zm-8.531-3.546c-.485-.51-1.309-1.573-1.832-2.364-.683-.896-1.026-1.528-1.026-1.528s-.5 1.606-.91 2.573l-1.278 3.161-.37.717s1.97-.646 2.973-.908c1.062-.277 3.218-.701 3.218-.701zm-2.75-11.026c.124-1.038.177-2.074-.157-2.596-.924-1.01-2.04-.168-1.851 2.235.063.809.264 2.19.533 3.042l.488 1.549.343-1.167c.19-.641.48-2.02.645-3.063z"
                          ></path>
                          <path
                            fill="#2c2c2c"
                            d="M-20.93 167.839h2.365q1.133 0 1.84.217.706.21 1.19.944.482.728.482 1.756 0 .945-.392 1.624-.392.678-1.056.98-.658.3-2.03.3h-.818v3.73h-1.581zm1.58 1.224v3.33h.785q1.05 0 1.448-.391.406-.392.406-1.274 0-.657-.266-1.063-.266-.413-.588-.504-.315-.098-1-.098zm5.508-1.224h2.148q1.56 0 2.49.552.938.553 1.414 1.645.483 1.091.483 2.42 0 1.4-.434 2.499-.427 1.091-1.316 1.763-.881.672-2.518.672h-2.267zm1.58 1.266v7.018h.659q1.378 0 2-.952.623-.958.623-2.553 0-3.513-2.623-3.513zm6.473-1.266h5.304v1.266h-3.723v2.855h2.981v1.266h-2.98v4.164H-5.79z"
                            font-family="Franklin Gothic Medium Cond"
                            letter-spacing="0"
                            style="
                              line-height: 125%;
                              -inkscape-font-specification: &quot;Franklin Gothic Medium Cond&quot;;
                            "
                            word-spacing="4.26"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </a>
                </template>
              </div>
            </template>
            <template #prezzo-template="{ data }">
              <span>{{
                new Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 3,
                }).format(data.value)
              }}</span>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="px-5 py-4">
      <div class="flex flex-wrap justify-end space-x-2">
        <button
          class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
          @click.stop="storicoModalOpen = false"
        >
          Chiudi
        </button>
      </div>
    </div>
  </ModalBasicLarge>
  <ModalBasic
    id="basic-modal"
    :modalOpen="basicModalOpen"
    @close-modal="basicModalOpen = false"
    title="Documenti"
  >
    <!-- Modal content -->
    <div class="px-5 pt-4 pb-1">
      <div class="text-sm">
        <div>
          <DxDataGrid
            id="gridContainer"
            :data-source="props.doc"
            :column-auto-width="true"
            :show-borders="true"
            :column-hiding-enabled="false"
            noDataText="Nessun risultato"
            :row-alternation-enabled="true"
          >
            <DxSearchPanel :visible="false" placeholder="Cerca..." />
            <DxToolbar>
              <DxItem name="searchPanel" />
            </DxToolbar>
            <DxLoadPanel :enabled="false" />
            <DxScrolling mode="infinite" />
            <DxSorting mode="single" />
            <DxPaging :enabled="false" />
            <DxColumn
              header-cell-template="title-header"
              data-field="file"
              caption="FILE"
              cell-template="file-cell"
            />
            <DxColumn
              header-cell-template="title-header"
              data-field="name"
              caption="NOME"
            />
            <template #file-cell="{ data }">
              <div>
                <template v-if="data.value"
                  ><a :href="data.value" target="_blank">
                    <svg viewBox="0 0 30 30" class="w-8 h-8 fill-current">
                      <path
                        d="M 4 3 C 2.895 3 2 3.895 2 5 L 2 8 L 2 23 C 2 24.105 2.895 25 4 25 L 26 25 C 27.105 25 28 24.105 28 23 L 28 7 C 28 5.895 27.105 5 26 5 L 11.199219 5 L 10.582031 3.9707031 C 10.221031 3.3687031 9.5701875 3 8.8671875 3 L 4 3 z M 15 9 C 15.553 9 16 9.448 16 10 L 16 17.585938 L 18.292969 15.292969 C 18.683969 14.901969 19.316031 14.901969 19.707031 15.292969 C 20.098031 15.683969 20.098031 16.316031 19.707031 16.707031 L 15.707031 20.707031 C 15.512031 20.902031 15.256 21 15 21 C 14.744 21 14.487969 20.902031 14.292969 20.707031 L 10.292969 16.707031 C 9.9019687 16.316031 9.9019688 15.683969 10.292969 15.292969 C 10.683969 14.901969 11.316031 14.901969 11.707031 15.292969 L 14 17.585938 L 14 10 C 14 9.448 14.447 9 15 9 z"
                      />
                    </svg> </a
                ></template>
              </div>
            </template>
            <template #title-header="{ data }">
              <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
                {{ data.column.caption }}
              </p>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="px-5 py-4">
      <div class="flex flex-wrap justify-end space-x-2">
        <button
          class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
          @click.stop="basicModalOpen = false"
        >
          Chiudi
        </button>
      </div>
    </div>
  </ModalBasic>
</template>
