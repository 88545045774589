import GenericModel from "./generic";

export function ContattiModelRules() {
  const fields = [
    new GenericModel(
      "__dbKey",
      "__dbKey",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "__recordDescription",
      "__recordDescription",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "idContatto",
      "idContatto",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "soggettoRagioneSociale",
      "Ragione sociale",
      true,
      true,
      false,
      "String",
      false,
      1,
      "",
    ),
    new GenericModel(
        "nome",
        "Nome",
        true,
        true,
        false,
        "String",
        false,
        2, ""
    ),
    new GenericModel(
      "cognome",
      "Cognome",
      true,
      true,
      false,
      "String",
      false,
      3,
      "",
    ),
    new GenericModel(
      "titolo",
      "Titolo",
        true,
      true,
      false,
      "Object",
      false,
      4,
      "",
    ),
    new GenericModel(
      "ruoloDescrizione",
      "Ruolo",
      true,
      true,
      false,
      "String",
      false,
      5,
      "",
    ),
    new GenericModel(
      "sedeDescrizione",
      "Descrizione",
      true,
      true,
      false,
      "String",
      false,
      6,
      "",
    ),
    new GenericModel(
      "sedeIndirizzo",
      "Indirizzo",
      false,
      true,
      false,
      "String",
      false,
      7,
      "",
    ),
    new GenericModel(
      "sedeNumeroCivico",
      "Numero civico",
      false,
      false,
      true,
      "String",
      false,
      8,
      "",
    ),
    new GenericModel(
      "sedeLocalita",
      "Località",
      false,
      false,
      true,
      "String",
      false,
      9,
      "",
    ),
    new GenericModel(
      "sedeComune",
      "Comune",
      false,
      false,
      true,
      "String",
      false,
      10,
      "",
    ),
    new GenericModel(
      "sedeCAP",
      "Cap",
      false,
      false,
      true,
      "String",
      false,
      11,
      "",
    ),
    new GenericModel(
      "sedeProvincia",
      "Provincia",
      false,
      false,
      true,
      "String",
      false,
      12,
      "",
    ),
    new GenericModel(
      "sedeStato",
      "Stato",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
  ];

  return {
    fields,
  };
}
