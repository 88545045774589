<script setup>
import FormFile from "../../../components/amm/FormFile.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { onBeforeMount, ref } from "vue";

const router = useRouter();
const route = useRoute();
const store = useStore();
const itemAdded = ref(false);
const errorSys = ref(false);

const editItem = (postFromForm) => {
  postFromForm.id = route.params.id;
  store
    .dispatch("amm/editFile", postFromForm)
    .then(() => {
      itemAdded.value = true;
      setTimeout(() => {
        router.push("/AMMINISTRAZIONE/GEST-FILE");
      }, 3000);
    })
    .catch((e) => {
      errorSys.value = e.response.data.message;
    });
};

const item = ref(false);

onBeforeMount(() => {
  store
    .dispatch("amm/fetchFile", route.params.id)
    .then((res) => {
      item.value = res.data.file;
    })
    .catch((e) => (errorSys.value = e.response.data.message));
});
</script>
<template>
  <div
    class="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto"
    v-if="itemAdded"
  >
    <div
      class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
    >
      <div class="flex w-full justify-between items-start">
        <div class="flex">
          <svg
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
            ></path>
          </svg>
          <div>File modificato con successo</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto"
    v-if="errorSys"
  >
    <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
      <div class="flex w-full justify-between items-start">
        <div class="flex">
          <svg
            class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
            ></path>
          </svg>
          <div class="font-medium">{{ errorSys }}</div>
        </div>
      </div>
    </div>
  </div>
  <FormFile
    @save="editItem"
    v-if="item && !itemAdded"
    :itemData="item"
  ></FormFile>
</template>
