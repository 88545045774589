import GenericModel from "./generic";

export function PrezziScontiModelRules() {
  const fields = [
    new GenericModel(
      "__dbKey",
      "__dbKey",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "__recordDescription",
      "__recordDescription",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "idPrzsco",
      "idPrzsco",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "cliente",
      "Cliente",
      true,
      true,
      false,
      "String",
      false,
      1,
      "",
    ),
    new GenericModel(
        "stato",
        "Stato",
        true,
        true,
        false,
        "Object",
        false,
        2, ""
    ),
    new GenericModel(
        "tipologia",
        "Tipologia",
        true,
        true,
        false,
        "Object",
        false,
        3, ""
    ),
    new GenericModel(
      "dataInizio",
      "Data inizio",
      false,
      true,
      false,
      "Date",
      false,
      4,
      "Dettagli prezzo/sconto",
    ),
    new GenericModel(
      "dataFine",
      "Data fine",
      false,
      true,
      false,
      "Date",
      false,
      5,
      "",
    ),
    new GenericModel(
      "relativoA",
      "Relativo a",
      false,
      true,
      false,
      "Object",
      false,
      6,
      "",
    ),
    new GenericModel(
      "prodottoDescrizione",
      "Descrizione prodotto",
      false,
      true,
      false,
      "String",
      false,
      7,
      "",
    ),
    new GenericModel(
      "inseritoDaNome",
      "Inserito da",
      false,
      true,
      false,
      "String",
      false,
      8,
      "",
    ),
    new GenericModel(
      "applicazione",
      "Applicazione",
      false,
      true,
      true,
      "Object",
      false,
      9,
      "Applicazione",
    ),
    new GenericModel(
      "unitaDiMisura",
      "Unità di misura",
      false,
      true,
      true,
      "Object",
      false,
      10,
      "",
    ),
    new GenericModel(
      "accisa",
      "Accisa",
      false,
      true,
      true,
      "Object",
      false,
      11,
      "",
    ),
    new GenericModel(
      "scaglioni",
      "Scaglioni",
      false,
      true,
      true,
      "Object",
      false,
      12,
      "",
    ),
    new GenericModel(
      "imp1",
      "Importo 1",
      false,
      true,
      true,
      "Number",
      "format-currency",
      13,
      "",
    ),
    new GenericModel(
        "imp2",
        "Importo 2",
        false,
        true,
        true,
        "Number",
        "format-currency",
        14,
        "",
    ),
    new GenericModel(
        "imp3",
        "Importo 3",
        false,
        true,
        true,
        "Number",
        "format-currency",
        15,
        "",
    ),
    new GenericModel(
      "note",
      "Note",
      false,
        true,
      false,
      "String",
      false,
      16,
      "",
    ),
    new GenericModel(
        "tipoSconto",
        "Tipo sconto",
        false,
        true,
        false,
        "Object",
        false,
        17,
        "Condizioni",
    ),
    new GenericModel(
        "scontiAggiuntivi",
        "Sconti aggiuntivi",
        false,
        true,
        false,
        "Object",
        false,
        18,
        "",
    ),
    new GenericModel(
        "promozione",
        "Promozione",
        false,
        true,
        false,
        "Object",
        false,
        19,
        "",
    ),
  ];

  return {
    fields,
  };
}
