import GenericModel from "./generic";

export function NoteCreditoModelRules() {
  const fields = [
    new GenericModel(
      "__dbKey",
      "__dbKey",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "__recordDescription",
      "__recordDescription",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "idNcautdet",
      "idNcautdet",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "dataRichiesta",
      "Data richiesta",
      true,
      true,
      false,
      "Date",
      false,
      1,
      "Fattura",
    ),
    new GenericModel(
      "clienteCodiceCliente",
      "Codice cliente",
      true,
      true,
      false,
      "String",
      false,
      2,
      "",
    ),
    new GenericModel(
      "clienteRagioneSociale",
      "Ragione sociale",
      true,
      true,
      false,
      "String",
      false,
      3,
      "",
    ),
    new GenericModel(
      "testataFatturaNumeroFattura",
      "Numero fattura",
      true,
      true,
      false,
      "Number",
      "numero-fattura",
      4,
      "",
    ),
    new GenericModel(
      "testataFatturaRegistroSezionale",
      "Titolo",
      false,
      false,
      false,
      "String",
      false,
      5,
      "",
    ),
    new GenericModel(
      "testataFatturaDataFattura",
      "Data fattura",
      true,
      true,
      false,
      "Date",
      false,
      6,
      "",
    ),
    new GenericModel(
      "nuovoImporto",
      "Richiesta nota di credito",
      true,
      false,
      false,
      "Number",
      "format-currency",
      7,
      "",
    ),
    new GenericModel(
      "fatturaDiRiferimento",
      "Allegato",
      false,
      true,
      false,
      "File",
      "blob",
      8,
    ),
    new GenericModel(
      "totaleDocumento",
      "Totale fattura",
      false,
      true,
      false,
      "Number",
      "format-currency",
      9,
      "Documento",
    ),
    new GenericModel(
      "quantitaFatturata",
      "Quantità",
      false,
      true,
      false,
      "Number",
      false,
      10,
      "",
    ),
    new GenericModel(
      "prezzo",
      "Prezzo fattura",
      false,
      true,
      false,
      "Number",
      "format-currency",
      11,
      "",
    ),
    new GenericModel(
      "percDiRiaccredito",
      "Percentuale di accredito",
      false,
      true,
      false,
      "String",
      false,
      12,
      "Richiesta",
    ),
    new GenericModel(
      "nuovoPrezzoNetto",
      "Nuovo prezzo",
      false,
      true,
      false,
      "Number",
      "format-currency",
      13,
      "",
    ),
    new GenericModel(
      "nuovoImporto",
      "Nuovo importo",
      false,
      true,
      false,
      "Number",
      "format-currency",
      14,
      "",
    ),
    new GenericModel(
      "nuovoValoreDiRiaccredito",
      "Nuovo prezzo",
      false,
      true,
      false,
      "Number",
      "format-currency",
      15,
      "",
    ),
    new GenericModel(
      "causale",
      "Causale",
      false,
      true,
      false,
      "String",
      false,
      16,
      "",
    ),
    new GenericModel(
      "note",
      "Note",
      false,
      true,
      false,
      "String",
      false,
      17,
      "",
    ),
  ];

  return {
    fields,
  };
}
