<script setup>
import { computed, reactive, ref, toRef, toRefs } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { parseError } from "../composables/parseError";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required, sameAs } from "@vuelidate/validators";

const store = useStore();
const route = useRoute();
const { errorSys, calcError } = parseError();

const form = reactive({
  username: "",
  newpwd: "",
  oldpwd: "",
  passwordConfirm: "",
});

const router = useRouter();
const passwordSent = ref(false);

const rules = {
  username: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  newpwd: {
    minLengthValue: helpers.withMessage(
      "Lunghezza minima 6 caratteri",
      minLength(6),
    ),
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  oldpwd: {
    required: helpers.withMessage("Campo obbligatorio", required),
  },
  passwordConfirm: {
    minLengthValue: helpers.withMessage(
      "Lunghezza minima 6 caratteri",
      minLength(6),
    ),
    required: helpers.withMessage("Campo obbligatorio", required),
    sameAsRef: helpers.withMessage(
      'Conferma password deve essere uguale a "Nuova password"',
      sameAs(toRef(form, "newpwd")),
    ),
  },
};
const vv = useVuelidate(rules, { ...toRefs(form) });

const submit = () => {
  store
    .dispatch("user/changePassword", form)
    .then(() => {
      passwordSent.value = true;
      errorSys.value = false;

      if (route.query.from) {
        setTimeout(() => {
          if (route.query.from === "loginCliente") {
            store
              .dispatch("user/loginCliente", {
                username: form.username,
                password: form.newpwd,
              })
              .then((u) => {
                store.dispatch("menu/fetchMenu").then(() => {
                  const altrePosizioniContabili = computed(() =>
                    store.getters["menu/getMainMenu"]
                      .filter((c) => c.code === "GRP-PROFILO")[0]
                      .childs.filter(
                        (child) => child.code === "CHG-POS_CONTABILE",
                      ),
                  );

                  if (altrePosizioniContabili.value.length) {
                    const formToPass = {
                      RagioneSociale: "",
                      Cliente: "",
                      Indirizzo: "",
                      Cap: "",
                      Comune: "",
                      Provincia: "",
                      CodiceFiscale:
                        u.data.apiResult.DatiUtenza[0].CodiceFiscale,
                      PartitaIva: "",
                      Qualifica: "",
                      MacroStato: "",
                      Agente: "",
                      CategoriaSconto: "",
                      StatoCliente: "",
                    };

                    if (!u.data.apiResult.DatiUtenza[0].CodiceFiscale) {
                      store.commit("GRPOPERS/setClientiDropdown", []);
                    } else {
                      store
                        .dispatch("GRPOPERS/fetchClientiDropDown", formToPass)
                        .then(() => {
                          router.push("/dashboard");
                        })
                        .catch((e) => {
                          calcError(e);
                        });
                    }
                  } else {
                    router.push("/dashboard");
                  }
                });
              })
              .catch((e) => {
                passwordSent.value = false;
                calcError(e);
              });
          } else {
            store
              .dispatch("user/loginOperatore", {
                username: form.username,
                password: form.newpwd,
              })
              .then(() => {
                store.dispatch("menu/fetchMenu").then(() => {
                  router.push("/dashboard");
                });
              })
              .catch((e) => {
                passwordSent.value = false;
                calcError(e);
              });
          }
        }, 3000);
      }
    })
    .catch((e) => {
      passwordSent.value = false;
      calcError(e);
    });
};

const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>

<template>
  <main class="bg-white">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-screen h-full flex flex-col after:flex-1">
          <div class="flex-1">
            <div
              class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"
            >
              <!-- Logo -->
            </div>
          </div>

          <div class="max-w-sm mx-auto px-4 py-8">
            <img
              class="object-cover"
              :src="'/data/' + configVars.licenseKey + '/' + configVars.logo"
            />
            <div class="h-px bg-slate-200 mt-8 mb-6 w-full"></div>
            <h1 class="text-3xl text-slate-800 font-bold mb-6">
              Inserire una nuova password
            </h1>
            <div
              class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600 mb-4"
              v-if="passwordSent"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
                    ></path>
                  </svg>
                  <div>
                    Password modificata con successo, adesso sarai reindirizzato
                    alla dashboard
                  </div>
                </div>
              </div>
            </div>
            <div
              class="inline-flex min-w-80 px-4 py-2 rounded-sm text-sm text-white bg-rose-500 mb-4"
              v-if="errorSys"
            >
              <div class="flex w-full justify-between items-start">
                <div class="flex">
                  <svg
                    class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
                    ></path>
                  </svg>
                  <div class="font-medium">{{ errorSys }}</div>
                </div>
              </div>
            </div>
            <!-- Form -->
            <form @submit.prevent="submit" novalidate>
              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium mb-1" for="username"
                    >Username <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="username"
                    v-model="vv.username.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.username.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="oldpwd"
                    >Password attuale
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="oldpwd"
                    v-model="vv.oldpwd.$model"
                    class="form-input w-full"
                    type="password"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.oldpwd.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="newpwd"
                    >Nuova password <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="newpwd"
                    v-model="vv.newpwd.$model"
                    class="form-input w-full"
                    type="password"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.newpwd.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="passwordConfirm"
                    >Conferma password
                    <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="passwordConfirm"
                    v-model="vv.passwordConfirm.$model"
                    class="form-input w-full"
                    type="password"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.passwordConfirm.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
              </div>
              <div class="flex items-center justify-between mt-6">
                <div class="mr-1">
                  <router-link
                    class="text-sm underline hover:no-underline"
                    :to="route.query.from ? route.query.from : '/loginCliente'"
                    >Login</router-link
                  >
                </div>
                <button
                  type="submit"
                  class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                  :class="
                    configVars.primary + ' hover:' + configVars.primaryHover
                  "
                  :disabled="vv.$invalid"
                >
                  Modifica la password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Image -->
      <div
        class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
        aria-hidden="true"
      >
        <img
          class="object-cover object-center w-full h-full"
          :src="'/data/' + configVars.licenseKey + '/' + configVars.bgImage"
          width="760"
          height="1024"
          alt="Authentication"
        />
      </div>
    </div>
  </main>
</template>
