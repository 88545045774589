<script setup>
import { computed, onMounted, reactive, ref, toRef } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
import { datesLimit } from "../../composables/datesLimit";
import { parseError } from "../../composables/parseError";
import FileSaver from "file-saver";
import Banner2 from "../../components/Banner2.vue";
const { today, firstLimit, secondLimit, thirdLimit, datesCalc } = datesLimit();
const { errorSys, calcError } = parseError();
const props = defineProps(["title"]);
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
const loaded = ref(false);
const downloading = ref(false);
const items = computed(() => store.getters["GRPFATTURE/getVisFatture"]);
const user = computed(() => store.getters["user/getUser"]);
const params = computed(() =>
  store.getters["menu/getMainMenu"]
    .filter((c) => c.code === "GRP-FATTURE")[0]
    .childs.filter((child) => child.code === "VIS-FATTURE")[0]
    .params.split(";"),
);

const total = ref(0);

const totale = (data) => {
  total.value = data.value;
  return "Totale: " + data.value;
};

onMounted(() => {
  datesCalc(params.value);
  if (user.value.DatiUtenza[0].Cliente_dbKey != "") {
    store
      .dispatch("GRPFATTURE/fetchVisFatture", {
        IdWebPwd: user.value.IdWebPwd,
        Cliente_dbKey: user.value.DatiUtenza[0].Cliente_dbKey,
        daData: firstLimit.value,
        aData: today.value,
      })
      .then(() => {
        if (!items.value.length) {
          errorSys.value = "Nessun elemento trovato";
        }
        loaded.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  }
});

const form = reactive({
  dataDa: thirdLimit,
  dataA: today,
});

const fattureWithDates = () => {
  loaded.value = false;

  store
    .dispatch("GRPFATTURE/fetchVisFatture", {
      IdWebPwd: user.value.IdWebPwd,
      Cliente_dbKey: user.value.DatiUtenza[0].Cliente_dbKey,
      daData: form.dataDa,
      aData: form.dataA,
    })
    .then(() => {
      if (!items.value.length) {
        errorSys.value = "Nessun elemento trovato";
      }
      loaded.value = true;
    })
    .catch((e) => {
      calcError(e);
    });
};

const configVars = computed(() => store.getters["menu/getConfigVars"]);

if (user.value.DatiUtenza[0].Cliente_dbKey == "") {
  errorSys.value =
    "Deve essere selezionato un cliente prima di procedere alla ricerca";
}

const downloadFileContent = (ref, filename) => {
  downloading.value = true;

  store
    .dispatch("amm/downloadBlobFile", ref)
    .then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      FileSaver.saveAs(blob, filename);

      downloading.value = false;
    })
    .catch(() => (downloading.value = false));
};
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-7xl mx-auto">
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8">
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ props.title }}
        </h1>
      </div>
    </div>
    <div class="mb-5" v-if="!loaded && !errorSys">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-6 space-y-8 mt-8 mb-5 bg-white" v-if="loaded">
      <!-- Input Types -->
      <div>
        <div class="grid gap-5 md:grid-cols-4 items-end">
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataDa"
                >Dal</label
              >
              <input
                id="dataDa"
                class="form-input w-full"
                type="date"
                v-model="form.dataDa"
                :min="secondLimit"
                :max="firstLimit"
              />
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataAl"
                >Al</label
              >
              <input
                id="dataAl"
                class="form-input w-full"
                v-model="form.dataA"
                type="date"
              />
            </div>
          </div>
          <div>
            <button
              class="btn text-white md:ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="fattureWithDates"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
              type="button"
            >
              Cerca
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5" v-if="downloading">
      <Banner2 type="warning" :open="true">
        Attendi, download in corso...
      </Banner2>
    </div>
    <div class="py-4 bg-white" v-if="loaded && !errorSys">
      <DxDataGrid
        id="gridContainer"
        :data-source="items"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        noDataText="Nessun risultato"
      >
        <DxScrolling
          :use-native="false"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          show-scrollbar="onHover"
          mode="infinite"
        />
        <DxSearchPanel :visible="true" placeholder="Cerca..." />
        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
        <DxLoadPanel :enabled="false" />
        <DxSorting mode="single" />
        <DxPaging :enabled="false" />
        <DxColumnFixing :enabled="true" />
        <DxColumn
          header-cell-template="title-header"
          data-field="DataFat"
          data-type="date"
          format="dd/MM/yyyy"
          caption="DATA"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="NumeroFattura"
          caption="NUMERO"
          alignment="left"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="TipoVendita.description"
          caption="TIPOLOGIA VENDITA"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="TotaleDoc"
          caption="IMPORTO €"
          data-type="number"
          alignment="right"
          cell-template="number-cell"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="DesPagamento"
          caption="TIPO PAGAMENTO"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="PrimaScadenza"
          data-type="date"
          format="dd/MM/yyyy"
          caption="SCADENZA ORIGINALE"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="PdfDocumento"
          caption="FILE"
          alignment="right"
          cell-template="fattura-cell"
        />
        <template #fattura-cell="{ data }">
          <div class="float-right">
            <template v-if="data.value">
              <a
                href="#"
                @click.prevent="
                  downloadFileContent(
                    data.data.PdfDocumento.ref,
                    data.data.PdfDocumento.filename,
                  )
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="24"
                  viewBox="0 0 75.32 92.604"
                >
                  <title>icon</title>
                  <g class="nc-icon-wrapper">
                    <g transform="translate(53.548 -183.975) scale(1.4843)">
                      <path
                        fill="#ff2116"
                        d="M-29.633 123.947c-3.552 0-6.443 2.894-6.443 6.446v49.498c0 3.551 2.891 6.445 6.443 6.445h37.85c3.552 0 6.443-2.893 6.443-6.445v-40.702s.102-1.191-.416-2.351a6.516 6.516 0 0 0-1.275-1.844 1.058 1.058 0 0 0-.006-.008l-9.39-9.21a1.058 1.058 0 0 0-.016-.016s-.802-.764-1.99-1.274c-1.4-.6-2.842-.537-2.842-.537l.021-.002z"
                        color="#000"
                        font-family="sans-serif"
                        overflow="visible"
                        paint-order="markers fill stroke"
                        style="
                          line-height: normal;
                          font-variant-ligatures: normal;
                          font-variant-position: normal;
                          font-variant-caps: normal;
                          font-variant-numeric: normal;
                          font-variant-alternates: normal;
                          font-feature-settings: normal;
                          text-indent: 0;
                          text-align: start;
                          text-decoration-line: none;
                          text-decoration-style: solid;
                          text-decoration-color: #000000;
                          text-transform: none;
                          text-orientation: mixed;
                          shape-padding: 0;
                          isolation: auto;
                          mix-blend-mode: normal;
                        "
                        white-space="normal"
                        solid-color="#000000"
                        solid-opacity="1"
                      ></path>
                      <path
                        fill="#f5f5f5"
                        d="M-29.633 126.064h28.38a1.058 1.058 0 0 0 .02 0s1.135.011 1.965.368a5.385 5.385 0 0 1 1.373.869l9.368 9.19s.564.595.838 1.208c.22.495.234 1.4.234 1.4a1.058 1.058 0 0 0-.002.046v40.746a4.294 4.294 0 0 1-4.326 4.328h-37.85a4.294 4.294 0 0 1-4.326-4.328v-49.498a4.294 4.294 0 0 1 4.326-4.328z"
                        color="#000"
                        font-family="sans-serif"
                        overflow="visible"
                        paint-order="markers fill stroke"
                        style="
                          line-height: normal;
                          font-variant-ligatures: normal;
                          font-variant-position: normal;
                          font-variant-caps: normal;
                          font-variant-numeric: normal;
                          font-variant-alternates: normal;
                          font-feature-settings: normal;
                          text-indent: 0;
                          text-align: start;
                          text-decoration-line: none;
                          text-decoration-style: solid;
                          text-decoration-color: #000000;
                          text-transform: none;
                          text-orientation: mixed;
                          shape-padding: 0;
                          isolation: auto;
                          mix-blend-mode: normal;
                        "
                        white-space="normal"
                        solid-color="#000000"
                        solid-opacity="1"
                      ></path>
                      <path
                        fill="#ff2116"
                        d="M-23.408 161.093c-1.456-1.457.12-3.458 4.397-5.584l2.691-1.337 1.049-2.294a90.314 90.314 0 0 0 1.912-4.575l.864-2.281-.595-1.688c-.732-2.075-.994-5.194-.529-6.316.63-1.519 2.69-1.363 3.506.265.638 1.272.572 3.575-.183 6.48l-.62 2.381.546.926c.3.51 1.177 1.719 1.948 2.687l1.449 1.803 1.803-.235c5.73-.748 7.692.523 7.692 2.345 0 2.299-4.499 2.489-8.277-.165-.85-.597-1.433-1.19-1.433-1.19s-2.367.482-3.532.796c-1.203.324-1.803.527-3.564 1.122 0 0-.619.898-1.021 1.55-1.499 2.428-3.249 4.44-4.498 5.173-1.4.82-2.866.875-3.605.137zm2.286-.817c.82-.506 2.477-2.466 3.624-4.285l.464-.737-2.115 1.064c-3.266 1.642-4.76 3.19-3.984 4.126.437.526.96.483 2.011-.168zm21.218-5.955c.801-.561.685-1.692-.22-2.148-.705-.354-1.273-.427-3.103-.4-1.125.076-2.934.303-3.24.372 0 0 .993.687 1.434.94.587.335 2.015.957 3.057 1.276 1.027.315 1.622.282 2.072-.04zm-8.531-3.546c-.485-.51-1.309-1.573-1.832-2.364-.683-.896-1.026-1.528-1.026-1.528s-.5 1.606-.91 2.573l-1.278 3.161-.37.717s1.97-.646 2.973-.908c1.062-.277 3.218-.701 3.218-.701zm-2.75-11.026c.124-1.038.177-2.074-.157-2.596-.924-1.01-2.04-.168-1.851 2.235.063.809.264 2.19.533 3.042l.488 1.549.343-1.167c.19-.641.48-2.02.645-3.063z"
                      ></path>
                      <path
                        fill="#2c2c2c"
                        d="M-20.93 167.839h2.365q1.133 0 1.84.217.706.21 1.19.944.482.728.482 1.756 0 .945-.392 1.624-.392.678-1.056.98-.658.3-2.03.3h-.818v3.73h-1.581zm1.58 1.224v3.33h.785q1.05 0 1.448-.391.406-.392.406-1.274 0-.657-.266-1.063-.266-.413-.588-.504-.315-.098-1-.098zm5.508-1.224h2.148q1.56 0 2.49.552.938.553 1.414 1.645.483 1.091.483 2.42 0 1.4-.434 2.499-.427 1.091-1.316 1.763-.881.672-2.518.672h-2.267zm1.58 1.266v7.018h.659q1.378 0 2-.952.623-.958.623-2.553 0-3.513-2.623-3.513zm6.473-1.266h5.304v1.266h-3.723v2.855h2.981v1.266h-2.98v4.164H-5.79z"
                        font-family="Franklin Gothic Medium Cond"
                        letter-spacing="0"
                        style="
                          line-height: 125%;
                          -inkscape-font-specification: &quot;Franklin Gothic Medium Cond&quot;;
                        "
                        word-spacing="4.26"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
            </template>
          </div>
        </template>
        <template #totalCountTemplate>
          <h2 class="font-semibold !text-slate-800 text-lg ml-5">
            Totale: <span class="text-slate-400 font-medium">{{ total }}</span>
          </h2>
        </template>
        <template #number-cell="{ data }">
          <div>
            {{
              new Intl.NumberFormat("it-IT", {
                minimumFractionDigits: 2,
              }).format(data.value)
            }}
          </div>
        </template>
        <template #title-header="{ data }">
          <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
            {{ data.column.caption }}
          </p>
        </template>
      </DxDataGrid>
    </div>
    <!-- Table -->
  </div>
</template>
