<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
import ModalBlank from "../../../components/ModalBlank.vue";
import { parseError } from "../../../composables/parseError";
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
const loaded = ref(false);
const items = computed(() => store.getters["amm/getSlides"]);
const user = computed(() => store.getters["user/getUser"]);
const total = ref(0);
const removeItem = ref(false);
const dangerModalOpen = ref(false);
const itemToDelete = ref({});
const { errorSys, calcError } = parseError();

const dataGrid = ref({});
const onInitialized = (e) => {
  dataGrid.value = e.component;
};

const totale = (data) => {
  total.value = data.value;
  return "Totale: " + data.value;
};

onMounted(() => {
  store
    .dispatch("amm/fetchSlides")
    .then(() => {
      loaded.value = true;
    })
    .catch((e) => {
      calcError(e);
    });
});

const delItem = (item) => {
  itemToDelete.value = item;
  dangerModalOpen.value = true;
};

const actionDeleteItem = (itemId) => {
  store
    .dispatch("amm/removeSlide", {
      id: itemId,
    })
    .then(() => {
      removeItem.value = true;
      dangerModalOpen.value = false;
      dataGrid.value.refresh();

      setTimeout(() => {
        removeItem.value = false;
      }, 3000);
    })
    .catch(() => {
      dangerModalOpen.value = false;
    });
};

const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="removeItem">
      <div
        class="px-4 py-2 rounded-sm text-sm border bg-emerald-100 border-emerald-200 text-emerald-600"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
              ></path>
            </svg>
            <div>Slide eliminata con successo</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loaded">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div
      class="sm:flex sm:justify-between sm:items-center mb-8"
      v-if="loaded && !errorSys"
    >
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          Elenco slide
        </h1>
      </div>

      <!-- Right: Actions  -->
      <div
        class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
      >
        <!-- Add customer button -->
        <button
          class="btn text-white"
          :class="configVars.primary + ' hover:' + configVars.primaryHover"
        >
          <svg
            class="w-4 h-4 fill-current opacity-50 shrink-0"
            viewBox="0 0 16 16"
          >
            <path
              d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
            />
          </svg>
          <router-link
            :to="'/AMMINISTRAZIONE/GEST-SLIDE/add'"
            class="hidden xs:block ml-2"
            >Aggiungi</router-link
          >
        </button>
      </div>
    </div>
    <div class="py-4 bg-white" v-if="loaded && !errorSys">
      <DxDataGrid
        id="gridContainer"
        :data-source="items"
        :column-auto-width="true"
        :show-borders="true"
        :column-hiding-enabled="true"
        noDataText="Nessun risultato"
        :row-alternation-enabled="true"
        :on-initialized="onInitialized"
      >
        <DxSearchPanel :visible="true" placeholder="Cerca..." />
        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
        <DxLoadPanel :enabled="false" />
        <DxScrolling mode="infinite" />
        <DxSorting mode="single" />
        <DxPaging :enabled="false" />
        <DxColumnFixing :enabled="true" />

        <DxColumn
          header-cell-template="title-header"
          data-field="title"
          caption="TITOLO"
          :hiding-priority="1"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="description"
          caption="DESCRIZIONE"
          :hiding-priority="1"
          cell-template="desc-cell"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="dest"
          caption="DESTINAZIONE"
          :hiding-priority="1"
          cell-template="dest-cell"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="image"
          caption="IMMAGINE"
          cell-template="image-cell"
          :hiding-priority="1"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="start"
          caption="INIZIO"
          data-type="date"
          format="dd/MM/yyyy"
          :hiding-priority="1"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="end"
          caption="FINE"
          data-type="date"
          format="dd/MM/yyyy"
          :hiding-priority="1"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="id"
          caption="AZIONI"
          cell-template="action-cell"
          :hiding-priority="1"
        />
        <template #totalCountTemplate>
          <h2 class="font-semibold !text-slate-800 text-lg ml-5">
            Totale: <span class="text-slate-400 font-medium">{{ total }}</span>
          </h2>
        </template>
        <template #desc-cell="{ data }">
          <div>
            {{ data.value.replace(/(<([^>]+)>)/gi, "").substring(0, 20) }}...
          </div>
        </template>
        <template #dest-cell="{ data }">
          <div>
            <span v-if="data.value == 'oper'">Operatore</span
            ><span v-else>Utente</span>
          </div>
        </template>
        <template #image-cell="{ data }">
          <div>
            <template v-if="data.value"
              ><img
                :src="data.value"
                alt=""
                width="80"
                height="80"
                class="mb-2"
            /></template>
          </div>
        </template>
        <template #action-cell="{ data }">
          <div>
            <div class="space-x-1">
              <router-link
                :to="'/AMMINISTRAZIONE/GEST-SLIDE/edit/' + data.value"
              >
                <button
                  class="text-slate-400 hover:text-slate-500 rounded-full"
                >
                  <span class="sr-only">Modifica</span>
                  <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                    <path
                      d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z"
                    />
                  </svg>
                </button>
              </router-link>
              <button
                class="text-rose-500 hover:text-rose-600 rounded-full"
                aria-controls="danger-modal"
                @click.stop="delItem(data.data)"
              >
                <span class="sr-only">Cancella</span>
                <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                  <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
                  <path
                    d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </template>
        <template #title-header="{ data }">
          <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
            {{ data.column.caption }}
          </p>
        </template>
      </DxDataGrid>
    </div>
    <ModalBlank
      id="danger-modal"
      :modalOpen="dangerModalOpen"
      @close-modal="dangerModalOpen = false"
    >
      <div class="p-5 flex space-x-4">
        <!-- Icon -->
        <div
          class="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100"
        >
          <svg
            class="w-4 h-4 shrink-0 fill-current text-rose-500"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
            />
          </svg>
        </div>
        <!-- Content -->
        <div>
          <!-- Modal header -->
          <div class="mb-2">
            <div class="text-lg font-semibold text-slate-800">
              Eliminare {{ itemToDelete.title }}?
            </div>
          </div>
          <!-- Modal content -->
          <div class="text-sm mb-10">
            <div class="space-y-2">
              <p>L'operazione è irreversibile</p>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex flex-wrap justify-end space-x-2">
            <button
              class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
              @click.stop="dangerModalOpen = false"
            >
              Non eliminare
            </button>
            <button
              class="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
              @click="actionDeleteItem(itemToDelete.id)"
            >
              Sì, elimina
            </button>
          </div>
        </div>
      </div>
    </ModalBlank>
  </div>
</template>
