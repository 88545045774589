<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { parseError } from "../composables/parseError";
const { calcError } = parseError();
const store = useStore();

defineProps(["align"]);

const dropdownOpen = ref(false);
const trigger = ref(null);
const dropdown = ref(null);

// close on click outside
const clickHandler = ({ target }) => {
  if (
    !dropdownOpen.value ||
    dropdown.value.contains(target) ||
    trigger.value.contains(target)
  )
    return;
  dropdownOpen.value = false;
};

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
  if (!dropdownOpen.value || keyCode !== 27) return;
  dropdownOpen.value = false;
};

onMounted(() => {
  document.addEventListener("click", clickHandler);
  document.addEventListener("keydown", keyHandler);
});

onUnmounted(() => {
  document.removeEventListener("click", clickHandler);
  document.removeEventListener("keydown", keyHandler);
});

const router = useRouter();
const user = computed(() => store.getters["user/getUser"]);

const gotoLogout = () => {
  router.push(
    user.value.IsOper
      ? "/logout?from=loginOperatore"
      : "/logout?from=loginCliente",
  );
};

const deselectCustomer = () => {
  store.commit("menu/setMainMenu", []);

  store
    .dispatch("menu/fetchMenu")
    .then()
    .catch((e) => {
      calcError(e);
    });
  store.commit("user/setClienteAbbinato", {
    Cliente_dbKey: "",
    ClienteAbbinato: "",
    PostelTramite: "",
    Cliente: "",
    Gdpr: "",
    conto_dbKey: ''
  });
  store.commit("GRPOPERS/setClientiDropdown", []);

  router.push("/dashboard");
};

const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="inline-flex justify-center items-center group"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <img
        class="w-8 h-8 rounded-full"
        :src="'/data/' + configVars.licenseKey + '/' + configVars.logoSmall"
      />
      <div class="flex items-center truncate">
        <span
          class="truncate ml-2 text-sm font-medium group-hover:text-slate-800"
          >{{ user.DatiUtenza[0].Nome }}</span
        >
        <svg
          class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
          <div class="font-medium text-slate-800">
            {{ user.DatiUtenza[0].Nome }}
          </div>
        </div>
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <li v-if="user.DatiUtenza[0].ClienteAbbinato && user.IsOper">
            <a
              href="javascript:void(0)"
              class="font-medium text-sm flex items-center py-1 px-3"
              :class="
                configVars.primaryText + ' hover:' + configVars.primaryTextHover
              "
              @click="deselectCustomer"
              >Deseleziona il cliente</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="font-medium text-sm flex items-center py-1 px-3"
              :class="
                configVars.primaryText + ' hover:' + configVars.primaryTextHover
              "
              @click="gotoLogout"
              >Log Out</a
            >
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
