import { ref } from "vue";

export function datesLimit() {
  const today = ref();
  const todayHoursMinutes = ref();
  const firstLimit = ref({});
  const secondLimit = ref({});
  const thirdLimit = ref({});
  const fourthLimit = ref({});
  const fifthLimit = ref({});
  const sixthLimit = ref({});
  const firstDayYear = ref();

  const todayCalc = () => {
    let todayDay = new Date();
    let dd = String(todayDay.getDate()).padStart(2, "0");
    let mm = String(todayDay.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = todayDay.getFullYear();

    today.value = yyyy + "-" + mm + "-" + dd;
  };

  const todayHoursCalc = () => {
    let todayDay = new Date();
    let hr = todayDay.getHours();
    let min = todayDay.getMinutes();

    todayHoursMinutes.value = hr + ":" + min;
  };

  const firstDayOfYear = () => {
    let date = new Date(new Date().getFullYear(), 0, 1);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = date.getFullYear();

    firstDayYear.value = yyyy + "-" + mm + "-" + dd;
  };

  const convertTimeStamp = (data: any) => {
    let a = new Date(data);

    let dd = String(a.getDate()).padStart(2, "0");
    let mm = String(a.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = a.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };

  const datesCalc = (params: any) => {
    todayCalc();

    let f = params[0].split("=");
    let s = params[1].split("=");

    firstLimit.value = convertTimeStamp(
      new Date().setDate(new Date().getDate() - f[1]),
    );
    secondLimit.value = convertTimeStamp(
      new Date().setDate(new Date().getDate() - s[1]),
    );
    thirdLimit.value = convertTimeStamp(
      new Date().setDate(new Date().getDate() - f[1]),
    );
  };

  const datesCalc2 = (params: any) => {
    todayCalc();

    let f = params[0].split("=");
    let s = params[1].split("=");

    fourthLimit.value = convertTimeStamp(
      new Date().setDate(new Date().getDate() - f[1]),
    );
    fifthLimit.value = convertTimeStamp(
      new Date().setDate(new Date().getDate() - s[1]),
    );
    sixthLimit.value = convertTimeStamp(
      new Date().setDate(new Date().getDate() - f[1]),
    );
  };

  const convertHandMinS = (hs: string) => {
    let hms = hs + ':00'   // your input string
    let a = hms.split(':'); // split it at the colons
    return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
  }

  const secondsToHms = (d: number) => {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);

    let hDisplay = h > 0 ? h : "00";
    let mDisplay = m > 0 ? m : "00";

    hDisplay = hDisplay.toString().length === 1 ? ('0' + hDisplay).toString() : hDisplay.toString()
    mDisplay = mDisplay.toString().length === 1 ? ('0' + mDisplay).toString() : mDisplay.toString()

    return hDisplay + ":" + mDisplay;
  }

  return {
    datesCalc,
    datesCalc2,
    today,
    todayCalc,
    firstLimit,
    secondLimit,
    thirdLimit,
    fourthLimit,
    fifthLimit,
    sixthLimit,
    firstDayOfYear,
    firstDayYear,
    todayHoursCalc,
    todayHoursMinutes,
    convertHandMinS,
    secondsToHms
  };
}
