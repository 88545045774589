import { Ref, ref } from "vue";

export function data() {
  const exportedData: Ref<Array<any>> = ref([]);

  const elaborateData = (data: Array<object>) => {
    exportedData.value = data;
  };

  const elaborateDataGroupBy = (
    data: Array<any>,
    groups: Array<string>,
    checkNc: string | null = null,
  ) => {
    let hash = [{}];
    let uniqueArr: object[] = [];
    let newData: any[];

    // BLOCCO NOTE CREDITO
    if (checkNc) {
      newData = data.map((item) => {
        return { ...item, status: [] };
      });

      for (let i: number = 0, l: number = newData.length; i < l; i++) {
        (<any>newData[i]).status.push({
          notaCreditoNonAmmessaPer: (<any>newData[i]).notaCreditoNonAmmessaPer,
          stato: (<any>newData[i]).stato
            ? (<any>newData[i]).stato.description
            : "",
        });
      }
      // FINE BLOCCO NOTE DI CREDITO
    } else {
      newData = data;
    }

    for (let i: number = 0, l: number = newData.length; i < l; i++) {
      const rules: any = [];
      if (groups.length) {
        groups.forEach((gr: string) => {
          rules.push((<any>newData)[i][gr]);
        });
      }
      if (hash[rules] === undefined) {
        hash[rules] = 1;
        uniqueArr.push(newData[i]);
      }
    }

    exportedData.value = uniqueArr;
  };

  const exportedMetaDataFields: Ref<object> = ref({});

  const elaborateMetaDataFields = (fields: object) => {
    if (fields) {
      exportedMetaDataFields.value = fields;
    }
  };

  const exportedMetaDataActions: Ref<object> = ref({});

  const elaborateMetaDataActions = (actions: object) => {
    if (actions) {
      exportedMetaDataActions.value = actions;
    }
  };

  const exportedTableInfo: Ref<Record<string, number>> = ref({});

  const elaborateTableInfo = (data: any) => {
    if (data.__recordMD) {
      exportedTableInfo.value = data.__recordMD;
    }
  };

  const checkAllReadonly = (metadataFields: any, rules: any): boolean => {
    if (metadataFields) {
      for (const [key, value] of Object.entries(metadataFields)) {
        if (rules.hasOwnProperty(key)) {
          if ((<any>value).readonly === true) {
            return false;
          }
        }
      }
      return true;
    }
    return true;
  };

  const checkRequired = (metadataFields: any, first: string, second: string): boolean => {
    if (metadataFields) {
      if (metadataFields[first]) {
        if (metadataFields[first][second]) {
          if (metadataFields[first][second].required) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return {
    elaborateData,
    exportedData,
    elaborateMetaDataFields,
    exportedMetaDataFields,
    elaborateTableInfo,
    exportedTableInfo,
    elaborateDataGroupBy,
    exportedMetaDataActions,
    elaborateMetaDataActions,
    checkAllReadonly,
    checkRequired
  };
}
