<script setup>
import { ref, reactive, toRefs, computed, onMounted } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import { uploadFile } from "../../composables/upload.ts";
import Multiselect from "@vueform/multiselect";

const loading = ref(true);
const props = defineProps(["itemData"]);

const { calcFile, fileDef } = uploadFile();

const store = useStore();

const emit = defineEmits(["save"]);

const filesTypesTot = ref([]);

const form = reactive(
  props.itemData
    ? {
        typeId: props.itemData.typeId,
        name: props.itemData.name,
        file: fileDef,
      }
    : {
        typeId: "",
        name: "",
        file: fileDef,
      },
);

const rules = {
  typeId: {
    required: helpers.withMessage("Tipo file obbligatorio", required),
  },
  file: {
    required: helpers.withMessage(
      "File obbligatorio",
      requiredIf(function () {
        return !props.itemData;
      }),
    ),
  },
  name: {
    required: helpers.withMessage("Nome obbligatorio", required),
  },
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const onSubmit = () => {
  vv.value.$touch();

  if (vv.value.$invalid) return;
  emit("save", form);
};

onMounted(() => {
  store
    .dispatch("amm/fetchFilesTypes")
    .then((res) => {
      res.data.filesTypes.forEach((el) => {
        filesTypesTot.value.push({
          value: el.id,
          label: el.type,
        });
      });
      loading.value = false;
    })
    .catch((e) => {
      console.log(e);
    });
});
const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<style
  src="../../../node_modules/@vueform/multiselect/themes/default.css"
></style>
<template>
  <form @submit.prevent="onSubmit" novalidate>
    <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="mb-8">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ itemData ? "Modifica il file" : "Aggiungi un file" }}
        </h1>
      </div>

      <div v-if="loading">
        <div
          class="px-4 py-2 rounded-sm text-sm border bg-indigo-100 border-indigo-200 text-indigo-500"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                ></path>
              </svg>
              <div class="loading_course">Caricamento in corso</div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-lg rounded-sm mb-8" v-if="!loading">
        <div class="p-6 space-y-8 mt-8">
          <!-- Input Types -->
          <div>
            <h2 class="text-2xl text-slate-800 font-bold mb-6">
              Compila il form
            </h2>
            <div class="grid gap-5 md:grid-cols-3">
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="typeId"
                    >Tipo <span class="text-rose-500">*</span></label
                  >
                  <Multiselect
                    id="typeId"
                    v-model="vv.typeId.$model"
                    :searchable="true"
                    mode="single"
                    :options="filesTypesTot"
                    :filterResults="true"
                    :minChars="1"
                    :resolveOnLoad="false"
                    :canClear="false"
                  />
                </div>
              </div>
              <div>
                <!-- Start -->
                <div>
                  <label class="block text-sm font-medium mb-1" for="file"
                    >File
                    <span
                      :class="props.itemData ? 'text-white' : 'text-rose-500'"
                      >*</span
                    ></label
                  >
                  <input
                    id="file"
                    class="block w-full text-sm text-slate-500 rounded border p-1 file:mr-4 file:px-4 file:py-1 file:rounded file:border-0 file:text-sm file:font-medium file:bg-slate-50 file:text-slate-700 hover:file:bg-slate-100"
                    type="file"
                    @change="calcFile('file')"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.file.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <!-- End -->
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="name"
                    >Nome <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="name"
                    v-model="vv.name.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <footer>
              <div class="flex flex-col py-5 border-t border-slate-200 mt-6">
                <div class="flex self-end">
                  <button
                    class="btn text-white ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                    :disabled="vv.$invalid"
                    :class="
                      configVars.primary + ' hover:' + configVars.primaryHover
                    "
                    type="submit"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
