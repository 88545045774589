import GenericModel from "./generic";

export function RichiediNoteCreditoModelRules() {
  const fields = [
    new GenericModel(
      "__dbKey",
      "__dbKey",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "__recordDescription",
      "__recordDescription",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "IdMagmov",
      "IdMagmov",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "magazziniMovimenti",
      "magazziniMovimenti",
      false,
      false,
      false,
      "String",
      false,
      99,
      "",
    ),
    new GenericModel(
      "status",
      "Stato",
      true,
      false,
      false,
      "Array",
      "status-nc",
      99,
      "",
      false,
    ),
    new GenericModel(
      "dataFattura",
      "Data fattura",
      true,
      true,
      false,
      "Date",
      false,
      1,
      "",
    ),
    new GenericModel(
      "numeroFattura",
      "Numero fattura",
      true,
      true,
      false,
      "Number",
      "numero-fattura",
      2,
      "",
    ),
    new GenericModel(
      "registroSezionale",
      "Registro sezionale",
      false,
      false,
      false,
      "String",
      false,
      3,
      "",
    ),
    new GenericModel(
      "testataFatturaTotaleDocumento",
      "Totale documento",
      true,
      true,
      false,
      "Number",
      "format-currency",
      4,
      "",
    ),
    new GenericModel(
      "notaCreditoNonAmmessaPer",
      "Nota di credito",
      false,
      false,
      false,
      "String",
      false,
      5,
      "",
    ),
    new GenericModel(
      "prodottoPrecodice",
      "Precodice",
      false,
      true,
      false,
      "String",
      false,
      6,
      "",
    ),
    new GenericModel(
      "prodottoCodice",
      "Codice",
      false,
      true,
      false,
      "String",
      false,
      7,
      "",
    ),
    new GenericModel(
      "magazziniMovimentiDescrizione",
      "Descrizione",
      false,
      true,
      false,
      "String",
      false,
      8,
      "",
    ),
    new GenericModel(
      "magazziniMovimentiQuantita",
      "Quantità",
      false,
      true,
      false,
      "String",
      false,
      9,
    ),
    new GenericModel(
      "magazziniMovimentoPrezzo",
      "Prezzo",
      false,
      true,
      false,
      "Number",
      "format-currency",
      10,
      "",
    ),
    new GenericModel(
      "magazziniMovimentoPrezzoNetto",
      "Prezzo netto",
      false,
      true,
      false,
      "Number",
      "format-currency",
      11,
      "",
    ),
    new GenericModel(
      "fattura",
      "Prezzo fattura",
      false,
      true,
      false,
      "File",
      "blob",
      12,
      "",
    ),
  ];

  return {
    fields,
  };
}
