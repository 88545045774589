<script setup>
import { computed } from "vue";
const props = defineProps(["item", "keyItem", "selected"]);
const checked = computed(() => props.selected.includes(props.keyItem));
const emit = defineEmits(["update:selected", "deleteItem"]);

const check = function () {
  let updatedSelected = [...props.selected];
  if (this.checked) {
    updatedSelected.splice(updatedSelected.indexOf(props.keyItem), 1);
  } else {
    updatedSelected.push(props.keyItem);
  }
  emit("update:selected", updatedSelected);
};

const delItem = (itemId) => {
  emit("deleteItem", itemId);
};
</script>
<template>
  <div class="flex">
    <div class="px-2 first:pl-5 last:pr-5 py-4 whitespace-nowrap w-12">
      <div class="flex items-center">
        <label class="inline-flex">
          <span class="sr-only">Seleziona</span>
          <input
            :id="item.id"
            class="form-checkbox"
            type="checkbox"
            :value="keyItem"
            @change="check($event)"
            :checked="checked"
          />
        </label>
      </div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-14">
      <div class="text-left">{{ item.id }}</div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-1/3">
      <div class="text-left">{{ item.title }}</div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-1/3">
      <div class="text-left">
        {{ item.description.replace(/(<([^>]+)>)/gi, "").substring(0, 20) }}...
      </div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-1/3">
      <div class="text-left">
        <template v-if="item.image"
          ><img :src="item.image" alt="" width="80" height="80" class="mb-2"
        /></template>
      </div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-1/3">
      <div class="text-left">
        {{ item.start ? formatDate(item.start) : "" }}
      </div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-1/3">
      <div class="text-left">{{ item.end ? formatDate(item.end) : "" }}</div>
    </div>
    <div class="px-2 first:pl-5 last:pr-5 pt-3 whitespace-nowrap w-28">
      <div class="space-x-1">
        <router-link :to="'/AMMINISTRAZIONE/GEST-SLIDE/edit/' + item.id">
          <button class="text-slate-400 hover:text-slate-500 rounded-full">
            <span class="sr-only">Modifica</span>
            <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
              <path
                d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z"
              />
            </svg>
          </button>
        </router-link>
        <button
          class="text-rose-500 hover:text-rose-600 rounded-full"
          aria-controls="danger-modal"
          @click.stop="delItem(item)"
          v-if="!item.blocked"
        >
          <span class="sr-only">Cancella</span>
          <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
            <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
            <path
              d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
