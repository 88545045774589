<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
import { datesLimit } from "../../composables/datesLimit";
import { parseError } from "../../composables/parseError";
const { today, firstDayYear, todayCalc, firstDayOfYear } = datesLimit();
const { errorSys, calcError } = parseError();
const props = defineProps(["title"]);
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
const loading = ref(true);
const loaded = ref(false);
const downloading = ref(false);
const items = computed(() => store.getters["GRPFATTURE/getVisFatture"]);
const user = computed(() => store.getters["user/getUser"]);
import Table from "../../components/dom/Table.vue";
import { data } from "../../composables/api/data";
const {
  exportedData,
  exportedMetaDataFields,
  exportedTableInfo,
  elaborateMetaDataFields,
  elaborateDataGroupBy,
  elaborateTableInfo,
} = data();

onMounted(() => {
  todayCalc();
  firstDayOfYear();
  loadData();
});

const form = reactive({
  dataDa: firstDayYear,
  dataA: today,
});

const configVars = computed(() => store.getters["menu/getConfigVars"]);

if (user.value.DatiUtenza[0].Cliente_dbKey == "") {
  errorSys.value =
    "Deve essere selezionato un cliente prima di procedere alla ricerca";
}

const loadData = () => {
  if (user.value.DatiUtenza[0].Cliente_dbKey != "") {
    store
      .dispatch("generic/beRetrieve", {
        beName: "FattureEmesse",
        beView: "fattureEmesseLista",
        includeMetadata: "view",
        solveDynamicMetadata: false,
        filter:
          "{cliente_dbKey} == '" +
          user.value.DatiUtenza[0].Cliente_dbKey +
          "' AND {dataFattura} >= '" +
          form.dataDa +
          "' AND {dataFattura} <= '" +
          form.dataA +
          "'",
      })
      .then((res) => {
        elaborateDataGroupBy(
          res.data.apiResult.data,
          [
            "dataFattura",
            "numeroFattura",
            "registroSezionale",
            "testataFatturaTotaleDocumento",
          ],
          "notaCreditoNonAmmessaPer",
        );

        if (res.data.apiResult.data && res.data.apiResult.metadata) {
          if (res.data.apiResult.metadata.fields) {
            elaborateMetaDataFields(res.data.apiResult.metadata.fields);
            elaborateTableInfo(res.data.apiResult.metadata.tableInfo);
            loaded.value = true;
          }
        }

        loading.value = false;
      })
      .catch((e) => {
        calcError(e);
      });
  }
};
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8">
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ props.title }}
        </h1>
      </div>
    </div>
    <div class="p-6 space-y-8 mt-8 mb-5 bg-white" v-if="!loading">
      <!-- Input Types -->
      <div>
        <div class="grid gap-5 md:grid-cols-4 items-end">
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataDa"
                >Dal</label
              >
              <input
                id="dataDa"
                class="form-input w-full"
                type="date"
                v-model="form.dataDa"
                :min="firstDayYear"
                :max="today"
              />
            </div>
          </div>
          <div>
            <div>
              <label class="block text-sm font-medium mb-1" for="dataAl"
                >Al</label
              >
              <input
                id="dataAl"
                class="form-input w-full"
                v-model="form.dataA"
                type="date"
              />
            </div>
          </div>
          <div>
            <button
              class="btn text-white md:ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
              @click="loadData"
              :class="configVars.primary + ' hover:' + configVars.primaryHover"
              type="button"
            >
              Cerca
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading && !errorSys">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <Table
      :items="exportedData"
      :metadataFields="exportedMetaDataFields"
      :tableInfo="exportedTableInfo"
      type="richiediNc"
      :loaded="loaded"
      @refreshData="loadData"
    />
  </div>
</template>
