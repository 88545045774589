import GenericModel from "./generic";

export function PagamentoFattureModelRules() {
  const fields = [
    new GenericModel(
      "__dbKey",
      "__dbKey",
      false,
      false,
      false,
      "String",
      false,
      99,
    ),
    new GenericModel(
      "__recordDescription",
      "__recordDescription",
      false,
      false,
      false,
      "String",
      false,
      99,
    ),
    new GenericModel(
      "idMovtc",
      "idMovtc",
      false,
      false,
      false,
      "String",
      false,
      99,
    ),
    new GenericModel(
      "contoRagioneSociale",
      "Fornitore",
      true,
      true,
      false,
      "String",
      false,
      1,
    ),
    new GenericModel(
      "testataMovContItemNumeroDocumento",
      "Numero fattura",
      true,
      true,
      false,
      "String",
      false,
      2,
    ),
    new GenericModel(
      "testataMovContItemDataDocumento",
      "Data fattura",
      true,
      true,
      false,
      "Date",
      false,
      3,
    ),
    new GenericModel(
      "totaleDocumento",
      "Importo",
      true,
      true,
      false,
      "Number",
      "format-currency",
      4,
    ),
    new GenericModel(
      "dataScadenza",
      "Data scadenza",
      false,
      false,
      false,
      "Date",
      false,
      5,
    ),
    new GenericModel("stato", "Stato", false, true, false, "Object", false, 6),
    new GenericModel(
      "testataMovContItemDescrizione",
      "Categoria",
      false,
      true,
      false,
      "String",
      false,
      7,
    ),
    new GenericModel(
      "testataMovContItemAllegato",
      "Allegato",
      false,
      true,
      false,
      "File",
      "blob",
      9,
    ),
    new GenericModel("note", "Note", false, true, false, "Number", false, 10),
  ];

  return {
    fields,
  };
}
