<script setup>
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
import { parseError } from "../../composables/parseError";
import Banner2 from "../../components/Banner2.vue";
import Multiselect from "@vueform/multiselect";
const { errorSys, calcError } = parseError();
const app = getCurrentInstance();

import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { restrictChars } from "../../composables/restrictChars";
import { helpers, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
const { restrictCharsOnly } = restrictChars();

const loaded = ref(false);
const loaded2 = ref(false);
const loaded3 = ref(false);
const loaded4 = ref(false);
const loaded5 = ref(false);
const loaded6 = ref(false);
const loading = ref(false);
const items = computed(() => store.getters["GRPOPERS/getClienti"]);
const user = computed(() => store.getters["user/getUser"]);
const props = defineProps(["title"]);
const route = useRoute();
const total = ref(0);

const totale = (data) => {
  total.value = data.value;
  return "Totale: " + data.value;
};

const form = reactive({
  RagioneSociale: null,
  Cliente: null,
  Indirizzo: null,
  Cap: null,
  Comune: null,
  Provincia: null,
  CodiceFiscale: null,
  PartitaIva: null,
  Qualifica: null,
  MacroStato: null,
  Agente: null,
  CategoriaSconto: null,
  StatoCliente: null,
});

const ricercaClienti = app.appContext.config.globalProperties.ricercaClienti;

const rules = {
  RagioneSociale: {
    //minLengthValue: helpers.withMessage('Lunghezza minima 3 caratteri', minLength(3)),
    //required: helpers.withMessage('Campo obbligatorio', required)
  },
  Cliente: {},
  Indirizzo: {},
  Cap: {},
  Comune: {},
  Provincia: {},
  CodiceFiscale: {},
  PartitaIva: {},
  Qualifica: {},
  MacroStato: {},
  Agente: {},
  CategoriaSconto: {},
  StatoCliente: {},
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const searchClienti = () => {
  loading.value = true;

  const search = {
    RagioneSociale: form.RagioneSociale != "" ? form.RagioneSociale : null,
    Cliente: form.Cliente != "" ? form.Cliente : null,
    Indirizzo: form.Indirizzo != "" ? form.Indirizzo : null,
    Cap: form.Cap != "" ? form.Cap : null,
    Comune: form.Comune != "" ? form.Comune : null,
    Provincia: form.Provincia != "" ? form.Provincia : null,
    CodiceFiscale: form.CodiceFiscale != "" ? form.CodiceFiscale : null,
    PartitaIva: form.PartitaIva != "" ? form.PartitaIva : null,
    Qualifica: form.Qualifica != "" ? form.Qualifica : null,
    MacroStato: form.MacroStato != "" ? form.MacroStato : null,
    Agente: form.Agente != "" ? form.Agente : null,
    CategoriaSconto: form.CategoriaSconto != "" ? form.CategoriaSconto : null,
    StatoCliente: form.StatoCliente != "" ? form.StatoCliente : null,
  };

  store
    .dispatch("GRPOPERS/fetchClienti", search)
    .then(() => {
      ricercaClienti.value = form;
      loaded.value = true;
      loading.value = false;
    })
    .catch((e) => {
      calcError(e);
    });
};

const qualifiche = computed(() => store.getters["GRPOPERS/getQualifiche"]);
const macroStati = computed(() => store.getters["GRPOPERS/getMacroStati"]);
const agenti = computed(() => store.getters["GRPOPERS/getAgenti"]);
const categorieSconto = computed(
  () => store.getters["GRPOPERS/getCategorieSconto"],
);
const statiCliente = computed(() => store.getters["GRPOPERS/getStatiCliente"]);

const qualificaTot = ref([]);
const macroStatoTot = ref([]);
const agentiTot = ref([]);
const categorieScontoTot = ref([]);
const statiClienteTot = ref([]);

onMounted(() => {
  if (!qualifiche.value.length) {
    store
      .dispatch("GRPOPERS/fetchQualifiche", {
        Codice: "",
        Descrizione: "",
        numRecords: "",
        qua_id: "",
      })
      .then(() => {
        if (qualifiche.value.length) {
          qualifiche.value.forEach((el) => {
            qualificaTot.value.push({
              value: el.Descrizione,
              label: el.Descrizione,
            });
          });
        }
        loaded2.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  } else {
    qualifiche.value.forEach((el) => {
      qualificaTot.value.push({
        value: el.Descrizione,
        label: el.Descrizione,
      });
    });

    loaded2.value = true;
  }

  if (!macroStati.value.length) {
    store
      .dispatch("GRPOPERS/fetchMacroStati", {
        Codice: "",
        Descrizione: "",
        numRecords: "",
      })
      .then(() => {
        if (macroStati.value.length) {
          macroStati.value.forEach((el) => {
            macroStatoTot.value.push({
              value: el.Descrizione,
              label: el.Descrizione,
            });
          });
        }
        loaded3.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  } else {
    macroStati.value.forEach((el) => {
      macroStatoTot.value.push({
        value: el.Descrizione,
        label: el.Descrizione,
      });
    });
    loaded3.value = true;
  }

  if (!agenti.value.length) {
    store
      .dispatch("GRPOPERS/fetchAgenti", {
        age_id: "",
        Agente: "",
        numRecords: "",
        RagioneSociale: "",
      })
      .then(() => {
        if (agenti.value.length) {
          agenti.value.forEach((el) => {
            agentiTot.value.push({
              value: el.RagioneSociale,
              label: el.RagioneSociale,
            });
          });
        }
        loaded4.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  } else {
    agenti.value.forEach((el) => {
      agentiTot.value.push({
        value: el.RagioneSociale,
        label: el.RagioneSociale,
      });
    });
    loaded4.value = true;
  }

  if (!categorieSconto.value.length) {
    store
      .dispatch("GRPOPERS/fetchCategorieSconto", {
        Codice: "",
        Descri: "",
        numRecords: "",
        IdCatsco: "",
      })
      .then(() => {
        if (categorieSconto.value.length) {
          categorieSconto.value.forEach((el) => {
            categorieScontoTot.value.push({
              value: el.Descri,
              label: el.Descri,
            });
          });
        }
        loaded5.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  } else {
    categorieSconto.value.forEach((el) => {
      categorieScontoTot.value.push({
        value: el.Descri,
        label: el.Descri,
      });
    });
    loaded5.value = true;
  }

  if (!statiCliente.value.length) {
    store
      .dispatch("GRPOPERS/fetchStatiCliente", {
        Codice: "",
        Descrizione: "",
        numRecords: "",
      })
      .then(() => {
        if (statiCliente.value.length) {
          statiCliente.value.forEach((el) => {
            statiClienteTot.value.push({
              value: el.Descrizione,
              label: el.Descrizione,
            });
          });
        }
        loaded6.value = true;
      })
      .catch((e) => {
        calcError(e);
      });
  } else {
    statiCliente.value.forEach((el) => {
      statiClienteTot.value.push({
        value: el.Descrizione,
        label: el.Descrizione,
      });
    });
    loaded6.value = true;
  }

  if (ricercaClienti.value) {
    if (
      ricercaClienti.value.RagioneSociale ||
      ricercaClienti.value.Cliente ||
      ricercaClienti.value.Indirizzo ||
      ricercaClienti.value.Cap ||
      ricercaClienti.value.Comune ||
      ricercaClienti.value.Provincia ||
      ricercaClienti.value.CodiceFiscale ||
      ricercaClienti.value.PartitaIva ||
      ricercaClienti.value.Qualifica ||
      ricercaClienti.value.MacroStato ||
      ricercaClienti.value.Agente ||
      ricercaClienti.value.CategoriaSconto ||
      ricercaClienti.value.StatoCliente
    ) {
      form.RagioneSociale = ricercaClienti.value.RagioneSociale;
      form.Cliente = ricercaClienti.value.Cliente;
      form.Indirizzo = ricercaClienti.value.Indirizzo;
      form.Cap = ricercaClienti.value.Cap;
      form.Comune = ricercaClienti.value.Comune;
      form.Provincia = ricercaClienti.value.Provincia;
      form.CodiceFiscale = ricercaClienti.value.CodiceFiscale;
      form.PartitaIva = ricercaClienti.value.PartitaIva;
      form.Qualifica = ricercaClienti.value.Qualifica;
      form.MacroStato = ricercaClienti.value.MacroStato;
      form.Agente = ricercaClienti.value.Agente;
      form.CategoriaSconto = ricercaClienti.value.CategoriaSconto;
      form.StatoCliente = ricercaClienti.value.StatoCliente;

      searchClienti();
    }
  }
});

const selectCustomer = (data) => {
  store.commit("user/setClienteAbbinato", {
    Cliente_dbKey: "",
    ClienteAbbinato: "",
    PostelTramite: "",
    Cliente: "",
    Gdpr: 0,
    conto_dbKey: ''
  });
  store.commit("GRPOPERS/setClientiDropdown", []);
  store.commit("menu/setMainMenu", []);

  store
    .dispatch("menu/fetchMenu", {
      Cliente_dbKey: data.__dbKey,
    })
    .then(() => {
      const altrePosizioniContabili = computed(() =>
        store.getters["menu/getMainMenu"]
          .filter((c) => c.code === "GRP-PROFILO")[0]
          .childs.filter((child) => child.code === "CHG-POS_CONTABILE"),
      );

      if (altrePosizioniContabili.value) {
        const form = {
          RagioneSociale: null,
          Cliente: null,
          Indirizzo: null,
          Cap: null,
          Comune: null,
          Provincia: null,
          CodiceFiscale: data["Conto/Soggetto/CodiceFiscale"],
          PartitaIva: null,
          Qualifica: null,
          MacroStato: null,
          Agente: null,
          CategoriaSconto: null,
          StatoCliente: null,
        };

        if (!data["Conto/Soggetto/CodiceFiscale"]) {
          store.commit("GRPOPERS/setClientiDropdown", []);
        } else {
          store
            .dispatch("GRPOPERS/fetchClientiDropDown", form)
            .then()
            .catch((e) => {
              calcError(e);
            });
        }
      }
    })
    .catch((e) => {
      calcError(e);
    });

  store.commit("user/setClienteAbbinato", {
    Cliente_dbKey: data.__dbKey,
    ClienteAbbinato: data.RagioneSociale,
    PostelTramite: data.PostelTramite,
    Cliente: data.Cliente,
    Gdpr: 0,
    conto_dbKey: data.conto_dbKey
  });

  router.push("/dashboard");
};
const configVars = computed(() => store.getters["menu/getConfigVars"]);
const params = computed(() => {
  if (store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-OPERS")) {
    if (
      store.getters["menu/getMainMenu"].filter((c) => c.code === "GRP-OPERS")[0]
    ) {
      return store.getters["menu/getMainMenu"]
        .filter((c) => c.code === "GRP-OPERS")[0]
        .childs.filter((child) => child.code === "VIS-CLIENTI")[0]
        .params.split("=");
    }
  }
  return null;
});

const resetForm = () => {
  form.RagioneSociale = "";
  form.Cliente = "";
  form.Indirizzo = "";
  form.Cap = "";
  form.Comune = "";
  form.Provincia = "";
  form.CodiceFiscale = "";
  form.PartitaIva = "";
  form.Qualifica = "";
  form.MacroStato = "";
  form.Agente = "";
  form.CategoriaSconto = "";
  form.StatoCliente = "";

  store.dispatch("GRPOPERS/resetClienti");
  loaded.value = false;
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8">
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ props.title }}
        </h1>
      </div>
    </div>
    <div
      class="p-6 space-y-8 mt-8 mb-8 bg-white"
      v-if="loaded2 && loaded3 && loaded4 && loaded5 && loaded6"
    >
      <!-- Input Types -->
      <form @submit.prevent="searchClienti" novalidate>
        <div>
          <div class="grid gap-5 md:grid-cols-4 items-end">
            <div>
              <div>
                <label
                  class="block text-sm font-medium mb-1"
                  for="RagioneSociale"
                  >Ragione Sociale</label
                >
                <input
                  id="RagioneSociale"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.RagioneSociale.$model"
                />
              </div>
              <div
                class="text-xs mt-1 text-rose-500"
                v-for="(error, index) of vv.RagioneSociale.$errors"
                :key="index"
              >
                {{ error.$message }}
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Cliente"
                  >Codice Cliente</label
                >
                <input
                  id="Cliente"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.Cliente.$model"
                  @keypress="restrictCharsOnly($event)"
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  class="block text-sm font-medium mb-1"
                  for="CodiceFiscale"
                  >Codice Fiscale</label
                >
                <input
                  id="CodiceFiscale"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.CodiceFiscale.$model"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="PartitaIva"
                  >Partita IVA</label
                >
                <input
                  id="PartitaIva"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.PartitaIva.$model"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Indirizzo"
                  >Via</label
                >
                <input
                  id="Indirizzo"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.Indirizzo.$model"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Cap"
                  >Cap</label
                >
                <input
                  id="Cap"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.Cap.$model"
                  @keypress="restrictCharsOnly($event)"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Comune"
                  >Città</label
                >
                <input
                  id="Comune"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.Comune.$model"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Provincia"
                  >Sigla provincia</label
                >
                <input
                  id="Provincia"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.Provincia.$model"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Agente"
                  >Agente</label
                >
                <Multiselect
                  id="Agente"
                  v-model="vv.Agente.$model"
                  :searchable="true"
                  mode="single"
                  :options="agentiTot"
                  :filterResults="true"
                  :minChars="1"
                  :resolveOnLoad="false"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="Qualifica"
                  >Qualifica</label
                >
                <Multiselect
                  id="Qualifica"
                  v-model="vv.Qualifica.$model"
                  :searchable="true"
                  mode="single"
                  :options="qualificaTot"
                  :filterResults="true"
                  :minChars="1"
                  :resolveOnLoad="false"
                />
              </div>
            </div>
            <div>
              <div>
                <label
                  class="block text-sm font-medium mb-1"
                  for="CategoriaSconto"
                  >Categoria Sconto</label
                >
                <Multiselect
                  id="CategoriaSconto"
                  v-model="vv.CategoriaSconto.$model"
                  :searchable="true"
                  mode="single"
                  :options="categorieScontoTot"
                  :filterResults="true"
                  :minChars="1"
                  :resolveOnLoad="false"
                />
              </div>
            </div>
            <div class="col-start-1">
              <div>
                <label class="block text-sm font-medium mb-1" for="MacroStato"
                  >Macro stato</label
                >
                <Multiselect
                  id="MacroStato"
                  v-model="vv.MacroStato.$model"
                  :searchable="true"
                  mode="single"
                  :options="macroStatoTot"
                  :filterResults="true"
                  :minChars="1"
                  :resolveOnLoad="false"
                />
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="StatoCliente"
                  >Stato Cliente</label
                >
                <Multiselect
                  id="StatoCliente"
                  v-model="vv.StatoCliente.$model"
                  :searchable="true"
                  mode="single"
                  :options="statiClienteTot"
                  :filterResults="true"
                  :minChars="1"
                  :resolveOnLoad="false"
                />
              </div>
            </div>
            <div class="col-start-1">
              <button
                class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                :disabled="vv.$invalid"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
                type="submit"
              >
                Cerca
              </button>
              <button
                class="btn text-white disabled:border-slate-200 ml-12"
                :class="
                  configVars.primaryHover + ' hover:' + configVars.primary
                "
                type="button"
                @click="resetForm"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div
      v-if="loading || !loaded2 || !loaded3 || !loaded4 || !loaded5 || !loaded6"
    >
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="params">
      <div
        class="mb-5"
        v-if="items.length >= params[1] && loaded && !errorSys && !loading"
      >
        <Banner2 type="warning" :open="true">
          La selezione potrebbe non essere esaustiva. Si consiglia di delimitare
          la ricerca inserendo ulteriori criteri di selezione
        </Banner2>
      </div>
    </template>
    <div class="py-4 bg-white" v-if="loaded && !errorSys && !loading">
      <DxDataGrid
        id="gridContainer"
        :data-source="items"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        noDataText="Nessun risultato"
      >
        <DxScrolling
          :use-native="false"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          show-scrollbar="onHover"
          mode="infinite"
        />
        <DxSearchPanel :visible="true" placeholder="Cerca..." />
        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>
        <DxLoadPanel :enabled="false" />
        <DxSorting mode="single" />
        <DxPaging :enabled="false" />
        <DxColumnFixing :enabled="true" />
        <DxColumn
          header-cell-template="title-header"
          data-field="__dbKey"
          caption="Seleziona"
          cell-template="choose-customer"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="RagioneSociale"
          caption="Ragione Sociale"
          alignment="left"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Cliente"
          caption="Codice"
          alignment="left"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Qualifica/Descrizione"
          caption="Qualifica"
          cell-template="qualifica"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Conto/PartitaIva"
          caption="Partita IVA"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Conto/Soggetto/CodiceFiscale"
          caption="Codice Fiscale"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Conto/Indirizzo"
          caption="Via"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Conto/Comune"
          caption="Città"
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="Conto/Provincia"
          caption="Prov."
        />
        <DxColumn
          header-cell-template="title-header"
          data-field="StatoCliente/Descrizione"
          caption="Stato"
        />
        <template #qualifica="{ data }">
          <template v-if="data.value">
            {{ data.value }}
          </template>
          <template v-else>
            {{ data.data.qualificaDescrizione }}
          </template>
        </template>
        <template #choose-customer="{ data }">
          <button
            class="btn btn-xs text-white"
            :class="configVars.primary + ' hover:' + configVars.primaryHover"
            @click="selectCustomer(data.data)"
          >
            <svg
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>dashboard_customize</title>
              <g fill="none" class="nc-icon-wrapper">
                <path
                  d="M3 3h8v8H3V3zm10 0h8v8h-8V3zM3 13h8v8H3v-8zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
            <button class="hidden xs:block ml-2">Seleziona</button>
          </button>
        </template>
        <template #totalCountTemplate>
          <h2 class="font-semibold !text-slate-800 text-lg ml-5">
            Totale: <span class="text-slate-400 font-medium">{{ total }}</span>
          </h2>
        </template>
        <template #title-header="{ data }">
          <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
            {{ data.column.caption }}
          </p>
        </template>
      </DxDataGrid>
    </div>
    <!-- Table -->
  </div>
</template>
