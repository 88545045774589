import { createStore } from "vuex";
import user from "./modules/user";
import menu from "./modules/menu";
import amm from "./modules/amm";
import GRPFATTURE from "./modules/GRP-FATTURE";
import VuexPersistence from "vuex-persist";
import GRPOPERS from "./modules/GPR-OPERS";
import GRPPROFILO from "./modules/GRP-PROFILO";
import GRPCONTRATTI from "./modules/GRP-CONTRATTI";
import external from "./modules/external";
import generic from "./modules/generic";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const store = createStore({
  modules: {
    user,
    menu,
    amm,
    GRPFATTURE,
    GRPOPERS,
    GRPPROFILO,
    GRPCONTRATTI,
    external,
    generic,
  },
  plugins: [vuexLocal.plugin],
});
