<template>
  <div class="relative flex">
    <p class="text-sm font-medium mr-3 mt-1 hidden lg:block">
      Scegli una delle tue posizioni contabili attive
    </p>
    <button
      ref="trigger"
      class="grow flex items-center truncate"
      aria-haspopup="true"
      @click.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <div class="truncate mt-0.5">
        <span
          class="text-sm font-bold text-rose-600"
          v-if="user.DatiUtenza[0].ClienteAbbinato"
          >{{ user.DatiUtenza[0].ClienteAbbinato }}</span
        >
      </div>
      <svg
        class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
        viewBox="0 0 12 12"
      >
        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="origin-top-right z-10 absolute top-full right-0 min-w-60 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-4"
      >
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <li v-for="customer in data">
            <a
              class="font-medium text-sm text-slate-600 hover:text-slate-800 block py-1.5 px-3"
              href="#0"
              @click="
                dropdownOpen = false;
                selectCustomer(customer);
              "
            >
              <div class="flex items-center justify-between">
                <div class="grow flex items-center truncate">
                  <img
                    class="w-7 h-7 rounded-full mr-2"
                    src="../images/channel-01.png"
                    width="28"
                    height="28"
                    alt="Channel 01"
                  />
                  <div class="truncate">{{ customer.RagioneSociale }}</div>
                </div>
                <svg
                  class="w-3 h-3 shrink-0 fill-current text-indigo-500 ml-1"
                  viewBox="0 0 12 12"
                  v-if="user.DatiUtenza[0].Cliente_dbKey === customer.__dbKey"
                >
                  <path
                    d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"
                  />
                </svg>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { parseError } from "../composables/parseError";
import { useRouter } from "vue-router";

export default {
  name: "DropdownSwitch",
  props: {
    data: Array,
  },
  setup(props, { emit }) {
    const { calcError } = parseError();
    const store = useStore();
    const data = props.data;
    const dropdownOpen = ref(false);
    const trigger = ref(null);
    const dropdown = ref(null);

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return;
      dropdownOpen.value = false;
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return;
      dropdownOpen.value = false;
    };

    onMounted(() => {
      document.addEventListener("click", clickHandler);
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("click", clickHandler);
      document.removeEventListener("keydown", keyHandler);
    });

    const user = computed(() => store.getters["user/getUser"]);

    const router = useRouter();

    const selectCustomer = (data) => {
      store.commit("menu/setMainMenu", []);

      store
        .dispatch("menu/fetchMenu", {
          Cliente_dbKey: data.__dbKey,
        })
        .then()
        .catch((e) => {
          calcError(e);
        });
      store.commit("user/setClienteAbbinato", {
        Cliente_dbKey: data.__dbKey,
        ClienteAbbinato: data.RagioneSociale,
        PostelTramite: data.PostelTramite,
        Cliente: data.Cliente,
        Gdpr: 0,
        conto_dbKey: data.conto_dbKey
      });

      router.push("/dashboard");
    };

    return {
      dropdownOpen,
      trigger,
      dropdown,
      data,
      user,
      selectCustomer,
    };
  },
};
</script>
