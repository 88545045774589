import axios from "axios"; // Vue router instance

const state: any = {
  clienti: [],
  qualifiche: [],
  statiCliente: [],
  agenti: [],
  macroStati: [],
  categorieSconto: [],
  clientiDropDown: [],
};

const getters: any = {
  getClienti: (state: any) => {
    return state.clienti;
  },
  getQualifiche: (state: any) => {
    return state.qualifiche;
  },
  getStatiCliente: (state: any) => {
    return state.statiCliente;
  },
  getAgenti: (state: any) => {
    return state.agenti;
  },
  getMacroStati: (state: any) => {
    return state.macroStati;
  },
  getCategorieSconto: (state: any) => {
    return state.categorieSconto;
  },
  getClientiDropdown: (state: any) => {
    return state.clientiDropDown;
  },
};

const actions: any = {
  async fetchClienti(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ricercaClienti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setClienti", result.data.apiResult);

    return result;
  },
  async resetClienti(context: any) {
    context.commit("setClienti", []);

    return true;
  },
  async fetchQualifiche(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Qualifiche/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setQualifiche", result.data.apiResult);

    return result;
  },
  async fetchStatiCliente(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/StatiCliente/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setStatiCliente", result.data.apiResult);

    return result;
  },
  async fetchAgenti(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Agenti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setAgenti", result.data.apiResult);

    return result;
  },
  async fetchMacroStati(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/MacroStati/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setMacroStati", result.data.apiResult);

    return result;
  },
  async fetchCategorieSconto(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/CategorieSconto/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setCategorieSconto", result.data.apiResult);

    return result;
  },
  async fetchClientiDropDown(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ricercaClienti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setClientiDropdown", result.data.apiResult);

    return result;
  },
};

const mutations: any = {
  setClienti(state: any, clientiData: any) {
    state.clienti = clientiData;
  },
  setQualifiche(state: any, qualificheData: any) {
    state.qualifiche = qualificheData;
  },
  setStatiCliente(state: any, statiClienteData: any) {
    state.statiCliente = statiClienteData;
  },
  setAgenti(state: any, agentiData: any) {
    state.agenti = agentiData;
  },
  setMacroStati(state: any, macroStatiData: any) {
    state.macroStati = macroStatiData;
  },
  setCategorieSconto(state: any, categorieScontoData: any) {
    state.categorieSconto = categorieScontoData;
  },
  setClientiDropdown(state: any, clientiData: any) {
    state.clientiDropDown = clientiData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
