import axios from "axios"; // Vue router instance

const state: any = {
  contatti: [],
};

const getters: any = {
  getContatti: (state: any) => {
    return state.contatti;
  },
};

const actions: any = {
  async beRetrieve(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/bz_bestd/beRetrieve/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async resetContatti(context: any) {
    context.commit("setContatti", []);

    return true;
  },
  async beActionExecute(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/bz_bestd/beActionExecute/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async findById(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/bz_bestd/beRetrieve/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async beNew(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/bz_bestd/beNew/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async beUpdate(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/bz_bestd/beUpdate/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async beDelete(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/bz_bestd/beDelete/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async beMetadataRetrieve(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/bz_bestd/beMetadataRetrieve/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async beLookup(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/bz_bestd/beLookup/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
};

const mutations: any = {
  setContatti(state: any, contattiData: any) {
    state.contatti = contattiData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
