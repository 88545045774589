import axios from "axios"; // Vue router instance
import { sha512 } from "js-sha512";
import { gestCookies } from "../../composables/cookies";
const { getCookieFromComposable } = gestCookies();

//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.common['Sec-Fetch-Site'] = 'same-origin';
//axios.defaults.headers.common['Sec-Fetch-Mode'] = 'no-cors';
//axios.defaults.headers.common['iv_sitekey'] = getCookieFromComposable('iv_sitekey')
//axios.defaults.headers.common['sivnext_override_config'] = 'MaurizioIV'
//axios.defaults.withCredentials = true
//axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

const state: any = {
  token: null,
  user: null,
};

const getters: any = {
  getToken: (state: any) => {
    return state.token;
  },
  getUser: (state: any) => {
    return state.user;
  },
};

const actions: any = {
  async loginCliente(context: any, formData: any) {
    const div = this.getters["menu/getConfigVars"].licenseKey.split("_")

    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/login_utenteweb/",
      formData,
        {
          headers: {
            "ditta": parseInt(div[div.length - 1])
          }
        }
    );

    await context.commit("setUser", {
      ...result.data.apiResult,
      username: formData.username,
    });
    await context.commit("setToken", result.data.apiResult.apiSessionToken);

    return result;
  },
  async loginOperatore(context: any, formData: any) {
    const div = this.getters["menu/getConfigVars"].licenseKey.split("_")

    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/login_operatore/",
      { username: formData.username, password: sha512(formData.password) },
        {
          headers: {
            "ditta": parseInt(div[div.length - 1])
          }
        }
    );

    await context.commit("setUser", {
      ...result.data.apiResult,
      username: formData.username,
    });
    await context.commit("setToken", result.data.apiResult.apiSessionToken);

    return result;
  },
  logout(context: any) {
    context.commit("setToken", null);
    context.commit("setUser", null);

    localStorage.removeItem("vuex");
  },
  async resetPassword(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ResetPassword/",
      formData,
    );
  },
  async changePassword(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ChangePassword/",
      formData,
    );
  },
  async register(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/RegistrazioneWeb/",
      formData,
    );
  },
};

const mutations: any = {
  setToken(state: any, tokenData: any) {
    state.token = tokenData;
  },
  setUser(state: any, userData: any) {
    state.user = userData;
  },
  setClienteAbbinato(state: any, userData: any) {
    if (state.user) {
      if (state.user.DatiUtenza) {
        state.user.DatiUtenza[0].Cliente_dbKey = userData.Cliente_dbKey;
        state.user.DatiUtenza[0].ClienteAbbinato = userData.ClienteAbbinato;
        state.user.DatiUtenza[0].PostelTramite = userData.PostelTramite;
        state.user.DatiUtenza[0].Cliente = userData.Cliente;
        state.user.DatiUtenza[0].Gdpr = userData.Gdpr;
        state.user.DatiUtenza[0].conto_dbKey = userData.conto_dbKey
      }
    }
  },
  setGdprRequired(state: any, gdpr: any) {
    if (state.user) {
      if (state.user.DatiUtenza) {
        state.user.DatiUtenza[0].Gdpr = gdpr;
      }
    }
  },
  setEmail(state: any, userData: any) {
    state.user.EmailWeb = userData.EmailWeb;
  },
  setPostelTramite(state: any, userData: any) {
    state.user.DatiUtenza[0].PostelTramite.value = userData.PostelTramite;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
