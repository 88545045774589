import axios from "axios"; // Vue router instance

const state: any = {
  mainMenu: {},
  configVars: false,
};

const getters: any = {
  getMainMenu: (state: any) => {
    return state.mainMenu;
  },
  getConfigVars: (state: any) => {
    return state.configVars;
  },
};

const mutations: any = {
  setMainMenu(state: any, menuData: any) {
    state.mainMenu = menuData;
  },
  setConfigVars(state: any, configData: any) {
    state.configVars = configData;
  },
};

const actions: any = {
  async fetchLicense(context: any, hostname: string) {
    const result: any = await axios.get(
      hostname + "/api/WebServices/Customers/GetLicense.php",
    );

    await context.commit("setConfigVars", {
      ...result.data.customer,
      hostname: hostname,
    });

    return result;
  },
  async fetchMenu(context: any, formData: any = "") {
    let payLoad = {};

    if (formData !== "") {
      payLoad = {
        Cliente_dbKey: formData.Cliente_dbKey,
      };
    } else {
      payLoad = {
        IdWebPwd: this.getters["user/getUser"].IdWebPwd,
      };
    }

    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/navigation_web/",
      payLoad,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setMainMenu", result.data.apiResult.NavFunct);

    return result;
  },
  async fetchMenuWithoutToken(context: any, formData: any = "") {
    let payLoad = {};

    if (formData !== "") {
      payLoad = {
        Cliente_dbKey: formData.Cliente_dbKey,
      };
    } else {
      payLoad = {
        IdWebPwd: this.getters["user/getUser"].IdWebPwd,
      };
    }

    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/navigation_web/",
      payLoad,
      {
        headers: {
          apiSessionToken: formData.apiSessionToken,
        },
      },
    );

    context.commit("setMainMenu", result.data.apiResult.NavFunct);

    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
