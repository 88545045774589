import { ref } from "vue";

export function uploadFile() {
  const fileDef = ref();

  const calcFile = (selector: any) => {
    const fileSelPrim: any = document.querySelector("#" + selector);
    const fileSel: any = fileSelPrim.files[0];

    let reader = new FileReader();

    reader.onload = (e: any) => {
      const b64 = e.target.result
        //.replace(/^data:.+;base64,/, "")
       // .replace(" ", "+");
      fileDef.value = b64;
    };
    reader.readAsDataURL(fileSel);
  };

  return {
    calcFile,
    fileDef,
  };
}
