<script setup lang="ts">
import {
  DxSelection,
  DxColumnFixing,
  DxSearchPanel,
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxLoadPanel,
  DxPaging,
  DxExport,
  DxSummary,
  DxTotalItem,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import { computed, ref, toRefs, watch } from "vue";
import { ContattiModelRules } from "../../models/contatti";
import { NoteCreditoModelRules } from "../../models/noteCredito";
import { PagamentoFattureModelRules } from "../../models/pagamentoFatture";
import { RichiediNoteCreditoModelRules } from "../../models/richiediNoteCredito";
import { useStore } from "vuex";
const { errorSys, calcError } = parseError();
import ActionsTableContatti from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTableNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTablePagamentoFatture from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTableRichiediNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTableTickets from "../GPR-OPERS/ROUTING/Index.vue";
import ActionsTablePrezziSconti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalContatti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalFormContatti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ModalPagamentoFatture from "../GPR-OPERS/ROUTING/Index.vue";
import ModalRichiediNoteCredito from "../GPR-OPERS/ROUTING/Index.vue";
import ModalPrezziSconti from "../GPR-OPERS/ROUTING/Index.vue";
import ModalTickets from "../GPR-OPERS/ROUTING/Index.vue";
import ModalFormTicket from "../GPR-OPERS/ROUTING/Index.vue";

const user = computed(() => store.getters["user/getUser"]);

const { formatDate } = convertDate();
const { secondsToHms } = datesLimit();
import { compare } from "../../composables/compare";
import { TicketsModelRules } from "../../models/tickets";
import { convertDate } from "../../composables/convertDate";
import {datesLimit} from "../../composables/datesLimit";
import {PrezziScontiModelRules} from "../../models/prezziSconti";
import {parseError} from "../../composables/parseError";
import {useRouter} from "vue-router";
const router = useRouter();
const { compareData } = compare();

const openBasicModalValue = ref(false);
const openEditModalValue = ref(false);

const store = useStore();
const dataGrid = ref();
const onInitialized = (e: any) => {
  dataGrid.value = e.component;
};
const updateRecapiti = ref(false);
const updateNote = ref(false);
const updateVerification = ref(false);
const updateCategory = ref(false);
const item = ref();
const configVars = computed(() => store.getters["menu/getConfigVars"]);

const props = defineProps([
  "items",
  "metadataFields",
  "tableInfo",
  "type",
  "addContact",
  "loaded",
  "upNote",
  "addTicket",
]);
const emit = defineEmits([
  "setPredef",
  "upRec",
  "addContatto",
  "refreshData",
  "upNote",
  "upVerification",
  "upCategory",
  "deleteContact",
  "addTicket",
  "deleteTicket"
]);

const { fields } =
  props.type == "contatti"
    ? ContattiModelRules()
    : props.type == "noteCredito"
      ? NoteCreditoModelRules()
      : props.type == "pagamentoFatture"
        ? PagamentoFattureModelRules()
        : props.type == "tickets"
          ? TicketsModelRules()
            : props.type == "prezziSconti"
              ? PrezziScontiModelRules()
          : RichiediNoteCreditoModelRules();

const items = computed(() => props.items);
const metadataFields = computed(() => props.metadataFields);
const tableInfo = computed(() => props.tableInfo);
const addContact = computed(() => props.addContact);
const addTicket = computed(() => props.addTicket);

const assignItem = (itemSelected: any) => {
  item.value = itemSelected;
};

// NOTE CREDITO

const refreshData = () => {
  emit("refreshData", true);
  openBasicModal(false);
};

// CONTATTI

const deleteContact = () => {
  emit("deleteContact", true);
};

const setPredef = (record: string) => {
  updateRecapiti.value = true;
  emit("setPredef", record);
};

const upRec = () => {
  updateRecapiti.value = true;
  emit("upRec", true);
};

const openEditModal = (value: boolean) => {
  openEditModalValue.value = value;
};

const editContatto = (item: any) => {
  assignItem(item);
  openBasicModal(false);
  openEditModal(true);
};

const openContatto = (item: any) => {
  assignItem(item);
  openBasicModal(true);
  openEditModal(false);
  emit("addContatto", true);
};

// TICKET

const openTicket = (item: any) => {
  assignItem(item);
  openBasicModal(true);
  openEditModal(false);
  emit("addTicket", true);
};

const editTicket = (item: any) => {
  assignItem(item);
  openBasicModal(false);
  openEditModal(true);
};

const deleteTicket = () => {
  openBasicModal(false)
  assignItem('')
  emit("deleteTicket");
};

watch(
  () => [props.addContact, props.addTicket],
  (oldValue, newValue) => {
    assignItem(null);
    openEditModal(true);
  },
  { deep: true },
);

// AUTORIZZAZIONE PAGAMENTO FATTURE

const upNote = (data: any) => {
  updateNote.value = true;
  emit("upNote", true);
};

const upVerification = () => {
  updateVerification.value = true;
  emit("upVerification", true);
};

const upCategory = () => {
  updateCategory.value = true;
  emit("upCategory", true);
};

const refreshDataNc = () => {
  emit("refreshData", true);
};

// PREZZI/SCONTI

// CONDIVISI

const openBasicModal = (value: boolean) => {
  openBasicModalValue.value = value;
};

const selected = toRefs<any>(props).items;

watch(
  selected,
  (newValue, oldValue) => {
    if (item.value) {
      let i = newValue.find(
        (el: { __dbKey: any }) => el.__dbKey === item.value.__dbKey,
      );
      if (i) {
        assignItem(i);
      } else {
        if (props.type !== "richiediNc") {
          openBasicModal(false);
        }
      }

      updateRecapiti.value = false;
      updateNote.value = false;
      updateVerification.value = false;
    }
  },
  { deep: true },
);


const calculateCellValue = (field: any) => {
  if(field.type === 'Object') {
    return field.name.toString() + '.description';
  }
  return field.name;
}

const selectCustomer = (d: any) => {
  store
      .dispatch("GRPOPERS/fetchClienti", {
        dbKey: d.cliente_dbKey,
        RagioneSociale: null,
        Cliente: null,
        Indirizzo: null,
        Cap: null,
        Comune: null,
        Provincia: null,
        CodiceFiscale: null,
        PartitaIva: null,
        Qualifica: null,
        MacroStato: null,
        Agente: null,
        CategoriaSconto: null,
        StatoCliente: null,
      })
      .then((res) => {
        let data = res.data.apiResult[0]

        if(res.data.apiResult.length) {
          store.commit("user/setClienteAbbinato", {
            Cliente_dbKey: "",
            ClienteAbbinato: "",
            PostelTramite: "",
            Cliente: "",
            Gdpr: 0,
            conto_dbKey: ''
          });
          store.commit("GRPOPERS/setClientiDropdown", []);
          store.commit("menu/setMainMenu", []);

          store
              .dispatch("menu/fetchMenu", {
                Cliente_dbKey: data.__dbKey,
              })
              .then(() => {
                const altrePosizioniContabili = computed(() =>
                    store.getters["menu/getMainMenu"]
                        .filter((c: any) => c.code === "GRP-PROFILO")[0]
                        .childs.filter((child: any) => child.code === "CHG-POS_CONTABILE"),
                );

                if (altrePosizioniContabili.value) {
                  const form = {
                    RagioneSociale: null,
                    Cliente: null,
                    Indirizzo: null,
                    Cap: null,
                    Comune: null,
                    Provincia: null,
                    CodiceFiscale: data["Conto/Soggetto/CodiceFiscale"],
                    PartitaIva: null,
                    Qualifica: null,
                    MacroStato: null,
                    Agente: null,
                    CategoriaSconto: null,
                    StatoCliente: null,
                  };

                  if (!data["Conto/Soggetto/CodiceFiscale"]) {
                    store.commit("GRPOPERS/setClientiDropdown", []);
                  } else {
                    store
                        .dispatch("GRPOPERS/fetchClientiDropDown", form)
                        .then()
                        .catch((e) => {
                          console.log(e)
                        });
                  }
                }
              })
              .catch((e) => {
                console.log(e)
              });

          store.commit("user/setClienteAbbinato", {
            Cliente_dbKey: data.__dbKey,
            ClienteAbbinato: data.RagioneSociale,
            PostelTramite: data.PostelTramite,
            Cliente: data.Cliente,
            Gdpr: 0,
            conto_dbKey: data.conto_dbKey
          });

          router.push("/dashboard");
        }
      })
      .catch((e) => {
        console.log(e)
      });
};
</script>
<template>
  <div class="py-4 bg-white mt-8" v-if="props.loaded">
    <DxDataGrid
      id="gridContainer"
      :data-source="items"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      noDataText="Nessun risultato"
      :on-initialized="onInitialized"
    >
      <DxScrolling
        :use-native="false"
        :scroll-by-content="true"
        :scroll-by-thumb="true"
        show-scrollbar="onHover"
        mode="infinite"
      />
      <DxSearchPanel :visible="true" placeholder="Cerca..." />
      <DxToolbar>
        <DxItem name="searchPanel" />
      </DxToolbar>
      <DxLoadPanel :enabled="false" />
      <DxSorting mode="single" />
      <DxPaging :enabled="false" />
      <DxColumnFixing :enabled="false" />
      <DxColumn
          header-cell-template="title-header"
          data-field="__dbKey"
          caption="Seleziona"
          cell-template="choose-customer"
          v-if="props.type === 'tickets' && user.DatiUtenza[0].Cliente_dbKey == ''"
      />
      <template v-for="field in fields.sort(compareData)">
        <template
          v-if="
            field.visibleTable &&
            (metadataFields[field.name] ||
              (!metadataFields[field.name] && !field.metadataField))
          "
        >
          <DxColumn
            header-cell-template="title-header"
            :data-field="calculateCellValue(field)"
            :caption="
              metadataFields[field.name]
                ? metadataFields[field.name].label
                : field.visibleName
            "
            :data-type="field.type === 'Date' ? 'date' : 'string'"
            :format="field.type === 'Date' ? 'dd/MM/yyyy' : ''"
            :cell-template="field.cellTemplate ? field.cellTemplate !== 'object-template' ? field.cellTemplate : '' : ''"
          />
        </template>
      </template>
      <DxColumn
        header-cell-template="title-header"
        data-field="__dbKey"
        caption="AZIONI"
        cell-template="action-cell"
      />
      <template #title-header="{ data }">
        <p style="font-size: 0.9rem; color: #536de6; font-weight: 700">
          {{ data.column.caption }}
        </p>
      </template>
      <template #choose-customer="{ data }">
        <button
            v-if="data.data.cliente_dbKey"
            class="btn btn-xs text-white"
            :class="configVars.primary + ' hover:' + configVars.primaryHover"
            @click="selectCustomer(data.data)"
        >
          <svg
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
          >
            <title>dashboard_customize</title>
            <g fill="none" class="nc-icon-wrapper">
              <path
                  d="M3 3h8v8H3V3zm10 0h8v8h-8V3zM3 13h8v8H3v-8zm15 0h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z"
                  fill="#ffffff"
              ></path>
            </g>
          </svg>
          <button class="hidden xs:block ml-2">Seleziona</button>
        </button>
      </template>
      <template #format-number="{ data }">
        {{
          new Intl.NumberFormat("it-IT", { minimumFractionDigits: 2 }).format(
            data.value,
          )
        }}
      </template>
      <template #format-currency="{ data }">
        {{
          new Intl.NumberFormat("it-IT", { minimumFractionDigits: 2 }).format(
            data.value,
          )
        }}
        €
      </template>
      <template #object-template="{ data }">
        {{ data.value ? data.value.description : "" }}
      </template>
      <template #numero-fattura="{ data }">
        <template v-if="data.data.testataFatturaNumeroFattura">
          {{ data.data.testataFatturaRegistroSezionale }}/{{ data.value }}
        </template>
        <template v-else-if="data.data.numeroFattura">
          {{ data.data.registroSezionale }}/{{ data.value }}
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template>
      <template #status-nc="{ data }">
        <template v-if="!data.value.length"> &nbsp; </template>
        <template v-else-if="data.value.length === 1">
          <template v-if="data.value[0].stato">
            {{ data.value[0].stato }}
          </template>
          <template v-else>
            {{ data.value[0].notaCreditoNonAmmessaPer }}
          </template>
        </template>
        <template v-else> premere a destra per dettagli... </template>
      </template>
      <template #date-hour-start="{ data }">
        <template v-if="data.data.dataAvvio && data.data.oraAvvio">
          {{ formatDate(data.data.dataAvvio) }} {{ data.data.oraAvvio }}
        </template>
        <template v-else-if="data.data.data && data.data.ora">
          {{ formatDate(data.data.data) }} {{ data.data.ora }}
        </template>
        <template v-else>&nbsp;</template>
      </template>
      <template #name-surname="{ data }">
        {{ data.data.nomeContatto }} {{ data.data.cognomeContatto }}
      </template>
      <template #second-to-hms="{ data }">
        {{ secondsToHms(data.value) }}
      </template>
      <template #action-cell="{ data }">
        <ActionsTableContatti
          v-if="props.type === 'contatti'"
          :version="user.Release"
          component="ActionsTableContatti"
          folder="ActionsTable"
          :data="data"
          :tableInfo="tableInfo"
          @assignItem="assignItem"
          @openBasicModal="openBasicModal"
          @openEditModal="openEditModal"
          @deleteContact="deleteContact"
        />
        <ActionsTableNoteCredito
          v-if="props.type === 'noteCredito'"
          :version="user.Release"
          component="ActionsTableNoteCredito"
          folder="ActionsTable"
          :data="data"
          :tableInfo="tableInfo"
          @assignItem="assignItem"
          @openBasicModal="openBasicModal"
        />
        <ActionsTablePagamentoFatture
          v-if="props.type === 'pagamentoFatture'"
          :version="user.Release"
          component="ActionsTablePagamentoFatture"
          folder="ActionsTable"
          :data="data"
          :tableInfo="tableInfo"
          @assignItem="assignItem"
          @openBasicModal="openBasicModal"
        />
        <ActionsTableRichiediNoteCredito
          v-if="props.type === 'richiediNc'"
          :version="user.Release"
          component="ActionsTableRichiediNoteCredito"
          folder="ActionsTable"
          :data="data"
          :tableInfo="tableInfo"
          @assignItem="assignItem"
          @openBasicModal="openBasicModal"
        />
        <ActionsTableTickets
          v-if="props.type === 'tickets'"
          :version="user.Release"
          component="ActionsTableTickets"
          folder="ActionsTable"
          :data="data"
          :tableInfo="tableInfo"
          @assignItem="assignItem"
          @openBasicModal="openBasicModal"
        />
        <ActionsTablePrezziSconti
            v-if="props.type === 'prezziSconti'"
            :version="user.Release"
            component="ActionsTablePrezziSconti"
            folder="ActionsTable"
            :data="data"
            :tableInfo="tableInfo"
            @assignItem="assignItem"
            @openBasicModal="openBasicModal"
        />
      </template>
    </DxDataGrid>
  </div>
  <template v-if="props.type === 'contatti'">
    <ModalContatti
      v-if="openBasicModalValue"
      :version="user.Release"
      component="ModalContatti"
      folder="Contatti"
      :metadataFields="metadataFields"
      :openBasicModal="openBasicModalValue"
      :item="item"
      :updateRec="updateRecapiti"
      :tableInfo="tableInfo"
      @setPredef="setPredef"
      @upRec="upRec"
      @closeModal="openBasicModal"
      @editContatto="editContatto"
    />
    <ModalFormContatti
      v-if="openEditModalValue"
      :version="user.Release"
      component="ModalFormContatti"
      folder="Contatti"
      :openEditModal="openEditModalValue"
      :item="item"
      @closeModal="openEditModal"
      @save="openContatto"
    />
  </template>
  <template v-if="props.type === 'noteCredito'">
    <ModalNoteCredito
      v-if="openBasicModalValue"
      :version="user.Release"
      component="ModalNoteCredito"
      folder="NoteCredito"
      :metadataFields="metadataFields"
      :openBasicModal="openBasicModalValue"
      :item="item"
      :tableInfo="tableInfo"
      :updateVerification="updateVerification"
      @closeModal="openBasicModal"
      @verification="upVerification"
    />
  </template>
  <template v-if="props.type === 'pagamentoFatture'">
    <ModalPagamentoFatture
      v-if="openBasicModalValue"
      :version="user.Release"
      component="ModalPagamentoFatture"
      folder="PagamentoFatture"
      :metadataFields="metadataFields"
      :openBasicModal="openBasicModalValue"
      :item="item"
      :tableInfo="tableInfo"
      :updateNote="updateNote"
      :updateVerification="updateVerification"
      @closeModal="openBasicModal"
      @verification="upVerification"
      @upNote="upNote"
      @upCategory="upCategory"
    />
  </template>
  <template v-if="props.type === 'richiediNc'">
    <ModalRichiediNoteCredito
      v-if="openBasicModalValue"
      :version="user.Release"
      component="ModalRichiediNoteCredito"
      folder="NoteCredito"
      :openBasicModal="openBasicModalValue"
      :item="item"
      :tableInfo="tableInfo"
      @closeModal="openBasicModal"
      @reloadData="refreshDataNc"
    />
  </template>
  <template v-if="props.type === 'tickets'">
    <ModalTickets
      v-if="openBasicModalValue"
      :version="user.Release"
      component="ModalTickets"
      folder="Ticket"
      :openBasicModal="openBasicModalValue"
      :item="item"
      :tableInfo="tableInfo"
      @closeModal="openBasicModal"
      @editTicket="editTicket"
      @deleteTicket = "deleteTicket"
    />
    <ModalFormTicket
        v-if="openEditModalValue"
        :version="user.Release"
        component="ModalFormTicket"
        folder="Ticket"
        :openEditModal="openEditModalValue"
        :item="item"
        @closeModal="openEditModal"
        @save="openTicket"
    />
  </template>
  <template v-if="props.type === 'prezziSconti'">
    <ModalPrezziSconti
        v-if="openBasicModalValue"
        :version="user.Release"
        component="ModalPrezziSconti"
        folder="PrezziSconti"
        :openBasicModal="openBasicModalValue"
        :item="item"
        :tableInfo="tableInfo"
        @closeModal="openBasicModal"
        @verification="upVerification"
    />
  </template>
</template>
