<script setup>
import { ref, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { VueEditor } from "vue3-editor";
import { uploadFile } from "../../composables/upload";

const loading = ref(false);
const props = defineProps(["itemData"]);

const store = useStore();

const emit = defineEmits(["save"]);
const { calcFile, fileDef } = uploadFile();
const form = reactive(
  props.itemData
    ? {
        loginText: props.itemData.loginText,
        signupText: props.itemData.signupText,
        bankAccount: props.itemData.bankAccount,
        bankDebit: props.itemData.bankDebit,
        bankDebitFile: fileDef,
        privacyAmm: props.itemData.privacyAmm,
        privacyMail: props.itemData.privacyMail,
        privacySms: props.itemData.privacySms,
        privacyThird: props.itemData.privacyThird,
        titlePayPage: props.itemData.titlePayPage,
        descriptionPayPage: props.itemData.descriptionPayPage,
      }
    : {
        loginText: "",
        signupText: "",
        bankAccount: "",
        bankDebit: "",
        bankDebitFile: fileDef,
        privacyAmm: "",
        privacyMail: "",
        privacySms: "",
        privacyThird: "",
        titlePayPage: "",
        descriptionPayPage: "",
      },
);

const rules = {
  loginText: {
    required: helpers.withMessage("Testo login obbligatorio", required),
  },
  signupText: {
    required: helpers.withMessage("Testo nuovo cliente obbligatorio", required),
  },
  bankAccount: {},
  bankDebit: {},
  bankDebitFile: {},
  privacyAmm: {},
  privacyMail: {},
  privacySms: {},
  privacyThird: {},
  titlePayPage: {},
  descriptionPayPage: {},
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const onSubmit = () => {
  vv.value.$touch();

  if (vv.value.$invalid) return;
  emit("save", form);
};

const customToolbar = ref([
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }], // dropdown with defaults from theme
  [{ align: [] }],

  ["clean"],
]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<template>
  <form @submit.prevent="onSubmit" novalidate>
    <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="mb-8">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          Personalizzazione dei testi standard
        </h1>
      </div>

      <div v-if="loading">
        <div
          class="px-4 py-2 rounded-sm text-sm border bg-indigo-100 border-indigo-200 text-indigo-500"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                ></path>
              </svg>
              <div class="loading_course">Caricamento in corso</div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-lg rounded-sm mb-8" v-if="!loading">
        <div class="p-6 space-y-8 mt-8">
          <!-- Input Types -->
          <div>
            <h2 class="text-2xl text-slate-800 font-bold mb-6">
              Compila il form
            </h2>
            <div class="grid gap-5 md:grid-cols-1">
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="loginText"
                    >Login</label
                  >
                  <vue-editor
                    v-model="vv.loginText.$model"
                    id="loginText"
                    :editor-toolbar="customToolbar"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="loginText"
                    >Nuova iscrizione</label
                  >
                  <vue-editor
                    v-model="vv.signupText.$model"
                    id="loginText"
                    :editor-toolbar="customToolbar"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="bankAccount"
                    >Indicazioni per bonifico bancario</label
                  >
                  <vue-editor
                    v-model="vv.bankAccount.$model"
                    id="bankAccount"
                    :editor-toolbar="customToolbar"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="bankDebit"
                    >Domiciliazione bancaria</label
                  >
                  <vue-editor
                    v-model="vv.bankDebit.$model"
                    id="bankDebit"
                    :editor-toolbar="customToolbar"
                  />
                </div>
              </div>
              <div>
                <!-- Start -->
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="bankDebitFile"
                    >Allegato
                    <template v-if="props.itemData">
                      <template v-if="props.itemData.bankDebitFile">
                        <a
                          :href="
                            '/data/' +
                            props.itemData.license +
                            '/' +
                            props.itemData.bankDebitFile
                          "
                          target="_blank"
                          ><i class="dx-icon-download"></i
                        ></a>
                      </template>
                    </template>
                  </label>
                  <input
                    id="bankDebitFile"
                    class="block w-full text-sm text-slate-500 rounded border p-1 file:mr-4 file:px-4 file:py-1 file:rounded file:border-0 file:text-sm file:font-medium file:bg-slate-50 file:text-slate-700 hover:file:bg-slate-100"
                    type="file"
                    @change="calcFile('bankDebitFile')"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.bankDebitFile.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <!-- End -->
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="privacyAmm"
                    >Testo privacy amministrativa</label
                  >
                  <input
                    id="privacyAmm"
                    v-model="vv.privacyAmm.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="privacyMail"
                    >Testo privacy mail</label
                  >
                  <input
                    id="privacyMail"
                    v-model="vv.privacyMail.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="privacySms"
                    >Testo privacy SMS</label
                  >
                  <input
                    id="privacySms"
                    v-model="vv.privacySms.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="privacyThird"
                    >Testo privacy consenso a terzi</label
                  >
                  <input
                    id="privacyThird"
                    v-model="vv.privacyThird.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="titlePayPage"
                    >Titolo pagina pagamento da Qr Code</label
                  >
                  <input
                    id="titlePayPage"
                    v-model="vv.titlePayPage.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="descriptionPayPage"
                    >Testo pagina pagamento da Qr Code</label
                  >
                  <vue-editor
                    v-model="vv.descriptionPayPage.$model"
                    id="descriptionPayPage"
                    :editor-toolbar="customToolbar"
                  />
                </div>
              </div>
            </div>
            <footer>
              <div class="flex flex-col py-5 border-t border-slate-200 mt-6">
                <div class="flex self-end">
                  <button
                    class="btn text-white ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                    :disabled="vv.$invalid"
                    :class="
                      configVars.primary + ' hover:' + configVars.primaryHover
                    "
                    type="submit"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
