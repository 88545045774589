import axios from "axios"; // Vue router instance

const state: any = {
  recapiti: [],
  gdpr: {},
};

const getters: any = {
  getRecapiti: (state: any) => {
    return state.recapiti;
  },
  getGdpr: (state: any) => {
    return state.gdpr;
  },
};

const actions: any = {
  async fetchRecapiti(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Recapiti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setRecapiti", result.data.apiResult.recapiti);

    return result;
  },
  async updateRecapito(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/AggiornaRecapito/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async updateEmail(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ModificaMailUtenzaWeb/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async updateSpedizione(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/ModificaSpedizioneDocumenti/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async fetchGdpr(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Rd_GDPR/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setGdpr", result.data.apiResult);

    return result.data.apiResult;
  },
  async updateGdpr(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Up_GDPR/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
};

const mutations: any = {
  setRecapiti(state: any, recapitiData: any) {
    state.recapiti = recapitiData;
  },
  setGdpr(state: any, gdprData: any) {
    state.gdpr = gdprData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
