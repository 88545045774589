import axios from "axios"; // Vue router instance

const state: any = {
  visFatture: [],
  visSospesi: [],
};

const getters: any = {
  getVisFatture: (state: any) => {
    return state.visFatture;
  },
  getVisSospesi: (state: any) => {
    return state.visSospesi;
  },
};

const actions: any = {
  async fetchVisFatture(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Fatture/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setVisFatture", result.data.apiResult);

    return result;
  },
  async fetchVisSospesi(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Partite/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );

    context.commit("setVisSospesi", result.data.apiResult);

    return result;
  },
  async fetchVisSospesiWithoutToken(context: any, formData: any) {
    const result: any = await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/Partite/",
      formData,
    );

    context.commit("setVisSospesi", result.data.apiResult);

    return result;
  },
  async startPayment(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/NewEPayment/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async richiestaBollettino(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/BollettinoPT/",
      formData,
      {
        headers: {
          apiSessionToken: this.getters["user/getToken"],
        },
      },
    );
  },
  async payByLink(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/PayByLink/",
      formData,
    );
  },
  async startPaymentWithoutToken(context: any, formData: any) {
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname + "/ws/next_web/NewEPayment/",
      formData,
      {
        headers: {
          apiSessionToken: formData.apiSessionToken,
        },
      },
    );
  },
  async richiestaBollettinoWithoutToken(context: any, formData: any) {
    let apiSessionToken = formData.apiSessionToken;
    delete formData.apiSessionToken;
    return await axios.post(
      this.getters["menu/getConfigVars"].hostname +
        "/ws/next_web/BollettinoPT/",
      formData,
      {
        headers: {
          apiSessionToken: apiSessionToken,
        },
      },
    );
  },
};

const mutations: any = {
  setVisFatture(state: any, visFattureData: any) {
    state.visFatture = visFattureData;
  },
  setVisSospesi(state: any, visSospesiData: any) {
    state.visSospesi = visSospesiData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
