<template>
  <!-- Modal backdrop -->
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-out duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="modalOpen"
      class="fixed inset-0 bg-slate-900 bg-opacity-30 z-50 transition-opacity"
      aria-hidden="true"
    ></div>
  </transition>
  <!-- Modal dialog -->
  <transition
    enter-active-class="transition ease-in-out duration-200"
    enter-from-class="opacity-0 translate-y-4"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="transition ease-in-out duration-200"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-4"
  >
    <div
      v-show="modalOpen"
      :id="id"
      class="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6"
      role="dialog"
      aria-modal="true"
    >
      <div
        ref="modalContent"
        class="bg-white rounded shadow-lg overflow-auto max-h-full resize min-w-80"
        :class="zoom"
      >
        <!-- Modal header -->
        <div class="px-5 py-3 border-b border-slate-200">
          <div class="flex justify-between items-center">
            <div class="font-semibold text-slate-800">{{ title }}</div>
            <div class="flex">
              <!--<button class="text-slate-400 hover:text-slate-500 mr-2" @click="changeZoom('add')">
                <div class="sr-only">+</div>
                <svg class="w-6 h-6 fill-current" viewBox="0 0 48 48">
                  <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z M 20 12 L 20 19 L 13 19 L 13 21 L 20 21 L 20 28 L 22 28 L 22 21 L 29 21 L 29 19 L 22 19 L 22 12 Z" />
                </svg>
              </button>
              <button class="text-slate-400 hover:text-slate-500 mr-8" @click="changeZoom('less')">
                <div class="sr-only">-</div>
                <svg class="w-6 h-6 fill-current" viewBox="0 0 48 48">
                  <path d="M 20 3 C 10.622621 3 3 10.622621 3 20 C 3 29.377379 10.622621 37 20 37 C 22.066158 37 24.050119 36.630222 25.884766 35.953125 A 1.0001245 1.0001245 0 1 0 25.193359 34.076172 C 23.576006 34.673075 21.827842 35 20 35 C 11.703379 35 5 28.296621 5 20 C 5 11.703379 11.703379 5 20 5 C 28.296621 5 35 11.703379 35 20 C 35 25.107279 32.454831 29.605613 28.564453 32.316406 A 1.0001 1.0001 0 0 0 28.429688 33.84375 L 38.525391 43.939453 C 39.883682 45.297745 42.115514 45.299064 43.474609 43.941406 A 1.0001 1.0001 0 0 0 43.474609 43.939453 L 43.939453 43.476562 C 45.297745 42.118271 45.297158 39.884486 43.939453 38.525391 L 36.707031 31.292969 A 1.0001 1.0001 0 1 0 35.292969 32.707031 L 42.525391 39.939453 C 43.119686 40.534357 43.119099 41.468792 42.525391 42.0625 L 42.060547 42.525391 C 41.465643 43.119686 40.533161 43.119099 39.939453 42.525391 L 30.587891 33.173828 C 34.45771 30.057134 37 25.34874 37 20 C 37 10.622621 29.377379 3 20 3 z M 13 19 A 1.0001 1.0001 0 1 0 13 21 L 27 21 A 1.0001 1.0001 0 1 0 27 19 L 13 19 z" />
                </svg>
              </button>-->
              <button
                class="text-slate-400 hover:text-slate-500"
                @click.stop="$emit('close-modal')"
              >
                <div class="sr-only">Close</div>
                <svg class="w-4 h-4 fill-current">
                  <path
                    d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "ModalBasic",
  props: ["id", "modalOpen", "title"],
  emits: ["close-modal"],
  setup(props, { emit }) {
    const modalContent = ref(null);

    const zooms = ["w-3xl", "w-4xl", "w-5xl", "w-6xl", "w-7xl", "w-full"];

    const zoom = ref("w-3xl");

    const changeZoom = (val) => {
      const indexZoom = zooms.findIndex((el) => el === zoom.value);

      if (val === "less") {
        if (indexZoom === 0) {
          return;
        }
        zoom.value = zooms[indexZoom - 1];
      } else {
        if (indexZoom === zooms.length - 1) {
          return;
        }
        zoom.value = zooms[indexZoom + 1];
      }
    };

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!props.modalOpen || modalContent.value.contains(target)) return;
      emit("close-modal");
    };

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.modalOpen || keyCode !== 27) return;
      emit("close-modal");
    };

    onMounted(() => {
      //document.addEventListener('click', clickHandler)
      document.addEventListener("keydown", keyHandler);
    });

    onUnmounted(() => {
      //document.removeEventListener('click', clickHandler)
      document.removeEventListener("keydown", keyHandler);
    });

    return {
      modalContent,
      zoom,
      zooms,
      changeZoom,
    };
  },
};
</script>
