export function colors() {
    const semaforoInterno = (value: string) => {
        let v = value.substring(0,1);
        switch(v) {
            default:
            case "A":
            case "X":
            case "":
                return '<div class="w-4 h-4 bg-emerald-500 rounded-full"></div>'
                break;
            case "B":
                return '<div class="w-4 h-4 bg-yellow-500 rounded-full"></div>'
            break;
            case "C":
                return '<div class="w-4 h-4 bg-red-500 rounded-full"></div>'
            break;
            case "D":
                return '<div class="w-4 h-4 bg-gray-500 rounded-full"></div>'
        }
    }
    const semaforoCribis = (value: string) => {
        let v = value.substring(0,1);

        switch(v) {
            default:
            case "A":
                return '<div class="w-4 h-4 bg-emerald-500 rounded-full"></div>'
                break;
            case "B":
                return '<div class="w-4 h-4 bg-yellow-500 rounded-full"></div>'
                break;
            case "C":
                return '<div class="w-4 h-4 bg-red-500 rounded-full"></div>'
                break;
            case "D":
                return '<div class="w-4 h-4 bg-gray-500 rounded-full"></div>'
        }
    }

    const statoCliente = (value: any) => {
        if(value.statoOrdini == 'N' || value.statoFatturazione == 'N') {
            return 'text-red-500'
        }

        return
    }

    const itemColors = [
        'bg-red-500',
        'bg-yellow-500',
        'bg-pink-500',
        'bg-indigo-500',
        'bg-emerald-500',
        'bg-gray-500',
        'bg-purple-500',
        'bg-cyan-500',
        'bg-green-500',
        'bg-teal-500',
        'bg-orange-500',
        'bg-sky-500',
        'bg-amber-500',
        'bg-lime-500',
        'bg-blue-500',
        'bg-violet-500',
        'bg-rose-500'
    ];
    const listItemColors = (index: number) => {
        if(index > 16) {
            return itemColors[index % 16]
        } else {
            return itemColors[index]
        }
    }

    return {
        semaforoInterno,
        semaforoCribis,
        statoCliente,
        listItemColors
    }
}