export function convertDate() {
  const checkZero = (data: string) => {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  };

  const newDate = (dd: string) => {
    let today = new Date(dd);
    let day = today.getDate() + "";
    let month = today.getMonth() + 1 + "";
    let year = today.getFullYear() + "";
    //let hour = today.getHours() + "";
    //let minutes = today.getMinutes() + "";
    //let seconds = today.getSeconds() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    //hour = checkZero(hour);
    //minutes = checkZero(minutes);
    //seconds = checkZero(seconds);

    return year + "-" + month + "-" + day;
  };

  const formatDateWithoutDay = (date: string) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month].join("-");
  };

  const formatDate = (date: string) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  return {
    newDate,
    formatDateWithoutDay,
    formatDate,
  };
}
