<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();
const props = defineProps(["activeCondition", "resetValue", "currentRoute"]);

const expanded = ref(props.activeCondition);
const resetV = computed(() => props.resetValue);
const currentRoute = computed(() => props.currentRoute);

const handleClick = () => {
  expanded.value = !expanded.value;
};

watch(
  () => resetV.value,
  () => {
    if (!route.path.includes(currentRoute.value)) {
      expanded.value = false;
    }
  },
);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<template>
  <li
    class="px-3 py-2 rounded-sm mb-0.5 last:mb-0"
    :class="activeCondition && configVars.primary"
  >
    <slot :handleClick="handleClick" :expanded="expanded" />
  </li>
</template>
