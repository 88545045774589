<script setup>
import { ref } from "vue";
import Sidebar from "../components/Sidebar.vue";
import Header from "../components/Header.vue";

const sidebarOpen = ref(false);
</script>
<style>
.dx-datagrid {
  color: #475569 !important;
  font-family: Inter, sans-serif !important;
  font-size: 0.9rem !important;
}
.dx-widget {
  font-family: Inter, sans-serif !important;
}

.multiselect-option,
.multiselect-search,
.multiselect {
  font-size: 0.875rem !important;
}

.dx-datagrid td,
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border: 0 !important;
}

.dx-datagrid .dx-column-lines > td {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  vertical-align: middle !important;
}
.dx-datagrid-text-content > p {
  color: #64748b !important;
}
.dx-header-row > td {
  border-top: 1px solid #ddd !important;
  background-color: #f8fafc !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.dx-header-row > td:first-child,
.dx-datagrid .dx-column-lines > td:first-child {
  padding-left: 1.25rem !important;
}

.dx-datagrid-header-panel .dx-toolbar {
  margin: 0;
  padding-right: 20px;
  background-color: transparent;
}

.dx-datagrid-header-panel .dx-toolbar-items-container {
  height: 70px;
}

.dx-datagrid-header-panel .dx-toolbar-before .dx-toolbar-item:last-child {
  padding-right: 10px;
}

.dx-datagrid-header-panel .dx-selectbox {
  margin: auto 10px;
}

.dx-datagrid-header-panel .dx-button {
  margin: auto 0;
}
.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
  width: 10px !important;
  min-width: 10px !important;
  padding-left: 0 !important;
}
.dx-datagrid-summary-item {
  margin-left: 1rem !important;
  padding-left: 0;
}

.informer {
  padding-bottom: 15px;
  height: 70px;
  width: 130px;
  text-align: center;
  color: #fff;
}

.count {
  line-height: 27px;
  font-size: 28px;
  margin: 0;
}
</style>
<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />
    <!-- Content area -->
    <div
      class="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>
