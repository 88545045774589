<script setup lang="ts">
import { parseError } from "../../composables/parseError";
import { computed, reactive, toRefs, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useStore } from "vuex";
const { errorSys, calcError } = parseError();
const store = useStore();
import { helpers } from "@vuelidate/validators";
const loading = ref(false);
const loaded = ref(false);
const user = computed(() => store.getters["user/getUser"]);
import Table from "../../components/dom/Table.vue";
import { data } from "../../composables/api/data";
import ListItem from "../../components/dom/ListItem.vue";
import ModalFormContatti from "../../components/GPR-OPERS/ROUTING/Index.vue";
const {
  exportedData,
  exportedMetaDataFields,
  exportedTableInfo,
  elaborateMetaDataFields,
  elaborateData,
  elaborateTableInfo,
} = data();
const addContact = ref(false);
const props = defineProps(["title"]);

const form = reactive({
  cognome: "",
  nome: "",
});

const checkMinLengthNome = () => {
  if (form.nome.length < 3 && !form.cognome) {
    return false;
  }
  return true;
};

const checkMinLengthCognome = () => {
  if (form.cognome.length < 3 && !form.nome) {
    return false;
  }
  return true;
};

const rules = {
  cognome: {
    minLengthValue: helpers.withMessage(
      "Lunghezza minima 3 caratteri",
      checkMinLengthCognome,
    ),
  },
  nome: {
    minLengthValue: helpers.withMessage(
      "Lunghezza minima 3 caratteri",
      checkMinLengthNome,
    ),
  },
};

const vv = useVuelidate(rules, { ...toRefs(form) });
const configVars = computed(() => store.getters["menu/getConfigVars"]);

const params = computed(() => {
  if (
    store.getters["menu/getMainMenu"].filter(
      (c: { code: string }) => c.code === "GRP-OPERS",
    )
  ) {
    if (
      store.getters["menu/getMainMenu"].filter(
        (c: { code: string }) => c.code === "GRP-OPERS",
      )[0]
    ) {
      return store.getters["menu/getMainMenu"]
        .filter((c: { code: string }) => c.code === "GRP-OPERS")[0]
        .childs.filter(
          (child: { code: string }) => child.code === "VIS-CONTATTI",
        )[0]
        .params.split(";");
    }
  }
  return null;
});

const loadData = () => {
  store
    .dispatch("generic/beRetrieve", {
      beName: "Contatti_be",
      beView: "webAppContattiLista",
      includeMetadata: "view",
      solveDynamicMetadata: true,
      filter:
        "{nome} begins '" +
        form.nome +
        "' and {cognome} begins '" +
        form.cognome +
        "'",
      orderBy: "cognome",
      howMany: params.value[0].length
        ? parseInt(params.value[0].split("=")[1])
        : "",
    })
    .then((res) => {
      elaborateData(res.data.apiResult.data);

      if (res.data.apiResult.data && res.data.apiResult.metadata) {
        if (res.data.apiResult.metadata.fields) {
          elaborateMetaDataFields(res.data.apiResult.metadata.fields);
          elaborateTableInfo(res.data.apiResult.metadata.tableInfo);
          loaded.value = true;
        }
      }

      loading.value = false;
    })
    .catch((e) => {
      calcError(e);
    });
};

const searchContatti = () => {
  loading.value = true;
  loaded.value = false;
  loadData();
};

const resetForm = () => {
  form.cognome = "";
  form.nome = "";
  loaded.value = false;

  store.dispatch("generic/resetContatti");
};

const setPredef = (value: string) => {
  store
    .dispatch("generic/beActionExecute", {
      beName: "Recapiti_be",
      beView: "Recapiti",
      action: "impostaComePredefinito",
      recordDbKey: [value],
    })
    .then(() => loadData());
};

const upRec = () => {
  loadData();
};

const addToggleContact = () => {
  addContact.value = !addContact.value;
};

const addContatto = () => {
  if (form.nome != "" || form.cognome != "") {
    loadData();
  }
};

const deleteContact = () => {
  loading.value = true;
  loaded.value = false;
  loadData();
};
</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
    <div class="mb-5" v-if="errorSys">
      <div class="px-4 py-2 rounded-sm text-sm text-white bg-rose-500">
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z"
              ></path>
            </svg>
            <div class="font-medium">{{ errorSys }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page header -->
    <div class="sm:flex sm:justify-between sm:items-center mb-8">
      <!-- Left: Title -->
      <div class="mb-4 sm:mb-0">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ props.title }}
        </h1>
      </div>

      <div
        class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"
        v-if="params[1].split('=')[1] === 'SI'"
      >
        <!-- Add customer button -->
        <button
          class="btn text-white"
          :class="configVars.primary + ' hover:' + configVars.primaryHover"
          @click="addToggleContact"
        >
          <svg
            class="w-4 h-4 fill-current opacity-50 shrink-0"
            viewBox="0 0 16 16"
          >
            <path
              d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
            />
          </svg>
          <span class="hidden xs:block ml-2"
            >Aggiungi un contatto</span>
        </button>
      </div>
    </div>
    <div class="p-6 space-y-8 mt-8 mb-8 bg-white">
      <!-- Input Types -->
      <form @submit.prevent="searchContatti" novalidate>
        <div>
          <div class="grid gap-5 md:grid-cols-2 items-end">
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="cognome"
                  >Cognome</label
                >
                <input
                  id="cognome"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.cognome.$model"
                />
              </div>
              <div
                class="text-xs mt-1 text-rose-500"
                v-for="(error, index) of vv.cognome.$errors"
                :key="index"
              >
                {{ error.$message }}
              </div>
            </div>
            <div>
              <div>
                <label class="block text-sm font-medium mb-1" for="nome"
                  >Nome</label
                >
                <input
                  id="nome"
                  class="form-input w-full"
                  type="text"
                  v-model="vv.nome.$model"
                />
              </div>
              <div
                class="text-xs mt-1 text-rose-500"
                v-for="(error, index) of vv.nome.$errors"
                :key="index"
              >
                {{ error.$message }}
              </div>
            </div>
            <div class="col-start-1">
              <button
                class="btn text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                :disabled="vv.$invalid"
                :class="
                  configVars.primary + ' hover:' + configVars.primaryHover
                "
                type="submit"
              >
                Cerca
              </button>
              <button
                class="btn text-white disabled:border-slate-200 ml-12"
                :class="
                  configVars.primaryHover + ' hover:' + configVars.primary
                "
                type="button"
                @click="resetForm"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="loading">
      <div
        class="px-4 py-2 rounded-sm text-sm border border-indigo-200"
        :class="configVars.secondaryText + ' ' + configVars.primary"
      >
        <div class="flex w-full justify-between items-start">
          <div class="flex">
            <svg
              class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
              ></path>
            </svg>
            <div class="loading_course">Caricamento in corso</div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-y-2 mt-8">
      <ListItem
        :items="exportedData"
        :metadataFields="exportedMetaDataFields"
        :tableInfo="exportedTableInfo"
        @setPredef="setPredef"
        @upRec="upRec"
        @addContatto="addContatto"
        :addContact="addContact"
        type="contatti"
        :loaded="loaded"
        @deleteContact="deleteContact"
      />
    </div>
  </div>
</template>
