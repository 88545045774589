<script setup>
import { ref, reactive, toRefs, computed } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { uploadFile } from "../../../composables/upload.ts";
import { VueEditor } from "vue3-editor";
import Multiselect from "@vueform/multiselect";

const loading = ref(false);
const props = defineProps(["itemData"]);

const { calcFile, fileDef } = uploadFile();

const store = useStore();

const emit = defineEmits(["save"]);

const form = reactive(
  props.itemData
    ? {
        title: props.itemData.title,
        image: fileDef,
        description: props.itemData.description,
        dest: props.itemData.dest,
        start: props.itemData.start,
        end: props.itemData.end,
      }
    : {
        title: "",
        image: fileDef,
        description: "",
        dest: "oper",
        start: "",
        end: "",
      },
);

const rules = {
  title: {
    required: helpers.withMessage("Titolo obbligatorio", required),
  },
  file: {},
  description: {},
  dest: {},
  start: {},
  end: {},
};

const vv = useVuelidate(rules, { ...toRefs(form) });

const onSubmit = () => {
  vv.value.$touch();

  if (vv.value.$invalid) return;
  emit("save", form);
};

const customToolbar = ref([
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }], // dropdown with defaults from theme
  [{ align: [] }],

  ["clean"],
]);

const valoriTot = ref([
  {
    value: "oper",
    label: "Operatore",
  },
  {
    value: "user",
    label: "Cliente",
  },
]);
const configVars = computed(() => store.getters["menu/getConfigVars"]);
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<template>
  <form @submit.prevent="onSubmit" novalidate>
    <div class="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <!-- Page header -->
      <div class="mb-8">
        <h1 class="text-2xl md:text-3xl text-slate-800 font-bold">
          {{ itemData ? "Modifica la slide" : "Aggiungi una slide" }}
        </h1>
      </div>

      <div v-if="loading">
        <div
          class="px-4 py-2 rounded-sm text-sm border bg-indigo-100 border-indigo-200 text-indigo-500"
        >
          <div class="flex w-full justify-between items-start">
            <div class="flex">
              <svg
                class="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
                ></path>
              </svg>
              <div class="loading_course">Caricamento in corso</div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-lg rounded-sm mb-8" v-if="!loading">
        <div class="p-6 space-y-8 mt-8">
          <!-- Input Types -->
          <div>
            <h2 class="text-2xl text-slate-800 font-bold mb-6">
              Compila il form
            </h2>
            <div class="grid gap-5 md:grid-cols-2">
              <div class="col-span-2">
                <div>
                  <label class="block text-sm font-medium mb-1" for="title"
                    >Titolo <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="title"
                    v-model="vv.title.$model"
                    class="form-input w-full"
                    type="text"
                  />
                </div>
              </div>
              <div>
                <!-- Start -->
                <div>
                  <label class="block text-sm font-medium mb-1" for="file"
                    >Immagine</label
                  >
                  <input
                    id="file"
                    class="block w-full text-sm text-slate-500 rounded border p-1 file:mr-4 file:px-4 file:py-1 file:rounded file:border-0 file:text-sm file:font-medium file:bg-slate-50 file:text-slate-700 hover:file:bg-slate-100"
                    type="file"
                    @change="calcFile('file')"
                  />
                </div>
                <div
                  class="text-xs mt-1 text-rose-500"
                  v-for="(error, index) of vv.file.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </div>
                <!-- End -->
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="dest"
                    >Destinazione <span class="text-rose-500">*</span></label
                  >
                  <Multiselect
                    id="dest"
                    v-model="vv.dest.$model"
                    :searchable="true"
                    mode="single"
                    :options="valoriTot"
                    :filterResults="true"
                    :minChars="1"
                    :resolveOnLoad="false"
                    :canClear="false"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="start"
                    >Inizio <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="start"
                    v-model="vv.start.$model"
                    class="form-input w-full"
                    type="date"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="end"
                    >Fine <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="end"
                    v-model="vv.end.$model"
                    class="form-input w-full"
                    type="date"
                  />
                </div>
              </div>
              <div class="col-span-2">
                <div>
                  <label
                    class="block text-sm font-medium mb-1"
                    for="description"
                    >Descrizione</label
                  >
                  <vue-editor
                    v-model="vv.description.$model"
                    id="description"
                    :editor-toolbar="customToolbar"
                  />
                </div>
              </div>
            </div>
            <footer>
              <div class="flex flex-col py-5 border-t border-slate-200 mt-6">
                <div class="flex self-end">
                  <button
                    class="btn text-white ml-3 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                    :disabled="vv.$invalid"
                    :class="
                      configVars.primary + ' hover:' + configVars.primaryHover
                    "
                    type="submit"
                  >
                    Salva
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
