import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "../store";

import Dashboard from "../views/Dashboard.vue";
import LoginOperatore from "../views/LoginOperatore.vue";
import LoginCliente from "../views/LoginCliente.vue";
import Crm from "../views/Crm.vue";
import Remember from "../views/Remember.vue";
import ChangePassword from "../views/ChangePassword.vue";
import ListCategories from "../views/Categories.vue";
import Logout from "../views/Logout.vue";
import AllRichieste from "../views/Categories.vue";
import FindRichiesta from "../views/Categories.vue";
import NewRichiesta from "../views/Categories.vue";
import VisRichieste from "../views/Categories.vue";
import VisRichiesteop from "../views/Categories.vue";
import SlidesList from "../views/AMMINISTRAZIONE/GEST-SLIDE/List.vue";
import SlidesListOld from "../views/AMMINISTRAZIONE/GEST-SLIDE/ListOLD.vue";
import SlidesAdd from "../views/AMMINISTRAZIONE/GEST-SLIDE/Add.vue";
import SlidesEdit from "../views/AMMINISTRAZIONE/GEST-SLIDE/Edit.vue";
import AmmGest from "../views/AMMINISTRAZIONE/Gest.vue";
import VisFatture from "../views/GRP-FATTURE/VIS-FATTURE.vue";
import RichiediNc from "../views/GRP-FATTURE/RICHIEDI-NC.vue";
import VisSospesi from "../views/GRP-FATTURE/VIS-SOSPESI.vue";
import VisClienti from "../views/GRP-OPERS/VIS-CLIENTI.vue";
import VisContatti from "../views/GRP-OPERS/VIS-CONTATTI.vue";
import VisNoteCredito from "../views/GRP-OPERS/VIS-NOTECREDITO.vue";
import VisPagamentoFatture from "../views/GRP-OPERS/VIS-AUTH-PF.vue";
import VisAuthPrezzo from "../views/GRP-OPERS/AUTH-PREZZO.vue";
import VisRecapiti from "../views/GRP-PROFILO/VIS-RECAPITI.vue";
import Tickets from "../views/GRP-OPERS/TICKET.vue";
import ChgMail from "../views/GRP-PROFILO/CHG-MAIL.vue";
import GestDocumentionline from "../views/GRP-PROFILO/GEST-DOCUMENTIONLINE.vue";
import ChgPassword from "../views/GRP-PROFILO/Chg-Password.vue";
import NewUser from "../views/NewUser.vue";
import Index from "../views/Index.vue";
import FilesList from "../views/AMMINISTRAZIONE/GEST-FILE/List.vue";
import FilesAdd from "../views/AMMINISTRAZIONE/GEST-FILE/Add.vue";
import VisSerbatoi from "../views/GRP-CONTRATTI/VIS-SERBATOI.vue";
import VisContatori from "../views/GRP-CONTRATTI/VIS-CONTATORI.vue";
import VisBomboleGpl from "../views/GRP-CONTRATTI/VIS-BOMBOLE_GPL.vue";
import GestGdpr from "../views/GRP-PROFILO/GEST-GDPR.vue";
import FilesEdit from "../views/AMMINISTRAZIONE/GEST-FILE/Edit.vue";
import Pay from "../views/PAY/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/loginOperatore",
    component: LoginOperatore,
  },
  {
    path: "/loginCliente",
    component: LoginCliente,
  },
  {
    path: "/remember-password",
    component: Remember,
  },
  {
    path: "/change-password",
    component: ChangePassword,
  },
  {
    path: "/new-user",
    component: NewUser,
  },
  {
    path: "/logout",
    component: Logout,
  },
  {
    path: "/pay",
    component: Pay,
  },
  {
    meta: {
      requiresAuth: true,
    },
    path: "/crm/",
    component: Crm,
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
      },
      {
        path: "/products/categories",
        component: ListCategories,
      },
      {
        path: "/AMMINISTRAZIONE/",
        children: [
          {
            path: "/AMMINISTRAZIONE/GEST-SLIDE",
            component: SlidesList,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-SLIDE/add",
            component: SlidesAdd,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-SLIDE/edit/:id",
            component: SlidesEdit,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-TESTI",
            component: AmmGest,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-SLIDE/ListOLD",
            component: SlidesListOld,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-FILE",
            component: FilesList,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-FILE/add",
            component: FilesAdd,
          },
          {
            path: "/AMMINISTRAZIONE/GEST-FILE/edit/:id",
            component: FilesEdit,
          },
        ],
      },
      {
        path: "/GRP-FATTURE/",
        children: [
          {
            path: "/GRP-FATTURE/VIS-FATTURE",
            component: VisFatture,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-FATTURE/VIS-SOSPESI",
            component: VisSospesi,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-FATTURE/RICHIEDI-NC",
            component: RichiediNc,
            props: (route) => ({ title: route.query.title }),
          },
        ],
      },
      {
        path: "/Download/",
        children: [
          {
            path: "/Download/Download-APP",
            component: ListCategories,
          },
        ]
      },
      {
        path: "/GRP-OPERS/",
        children: [
          {
            path: "/GRP-OPERS/VIS-CLIENTI",
            component: VisClienti,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-OPERS/VIS-CONTATTI",
            component: VisContatti,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-OPERS/AUTH-NC",
            component: VisNoteCredito,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-OPERS/AUTH-PAG_FTACQ",
            component: VisPagamentoFatture,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-OPERS/TICKET",
            component: Tickets,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-OPERS/AUTH-PREZZO",
            component: VisAuthPrezzo,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-OPERS/RICHIEDI-NC",
            component: ListCategories,
          },
          {
            path: "/GRP-OPERS/VIS-INTRANET",
            component: ListCategories,
          },
        ],
      },
      {
        path: "/GRP-PROFILO/",
        children: [
          {
            path: "/GRP-PROFILO/CHG-POS_CONTABILE",
            component: ListCategories,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-PROFILO/CHG-MAIL",
            component: ChgMail,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-PROFILO/VIS-RECAPITI",
            component: VisRecapiti,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-PROFILO/GEST-DOCUMENTIONLINE",
            component: GestDocumentionline,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-PROFILO/Chg-Password",
            component: ChgPassword,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-PROFILO/GEST-GDPR",
            component: GestGdpr,
            props: (route) => ({ title: route.query.title }),
          },
        ],
      },
      {
        path: "/GRP-CONTRATTI/",
        children: [
          {
            path: "/GRP-CONTRATTI/VIS-SERBATOI",
            component: VisSerbatoi,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-CONTRATTI/VIS-CONTATORI",
            component: VisContatori,
            props: (route) => ({ title: route.query.title }),
          },
          {
            path: "/GRP-CONTRATTI/VIS-BOMBOLE_GPL",
            component: VisBomboleGpl,
            props: (route) => ({ title: route.query.title }),
          },
        ],
      },
      {
        path: "/GRP-DISTR/",
        children: [
          {
            path: "/GRP-DISTR/GEST-DISTRI",
            component: ListCategories,
          },
        ],
      },
      {
        path: "/GRP-PRIMARI/",
        children: [
          {
            path: "/GRP-PRIMARI/ORD-PRIMARI",
            component: ListCategories,
          },
          {
            path: "/GRP-PRIMARI/CTR-PRIMARI",
            component: ListCategories,
          },
          {
            path: "/GRP-PRIMARI/VIS-QUOTAZIONI",
            component: ListCategories,
          },
          {
            path: "/GRP-PRIMARI/VIS-MOVPROD",
            component: ListCategories,
          },
          {
            path: "/GRP-PRIMARI/GEST-ORDINI",
            component: ListCategories,
          },
          {
            path: "/GRP-PRIMARI/VIS-ORDINI",
            component: ListCategories,
          },
          {
            path: "/GRP-PRIMARI/MOD-MAXGG",
            component: ListCategories,
          },
        ],
      },
      {
        path: "/GEST-RICHIESTE/",
        children: [
          {
            path: "/GEST-RICHIESTE/ALL-RICHIESTE",
            component: AllRichieste,
          },
          {
            path: "/GEST-RICHIESTE/FIND-RICHIESTA",
            component: FindRichiesta,
          },
          {
            path: "/GEST-RICHIESTE/NEW-RICHIESTA",
            component: NewRichiesta,
          },
          {
            path: "/GEST-RICHIESTE/VIS-RICHIESTE",
            component: VisRichieste,
          },
          {
            path: "/GEST-RICHIESTE/VIS-RICHIESTEOP",
            component: VisRichiesteop,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["user/getToken"]) {
      next("/loginCliente");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
