import GenericModel from "./generic";

export function SituazioneClienteModelRules() {
  const fields = [
    new GenericModel(
      "statoCliente",
      "Stato cliente",
      false,
      true,
      false,
      "Object",
      false,
      1,
      "",
    ),
    new GenericModel(
      "tipologiaCredito",
      "Tipologia credito",
      false,
      true,
      true,
      "Object",
      false,
      2,
      "",
    ),
    new GenericModel(
      "diretti",
      "Diretti",
      false,
      true,
      false,
      "Number",
      "format-currency",
      3,
      "",
    ),
    new GenericModel(
      "diCuiScaduto",
      "Di cui scaduto",
      false,
      true,
      false,
      "Number",
      "format-currency",
      4,
      "",
    ),
    new GenericModel(
      "titoli",
      "Titoli",
      false,
      true,
      false,
      "Number",
      "format-currency",
      5,
      "",
    ),
    new GenericModel(
      "effetti",
      "Effetti",
      false,
      true,
      false,
      "Number",
      "format-currency",
      6,
      "",
    ),
    new GenericModel(
      "bolle",
      "Bolle",
      false,
      true,
      true,
      "Number",
      "format-currency",
      7,
      "",
    ),
    new GenericModel(
      "ordini",
      "Ordini",
      false,
      true,
      true,
      "Number",
      "format-currency",
      9,
      "",
    ),
    new GenericModel(
      "esposizione",
      "Esposizione",
      false,
      true,
      false,
      "Number",
      "format-currency",
      10,
      "",
    ),
    new GenericModel(
      "diCuiGarantito",
      "Di cui garantito",
      false,
      true,
      false,
      "Number",
      "format-currency",
      11,
      "",
    ),
    new GenericModel(
      "fido",
      "Fido",
      false,
      true,
      true,
      "Number",
      "format-currency",
      12,
      "",
    ),
    new GenericModel(
      "residuo",
      "Residuo",
      false,
      true,
      true,
      "Number",
      "format-currency",
      13,
      "",
    ),
    new GenericModel(
      "clienteSemaforoInterno",
      "Semaforo interno",
      false,
      true,
      true,
      "String",
      false,
      14,
      "",
    ),
    new GenericModel(
        "clienteSemaforoCribis",
        "Semaforo cribis",
        false,
        true,
        true,
        "String",
        false,
        15,
        "",
    ),
    new GenericModel(
        "clienteRatingInterno",
        "Cliente rating interno",
        false,
        false,
        false,
        "Object",
        false,
        16,
        "",
    ),
    new GenericModel(
        "soggettoRatingInterno",
        "Soggetto rating interno",
        false,
        false,
        false,
        "Object",
        false,
        17,
        "",
    ),
    new GenericModel(
        "statoOrdini",
        "Stato ordini",
        false,
        false,
        false,
        "Object",
        false,
        18,
        "",
    ),
    new GenericModel(
        "statoFatturazione",
        "Stato fatturazione",
        false,
        false,
        false,
        "Object",
        false,
        19,
        "",
    )
  ];

  return {
    fields,
  };
}
