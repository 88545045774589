import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export function parseError() {
  const errorSys = ref(false);
  const router = useRouter();
  const store = useStore();
  const user = computed(() => store.getters["user/getUser"]);

  function calcError(e: any) {
    if (e.response && e.response.data) {
      if (e.response.data.errorCode) {
        if (e.response.data.errorCode === "I031") {
          router.push(
            user.value.IsOper
              ? "/logout?from=loginOperatore"
              : "/logout?from=loginCliente",
          );
          /*} else if(e.response.data.errorCode === 'E003') {
                        router.push(user.value.IsOper ? '/change-password?from=loginOperatore' : '/ChangePassword?from=loginCliente')*/
        } else {
          errorSys.value = e.response.data.errorMessage;
        }
      } else {
        router.push(
          user.value.IsOper
            ? "/logout?from=loginOperatore"
            : "/logout?from=loginCliente",
        );
      }
    } else {
      router.push(
        user.value.IsOper
          ? "/logout?from=loginOperatore"
          : "/logout?from=loginCliente",
      );
    }
  }

  return {
    calcError,
    errorSys,
  };
}
